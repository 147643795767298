<template>
  <div v-if="role >= 8">
  
  <v-row>
    <v-col cols="5">
<IndexBoard/>
    
    </v-col>
    <v-col cols="7">
<IndexMyCust/>
    
  </v-col>
  </v-row>
  <v-row>
    <v-col cols="5">
<IndexOtherCust/>
    
    </v-col>
    <v-col cols="7">
<IndexWageApply/>
    
  </v-col>
  </v-row>

  </div>
  <div v-else>
    대쉬보드준비중입니다.
  </div>
</template>
<script setup lang="ts">
import IndexBoard from '@/components/mng/dashboard/IndexBoard.vue'
import IndexMyCust from '@/components/mng/dashboard/IndexMyCust.vue'
import IndexOtherCust from '@/components/mng/dashboard/IndexOtherCust.vue'
import IndexWageApply from '@/components/mng/dashboard/IndexWageApply.vue'
import store from '@/store';
const role = store.getters.role;

</script>