<template>
  <div>
    <v-card style="margin-top:86px;" class=" mx-auto d-flex align-center justify-center crawlCard" elevation="3">
      <v-row class="fontNotoSans700">
        <v-col cols="12">
          <!-- 사용자로부터 입력 받는 폼 -->
            <v-table class="longtermVtable">
              <tbody>
                <tr>
                  <td class="labelTd">
                    <label>지역 : </label>
                  </td>
                  <td class="selectTd">
                    <select v-model="selectedSiDoCd" style="outline: auto; padding: 3px;">
                      <option v-for="option in regionOptions" :key="option.value" :value="option.value">{{ option.label }}
                      </option>
                    </select>
                  </td>
                  <td class="labelTd">
                    <label>서비스 : </label>
                  </td>
                  <td class="selectTd">
                    <input type="checkbox" v-model="selectedAdminKindCd" value="01"> 요양
                    <input type="checkbox" v-model="selectedAdminKindCd" value="06"> 주야간
                  </td>
                  <td class="labelTd">
                    <label>인원 : </label>
                  </td>
                  <td class="selectTd">
                    <input type="checkbox" v-model="selectedTotalCd" value="3"> 30~100명 미만
                    <input type="checkbox" v-model="selectedTotalCd" value="4"> 100명 이상
                  </td>
                  <td style="border-right: 2px solid #c8c8c8; width: 104px;">
                    <v-btn @click="submitForm('normal')" :disabled="loading" style="width:70px;">크롤링</v-btn>
                    <v-btn @click="submitForm('insert')" :disabled="loading" style="width:70px;">저장용크롤링</v-btn>

                  </td>
                  <td>
                    <v-btn @click="toggleList"> <v-icon style="color: darkgreen;">{{ mdiFileExcel }}</v-icon></v-btn>
                  </td>
                </tr>
              </tbody>
            </v-table>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="longtermExcelList" v-if="isListVisible">
      <v-row>
        <div v-for="(column, index) in columns" :key="index">
          <v-card style="width:380px;">
            <v-card-text>
              <ul class="list-container">
                <li v-for="file in column" :key="file">
                  <button style="color: #333 !important;" @click="downloadExcelFile(file)">{{ file }}</button>
                  <button style="float: right;" @click="confirmDelete(file)">삭제</button>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </v-card>
    <v-card class="mx-auto d-flex align-center justify-center crawlCard" elevation="3">
      <v-row class="fontNotoSans700">
        <v-col>
          <!-- 크롤링 결과 표시 -->
          <table class="longtermTable">
            <thead>
              <tr class="tac">
                <th class="tac">No.</th>
                <th class="tac sorting" @click="sortBy('category')" :class="{ 'sorting-active': isSorting('category', 1) || isSorting('category', -1) }">
                  구분
                  <v-icon v-if="sortByColumn === 'category'" :class="{ 'rotate180': isSorting('category', 1), 'rotate0': isSorting('category', -1) }">{{ sortDirection === 1 ? mdiArrowUp : mdiArrowDown }}</v-icon>
                </th>
                <th class="tac sorting" @click="sortBy('name')" :class="{ 'sorting-active': isSorting('name', 1) || isSorting('name', -1) }">
                  기 관 명
                  <v-icon v-if="sortByColumn === 'name'" :class="{ 'rotate180': isSorting('name', 1), 'rotate0': isSorting('name', -1) }">{{ sortDirection === 1 ? mdiArrowUp : mdiArrowDown }}</v-icon>
                </th>
                <th class="tac sorting" @click="sortBy('date')" :class="{ 'sorting-active': isSorting('date', 1) || isSorting('date', -1) }">
                  설립일
                  <v-icon v-if="sortByColumn === 'date'" :class="{ 'rotate180': isSorting('date', 1), 'rotate0': isSorting('date', -1) }">{{ sortDirection === 1 ? mdiArrowUp : mdiArrowDown }}</v-icon>
                </th>
                <th class="tac sorting" @click="sortBy('capacity')" :class="{ 'sorting-active': isSorting('capacity', 1) || isSorting('capacity', -1) }">
                  정 원
                  <v-icon v-if="sortByColumn === 'capacity'" :class="{ 'rotate180': isSorting('capacity', 1), 'rotate0': isSorting('capacity', -1) }">{{ sortDirection === 1 ? mdiArrowUp : mdiArrowDown }}</v-icon>
                </th>

                <!-- 현원에 대한 th -->
                <th class="tac sorting" @click="sortBy('pacient')" :class="{ 'sorting-active': isSorting('pacient', 1) || isSorting('pacient', -1) }">
                  현 원
                  <v-icon v-if="sortByColumn === 'pacient'" :class="{ 'rotate180': isSorting('pacient', 1), 'rotate0': isSorting('pacient', -1) }">{{ sortDirection === 1 ? mdiArrowUp : mdiArrowDown }}</v-icon>
                </th>
                <th class="tac">영양사</th>
                <th class="tac">조리원</th>
                <th class="tac sorting" @click="sortBy('address')" :class="{ 'sorting-active': isSorting('address', 1) || isSorting('address', -1) }">
                  주 소
                  <v-icon v-if="sortByColumn === 'address'" :class="{ 'rotate180': isSorting('address', 1), 'rotate0': isSorting('address', -1) }">{{ sortDirection === 1 ? mdiArrowUp : mdiArrowDown }}</v-icon>
                </th>
                <th class="tac">번 호</th>
                <th class="tac">이 메 일</th>
                <th class="tac">홈페이지</th>
                <th class="tac">비급여항목</th>
                <th class="tac">산출근거</th>
                <th class="tac">금 액 (일)</th>
                <th class="tac">금 액 (월)</th>
                <th class="tac">비고</th>
              </tr>
            </thead>


            <tbody v-if="crawlResultList && !loading">
              <template v-for="(result, index) in sortedCrawlResultList" :key="index">
                <tr v-for="(info, infoIndex) in result.infoArray3" :key="infoIndex">
                  <!-- 첫 번째 열에서만 rowspan을 적용 -->
                  <template v-if="infoIndex === 0">
                    <td :rowspan="result.infoArray3.length" class="tac">{{ index + 1 }}</td>
                    <td :rowspan="result.infoArray3.length" style="width: 90px;" class="tac">
                      {{ result.infoArray2[1]?.category || '' }}</td>
                    <td :rowspan="result.infoArray3.length" style="min-width: 5%; width: 170px;">{{
                      result.infoArray[0]?.name || '' }}</td>
                    <td :rowspan="result.infoArray3.length" style="width: 60px;" class="tac">
                      {{ result.infoArray[0]?.date || 0 }}</td>
                    <td :rowspan="result.infoArray3.length" style="width: 60px;" class="tac">
                      {{ result.infoArray2[0]?.capacity || 0 }}</td>
                    <td :rowspan="result.infoArray3.length" style="width: 60px;" class="tac">
                      {{ result.infoArray2[0]?.pacient || 0 }}</td>
                    <td :rowspan="result.infoArray3.length" style="width: 60px;" class="tac">{{ result.infoArray4[0]?.diet
                      || '' }}</td>
                    <td :rowspan="result.infoArray3.length" style="width: 60px;" class="tac">{{ result.infoArray4[0]?.cook
                      || '' }}</td>
                    <td :rowspan="result.infoArray3.length" style="width: 190px;">{{ result.infoArray[0]?.address || '' }}
                    </td>
                    <td :rowspan="result.infoArray3.length" style="width: 110px;" class="tac">{{ result.infoArray[0]?.tell
                      || '' }}</td>
                    <td :rowspan="result.infoArray3.length" style="max-width: 110px;">{{ result.infoArray[0]?.email ||
                      '' }}</td>
                    <td :rowspan="result.infoArray3.length"
                      style="max-width: 110px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                      <a :href="result.infoArray[0]?.homepage || '#'" target="_blank" rel="noopener noreferrer">
                        {{ result.infoArray[0]?.homepage || '' }}
                      </a>
                    </td>
                  </template>

                  <!-- 나머지 열들의 데이터 표시 부분 -->
                  <td style="width: 150px;">{{ info?.category || '' }}</td>
                  <td style="width: 230px;">{{ info?.reason || '' }}</td>
                  <td style="width: 100px;" class="tar">{{ info?.day_price || '' }}</td>
                  <td style="width: 100px;" class="tar">{{ info?.month_price || '' }}</td>
                  <td>{{ info?.bigo || '' }}</td>
                </tr>
              </template>
            </tbody>
          </table>
          <div v-if="crawlResultList.length === 0 && !loading" class="fontNotoSans700"
            style="width: 100%; height: 300px; text-align: center; display: flex; align-items: center; justify-content: center;">
            자료가 없습니다.</div>
          <div v-if="loading" class="spinner tac"></div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios, { AxiosResponse } from 'axios';
import { useStore } from 'vuex';
const store = useStore();
import {
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown
} from '@mdi/js'
onMounted(async () => {
  crawlResultList.value = store.state.crawlResultList;
  sortedCrawlResultList.value = crawlResultList.value;
  fetchExcelFilesList();
});

const selectedSiDoCd = ref('');
const selectedAdminKindCd = ref<string[]>([]);
const selectedTotalCd = ref<string[]>([]);
const regionOptions = [
  { label: '서울특별시', value: '11' },
  { label: '부산광역시', value: '26' },
  { label: '대구광역시', value: '27' },
  { label: '인천광역시', value: '28' },
  { label: '광주광역시', value: '29' },
  { label: '대전광역시', value: '30' },
  { label: '울산광역시', value: '31' },
  { label: '세종특별자치시', value: '36' },
  { label: '경기도', value: '41' },
  { label: '충청북도', value: '43' },
  { label: '충청남도', value: '44' },
  { label: '전북특별자치도', value: '52' },
  { label: '전라남도', value: '46' },
  { label: '경상북도', value: '47' },
  { label: '경상남도', value: '48' },
  { label: '제주특별자치도', value: '50' },
  { label: '강원특별자치도', value: '51' },
];




const crawlResultList = ref([] as any[]);
const sortByColumn = ref('');
const sortDirection = ref(1);

const sortedCrawlResultList = ref([] as any[]);

const sortingColumn = ref('');

const isSorting = (column: string, direction: number) => {
  return sortingColumn.value === column && sortDirection.value === direction;
};

const sortBy = (column: any) => {
  if (column === sortByColumn.value) {
    sortDirection.value = -sortDirection.value;
  } else {
    sortDirection.value = -1; // 첫 클릭 시 큰 순서대로 소팅
    sortByColumn.value = column;
  }
  sortingColumn.value = column; // sortingColumn 값 업데이트

  sortedCrawlResultList.value = [...crawlResultList.value].sort((a, b) => {
    let valueA, valueB;

    if (column === 'name') {
      valueA = a.infoArray[0]?.name || '';
      valueB = b.infoArray[0]?.name || '';
    } else if (column === 'address') {
      valueA = a.infoArray[0]?.address || '';
      valueB = b.infoArray[0]?.address || '';
    } else if (column === 'date') {
      valueA = a.infoArray[0]?.date || '';
      valueB = b.infoArray[0]?.date || '';
    } else {
      valueA = typeof a.infoArray2[0][column] === 'number' ? a.infoArray2[0][column] : 0;
      valueB = typeof b.infoArray2[0][column] === 'number' ? b.infoArray2[0][column] : 0;
    }

    // 데이터의 유형에 따라 올바른 비교 방법을 사용합니다.
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueB.localeCompare(valueA) * sortDirection.value;
    } else {
      // 숫자형 데이터의 경우, 빈 문자열이 아닌 경우에만 비교
      return (valueA - valueB) * sortDirection.value;
    }
  });

};

const loading = ref(false);

const submitForm = async (crawl : string): Promise<void> => {
  try {
    if(crawl === 'normal'){
        if (getSiDoCd(selectedSiDoCd.value) === null || selectedAdminKindCd.value.length === 0 || selectedTotalCd.value.length === 0) {
        alert('조건을 모두 선택해주세요.')
        return;
      }
    }else if(crawl === 'insert'){
      if (getSiDoCd(selectedSiDoCd.value) === null ) {
        alert('지역 설정은 필수입니다.')
        return;
      }
    }
    loading.value = true;
    let crawlRoot = '';
    if(crawl === 'normal'){
      crawlRoot = 'crawl'
    }else{
      crawlRoot = 'crawlForInsert'
    }

    const response: AxiosResponse<{ data: any[] }> = await axios.post(`/api/${crawlRoot}`, {
      siDoCd: getSiDoCd(selectedSiDoCd.value) || null,
      adminKindCd: selectedAdminKindCd.value || [''],
      totalCd: selectedTotalCd.value || ['']
    });
    
    const crawlResponse = response.data.data as any[];
    crawlResultList.value = crawlResponse.map(crawl => ({ ...crawl }));
    
    store.commit('setCrawlResultList', crawlResultList.value);
    if (crawlResultList.value.length > 0) {
      fetchExcelFilesList();
      sortedCrawlResultList.value = crawlResultList.value;
    } else {
      sortedCrawlResultList.value = [];
    }
  } catch (error) {
    console.error('크롤링이 실패하였습니다. :', error);
  } finally {
    loading.value = false;
  }
};

const getSiDoCd = (selectedSiDoCd: string): string | null => {
  const region = regionOptions.find((option) => option.value === selectedSiDoCd);
  return region ? region.value : null;
};


// 엑셀리스트 코드
const isListVisible = ref(false);
const excelFiles = ref([]);

const maxItemsPerColumn = 10;
const totalColumns = computed(() => Math.ceil(excelFiles.value.length / maxItemsPerColumn));

const columns = computed(() => {
  return Array.from({ length: totalColumns.value }, (v, i) =>
    excelFiles.value.slice(i * maxItemsPerColumn, (i + 1) * maxItemsPerColumn)
  );
});

const fetchExcelFilesList = async () => {
  try {
    const response = await axios.get('/api/crawling_excel/read');
    excelFiles.value = response.data;
  } catch (error) {
    console.error('client_엑셀 파일 목록을 가져오는 중 오류 발생:', error);
  }
};

const deleteExcelFile = async (fileName: any) => {
  try {
    await axios.delete(`/api/crawling_excel/delete/${fileName}`);
    fetchExcelFilesList();
  } catch (error) {
    console.error('엑셀 파일 삭제 중 오류 발생:', error);
  }
};

const downloadExcelFile = (fileName: any) => {
  window.location.href = `/api/crawling_excel/${fileName}/download`;
};

const toggleList = () => {
  isListVisible.value = !isListVisible.value;
};

const confirmDelete = (fileName: any) => {
  const isConfirmed = window.confirm('정말 삭제하시겠습니까?');
  if (isConfirmed) {
    deleteExcelFile(fileName);
  }
};

</script>

<style scoped>
.spinner {
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-left: 30px solid #8b2197;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
  margin: 60px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.crawlCard {
  padding: 12px;
  margin: 16px;
}

form {
  font-size: 15px;
}

.longtermTable {
  border-collapse: collapse;
  width: 100%;
  margin: auto;
  font-size: 13px;
}

.longtermTable th,
.longtermTable td {
  border: 1px solid #c8c8c8;

  padding: 4px;
  width: auto;
}

.longtermTable tr:hover {
  background-color: #e3e3e3;

}

.longtermTable th {
  background-color: #f2f2f2;
}


.longtermVtable tbody tr .labelTd {
  padding-right: 0 !important;
  max-width: 66px;
  min-width: 66px;
  width: 66px;
}

.longtermVtable tbody tr .selectTd {
  max-width: 150px;
  min-width: 150px;
  width: 150px;
  border-right: 2px solid #c8c8c8;
}

.sorting {
  cursor: pointer;
}

.selectDiv input {
  width: 17px;
  height: 17px;
  margin-right: 2px;
  margin-left: 10px;
}

.selectDiv {
  display: flex;
  align-items: center;
}

.longtermExcelList div {
  margin: 3px 5px
}

.longtermExcelList li {
  margin: 5px 5px 5px 5px;
}

.longtermExcelList .list-container li:hover,
.longtermExcelList .list-container li:focus {
  font-size: 15px;
  font-weight: 700;
  color: rgb(12, 93, 36) !important;
}</style>
