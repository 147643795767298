<template>
  <div class="snake-game" style="padding-top: 130px;">
    <canvas ref="canvas" :width="canvasSize" :height="canvasSize" class="game-canvas"></canvas>
    <div class="controls">
      <p>점수: {{ score }}</p>
      <p v-if="gameOver">게임 종료! 다시 시작하려면 아무 키나 누르세요.</p>
    </div>
  </div>

</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

const canvasSize = 900; // 게임 화면 크기
const tileSize = 25; // 타일 크기
const canvas = ref<HTMLCanvasElement | null>(null);

const score = ref(0);
const gameOver = ref(false);

// 방향 설정 (위: [0, -1], 아래: [0, 1], 왼쪽: [-1, 0], 오른쪽: [1, 0])
const direction = ref<[number, number]>([1, 0]);
const snake = ref<Array<[number, number]>>([[5, 5]]); // 초기 지렁이 위치
const food = ref<[number, number]>([10, 10]); // 초기 먹이 위치
let gameLoop: number | null = null;


const resetGame = () => {
  score.value = 0;
  gameOver.value = false;
  direction.value = [1, 0];
  snake.value = [[5, 5]];
  food.value = generateFood(); // 먹이를 중앙 쪽에 생성
};

const generateFood = (): [number, number] => {
  const gridCount = canvasSize / tileSize;

  // 중앙 범위를 정의
  const centerRange = {
    min: Math.floor(gridCount * 0.05), // 화면의 30% 지점
    max: Math.ceil(gridCount * 0.95), // 화면의 70% 지점
  };

  // 무작위 좌표 생성
  const isCentral = Math.random() < 0.95; // 중앙 쪽에 나올 확률 (70%)
  const randomCoord = () => Math.floor(Math.random() * gridCount);
  const centralCoord = () =>
    Math.floor(
      Math.random() * (centerRange.max - centerRange.min) + centerRange.min
    );

  // 중앙 또는 일반 위치에 따라 먹이 생성
  const x = isCentral ? centralCoord() : randomCoord();
  const y = isCentral ? centralCoord() : randomCoord();

  return [x, y];
};


const draw = () => {
  const ctx = canvas.value?.getContext("2d");
  if (!ctx) return;

  // 화면 초기화
  ctx.fillStyle = "#000";
  ctx.fillRect(0, 0, canvasSize, canvasSize);

  // 먹이 그리기
  ctx.fillStyle = "red";
  ctx.fillRect(food.value[0] * tileSize, food.value[1] * tileSize, tileSize, tileSize);

  // 지렁이 그리기
  ctx.fillStyle = "lime";
  snake.value.forEach(([x, y]) => {
    ctx.fillRect(x * tileSize, y * tileSize, tileSize, tileSize);
  });
};
const nextDirection = ref<[number, number] | null>(null);

const handleKeydown = (e: KeyboardEvent) => {
  if (gameOver.value) {
    resetGame();
    gameLoop = setInterval(gameTick, 40);
    return;
  }

  const keyMap: Record<string, [number, number]> = {
    ArrowUp: [0, -1],
    ArrowDown: [0, 1],
    ArrowLeft: [-1, 0],
    ArrowRight: [1, 0],
  };

  if (keyMap[e.key]) {
    const [dx, dy] = keyMap[e.key];
    // 역방향 이동 방지
    if (dx !== -direction.value[0] && dy !== -direction.value[1]) {
      nextDirection.value = [dx, dy];
    }
  }
};

const moveSnake = () => {
  if (gameOver.value) return;

  // 방향 업데이트 (다음 방향이 설정된 경우 반영)
  if (nextDirection.value) {
    direction.value = nextDirection.value;
    nextDirection.value = null;
  }

  const newHead: [number, number] = [
    snake.value[0][0] + direction.value[0],
    snake.value[0][1] + direction.value[1],
  ];

  // 충돌 처리
  if (
    newHead[0] < 0 ||
    newHead[1] < 0 ||
    newHead[0] >= canvasSize / tileSize ||
    newHead[1] >= canvasSize / tileSize ||
    snake.value.some(
      (segment) => segment[0] === newHead[0] && segment[1] === newHead[1]
    )
  ) {
    gameOver.value = true;
    clearInterval(gameLoop!);
    return;
  }

  snake.value.unshift(newHead);

  // 먹이를 먹으면 점수 증가
  if (newHead[0] === food.value[0] && newHead[1] === food.value[1]) {
    score.value += 10;
    food.value = generateFood(); // 새로운 먹이 생성
  } else {
    snake.value.pop(); // 먹지 않았으면 꼬리 제거
  }
};

const gameTick = () => {
  moveSnake();
  draw();
};

onMounted(() => {
  resetGame();
  draw();
  window.addEventListener("keydown", handleKeydown);
  gameLoop = setInterval(gameTick, 40);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", handleKeydown);
  if (gameLoop) clearInterval(gameLoop);
});
</script>

<style scoped>
.snake-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.game-canvas {
  border: 2px solid #fff;
  background-color: #000;
}
.controls {
  text-align: center;
  margin-top: 10px;
  font-size: 40px;
}
</style>
