<template>
  <img
    @click="dialog = true" 
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :style="{
      position: 'fixed',
      right: '1%',
      top: '49%',
      minWidth: '50px',
      minHeight: '50px',
      maxWidth: '3% !important',
      maxHeight: '6% !important',
      backgroundColor: 'aquamarine',
      borderRadius: '100%',
      cursor: 'pointer',
      transform: hover ? 'scale(1.1)' : 'scale(1)',
      transition: 'transform 0.3s ease'
    }"
    cover 
    src="@/assets/pictures/main/환급.png" 
    alt=""
  >
  <v-dialog v-model="dialog" style="width: 100%;" class="fontNotoSans400" >
    <WageCompInfo />
  </v-dialog>
</template>


<script setup lang="ts">

import { ref } from 'vue';
import WageCompInfo from '@/components/wage/WageCompInfo.vue';

const dialog = ref(false);
const hover = ref(false);
</script>
