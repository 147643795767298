<template>
  <div v-if="loading">
    <h2 class="tac">메일발송 중..</h2>
    <div class="spinner tac"></div>
  </div>

  <div v-else>
    <div class="mb-5" style="display: flex; align-items: center; justify-content: center;">
      <b class="mr-2">구분</b><select v-model="imgName" style="outline: auto; padding: 3px 20px 3px 20px;">
        <option v-for="status in imgNameValue" :key="status.value" :value="status.value">{{ status.label }}
        </option>
      </select>
      <v-divider class="mx-4" inset vertical></v-divider>

      <v-btn @click="dialog = true" class="btnColorBlue">이미지 바꾸기</v-btn>

      <v-divider class="mx-4" inset vertical></v-divider>
      <v-text-field v-model="toEmail" placeholder="보내실 메일 주소를 입력해주세요." type="text" hide-details="auto" variant="outlined" density="compact"
        style="max-width:270px;" />
        <v-text-field v-model="subject" placeholder="이곳에 제목을 입력해주세요." type="text" hide-details="auto" variant="outlined" density="compact"
        style="max-width:270px;" />
      <v-btn @click="sendTestMail" class="ml-2 btnColorGreen">메일발송</v-btn>
      <v-dialog v-model="dialog" style="width: 700px;" class="fontNotoSans400">
        <v-card>
          <div style="background-color: #333; color: #fff; padding: 10px 20px;">
            <h3>이미지 변경 시 기존 이미지는 삭제됩니다.</h3>
          </div>
          <v-card-text>
            <div>
              <input type="file" accept="image/*" @change="handleFileUpload"
                style="width: 100%; margin-bottom: 10px; border-bottom: 2px solid #333;">
            </div>
            <div>
              <img :src="imageMiribogi || ''" alt=" 이미지 확인 후 업로드버튼을 눌러주세요."
                style="max-width: 100%; max-height: 100%; margin-bottom: 5px;">
            </div>
          </v-card-text>
          <v-btn @click="sendMailImg" class="btnColorBlue">업로드</v-btn>
          <v-btn @click="dialog = false" class="btnColorDark">닫기</v-btn>
        </v-card>
      </v-dialog>
    </div>

    <v-sheet style="max-width: 600px !important; margin: auto;">
      <b v-if="imageUrl === 'nothing'">업로드된 파일이 존재하지 않습니다.</b>
      <img style="width: 100%;" v-else :src="imageUrl" alt="Promotional Image" />
    </v-sheet>
  </div>
</template>
<script setup lang="ts">
import axios from 'axios';
import { computed, onMounted, ref, watch } from 'vue';
const dialog = ref(false);
const loading = ref(false);
const toEmail = ref('vgusdn729@gmail.com');
const subject = ref('');
const sendTestMail = async () => {
  if(subject.value === ''){
    alert('제목을 입력해주세요.');
    return;
  }
  const isConfirmed = window.confirm(`${toEmail.value} 메일을 발송하시겠습니까?`);
  if (isConfirmed) {
    loading.value = true;
    try {
      const subjectEncode = encodeURIComponent(subject.value)
      const response = await axios.post(`/api/sendTestMail/${toEmail.value}/${subjectEncode}/${imgName.value}/${imageType.value}`);
      const result = response.data;
      if (result.success) {
        return result; // 가져온 데이터를 반환
      } else {
        alert('메일 전송에 실패하였습니다. 관리자에게 문의바랍니다.')

        console.error('에러가 발생했습니다.', result);
      }
    } catch (error) {
      console.error('errorMsg:', error);
    } finally {
      loading.value = false;
    }
  }

};


const imgNameValue = ref([
  { label: '사회보험 환급 서비스 홍보', value: 'hamwooriPayBack' },
  { label: '급식 서비스 홍보', value: 'hamwooriMeal' },
  { label: '회계 서비스 홍보', value: 'hamwooriFna' },
  { label: '플랫폼 서비스 홍보', value: 'hamwooriPlatform' },
]);
const imageType = ref();
const imageUrl = ref();
const imgName = ref(imgNameValue.value[0].value);

const changeImg = computed(() => {
  const whatImgName = imgName.value
return whatImgName;
});

watch(changeImg, async (newValue : any) => {
  if (newValue) {
    await fetchImage();
  }
});

const fetchImage = async () => {
  imageUrl.value = await getPromotionMail();
};

const getPromotionMail = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`/api/getPromotionMail/${imgName.value}`, { responseType: 'blob' });
    if(response.data){
      //이미지타입 추출
      imageType.value = response.data.type.split('/')[1];
      if(imageType.value === 'jpeg'){
        imageType.value = 'jpg';
      }
      const imageBlob = response.data;
      const imageObjectURL = URL.createObjectURL(imageBlob);
      return imageObjectURL; // 가져온 이미지 URL을 반환
    }
  } catch (error) {
    const imageObjectURL = 'nothing'
    return imageObjectURL;
  } finally {
    loading.value = false;
  }
};


const imageMiribogi = ref();
const imageFile = ref();
const ext = ref();
const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    const file = target.files[0];
    ext.value = file.name.split('.').pop();
    const reader = new FileReader();
    reader.onload = () => {
      imageMiribogi.value = reader.result;
    };
    reader.readAsDataURL(file);
    imageFile.value = file;
  }
};
const sendMailImg = async () => {

  if (!imageFile.value) {
    alert('이미지를 선택하세요.');
    return;
  }
  const allowedTypes = ['image/jpeg', 'image/png'];
  if (!allowedTypes.includes(imageFile.value.type)) {
    alert('JPG 또는 PNG 파일만 업로드 가능합니다.');
    loading.value = false;
    return;
  }
  loading.value = true;

  try {
    if (imageUrl.value !== 'nothing') {
      await deleteMailImg();
    }
    const formData = new FormData();
    formData.append('image', imageFile.value);
    const response = await axios.post(`/api/sendMailImg/${imgName.value}/${ext.value}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response && response.data.success) {
      await fetchImage();
      dialog.value = false;
    } else {
      console.error('이미지 업로드 실패', response.data);
    }
  } catch (error) {
    console.error('이미지 업로드 중 에러 발생', error);
  } finally {
    loading.value = false;
  }
};
const deleteMailImg = async () => {
  try {
    const response = await axios.post(`/api/deleteMailImg/${imgName.value}`);
    if (!response) {
      alert('기존 파일 삭제 에러 발생.')
      return;
    }
  } catch (error) {
    console.error('이미지 업로드 중 에러 발생', error);
  }
};


onMounted(() => {
  fetchImage();
})
</script>
<style scoped>
.spinner {
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-left: 30px solid #8b2197;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
  margin: 60px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>