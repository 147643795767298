<template>
  <v-text-field
    @click="findAddress"
    readonly
    :prepend-inner-icon="mdiMapLegend"
    variant="outlined"
    label="기관 주소"
    type="text"
    v-model="address01"
    maxlength="150"
    color="indigo"
    autocomplete="off"
  />
</template>

<script setup>
import { ref, defineEmits, defineProps } from "vue";
// eslint-disable-next-line no-undef
const emit = defineEmits(["updateAddress"]);
import { mdiMapLegend } from "@mdi/js";
const props = defineProps({
  addr: {
    type: Object,
    required: true,
  },
});
const zoneCode = ref("");
const address01 = ref(props.addr);

const findAddress = () => {
  new window.daum.Postcode({
    oncomplete: (data) => {
      zoneCode.value = data.zonecode;
      address01.value = data.address;

      emit("updateAddress", {
        // zoneCode: zoneCode.value,
        address: address01.value,
      });
    },
  }).open();
};
</script>
<!-- 
<DaumAddr @updateAddress="updateAddress" :addr="form.sisul_addr"/>

import DaumAddr from '@/components/api/DaumAddr.vue'
const updateAddress = (address: any) => {
  form.sisul_addr = `${address.address}`;
};
-->
