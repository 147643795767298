<template>
  <v-sheet class="mainMaxWidth consulting-menu main-menu-sheet">
    <div class="image-container">
        <img src="@/assets/pictures/main/메뉴_운영.png" class="main-menu-img" cover />
        <div class="main-menu-overlay">
          <p class="main-menu-text">운영컨설팅</p>
          <span class="main-menu-sub-text">Operation consulting</span>
        </div>
      </div>
    <div class="main-menu-box">
      <div class="main-menu-content">
        <p class="main-menu-top-ment">요양시설 단계별 컨설팅</p>
        <v-row style="margin: 40px 0">
          <v-col cols="4" style="padding-right: 10px;">
            <img src="@/assets/pictures/main/메뉴_운영_구두.png" class="consulting_img" cover />
          </v-col>
          <v-col cols="8" style="padding-left: 10px;">
          <v-row>
            <v-col>
              <div style="background-color: #F5F5F5; width:100%; height:100%; border-radius: 10px;">
                <div style="padding: 10px 20px 25px 20px;">
                  <div class="consulting-content-check-text-div">
                    <p>Step 1. Diagnosis</p>
                    <p><span>✔</span> 목표 설정</p>
                    <p><span>✔</span> 데이터 수집</p>
                    <p><span>✔</span> 문제 분석</p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col>
              <div style="background-color: #F5F5F5; width:100%; height:100%; border-radius: 10px;">
                <div style="padding: 10px 20px 25px 20px;">
                  <div class="consulting-content-check-text-div">
                    <p>Step 2. Planning</p>
                    <p><span>✔</span> 전략 개발</p>
                    <p><span>✔</span> 목표 설정</p>
                    <p><span>✔</span> 자원 배분</p>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row style="height: 191.25px;">
            <v-col>
              <div style="background-color: #F5F5F5; width:100%; height:100%; border-radius: 10px;">
                <div style="padding: 10px 20px 25px 20px;">
              <div class="consulting-content-check-text-div">
                  <p>Step 3. Implementation</p>
                  <p><span>✔</span> 계획 설정</p>
                  <p><span>✔</span> 모니터링</p>
                  <p><span>✔</span> 피드백 수집</p>
                </div>
              </div>
            </div>
            </v-col>
            <v-col>
              <div style="background-color: #F5F5F5; width:100%; height:100%; border-radius: 10px;">
              <div style="padding: 10px 20px 25px 20px;">
              <div class="consulting-content-check-text-div">
                  <p>Step 4. Evaluation</p>
                  <p><span>✔</span> 성과 평가</p>
                  <p><span>✔</span> 개선방안 도출</p>
                  <p><span>✔</span> 지속적인 개선</p>
                </div>
              </div>
            </div>
          </v-col>
          </v-row>
          </v-col>
        </v-row>
        <p class="main-menu-top-ment" style="margin-top: 80px;">요양시설 성공 전략</p>
        <v-row style="margin: 40px 0">
          <v-col cols="6" style="padding-left: 10px;">
            <div style="background-color: #F5F5F5; width:100%; height:100%; border-radius: 10px;">
              <div style="padding: 15px 20px;">
                <div class="consulting-content-check-text-div">
                  <p>1. 브랜드 구축 전략</p>
                  <p>• 신뢰기반의 장기적인 관계형성</p>
                  <p>• 정체성 구축으로 보호자에게 일관된 이미지 제공</p>
                  <p>• 마케팅 도구로써의 역할과 우리 기관의 메시지를 효과적으로 전달</p>
                  <p>• 타 요양시설과의 차별화로 우리 기관만의 가치 제공</p>
                </div>
                <div class="consulting-content-check-text-div">
                  <p>2. 운영 전략</p>
                  <p>• 조직 체계와 문화 개선</p>
                  <p>• 표준화된 운영체계 마련</p>
                  <p>• 수급자 만족도 향상 전략</p>
                </div>
                <div class="consulting-content-check-text-div">
                  <p>3. 수익성 향상 전략</p>
                  <p>• 재정상태 진단 및 분석</p>
                  <p>• 효과적인 재정관리 전략</p>
                  <p>• 자금누수 방지</p>
                  <p>• 효율적인 경비처리 방안</p>
                  <p>• 임금관리 개선</p>
                </div>
                <div class="consulting-content-check-text-div">
                  <p>4. EXIT 전략</p>
                  <p>• 승계 or 매각or 절세</p>
                  <p>• EXIT 방향성 진단</p>
                  <p>• EXIT 전략 마련</p>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" style="padding-right: 10px;">
            <img src="@/assets/pictures/main/메뉴_운영_만세.png" class="consulting_img" cover />
          </v-col>
        </v-row>
     </div>
    </div>
  </v-sheet>
</template>
<script setup>
import { onMounted} from 'vue';

onMounted(() => {
  window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
})
</script>
<style>
.consulting-content-check-text-div span{
  color: rgba(3, 3, 91, 0.744);
}
.consulting-content-check-text-div:not(:last-child){
  border-bottom: 1px solid #d2d2d2; 
  padding-bottom: 10px;
}
.consulting-content-check-text-div p:first-child{
  margin: 7px 0;
  font-size: 22px;
  font-weight: 600;
  padding-left: 15px;
  padding-bottom: 7.4px;
  letter-spacing: -0.1rem;
}
.consulting-content-check-text-div p:not(:first-child){
  font-size: 18px;
  padding-left: 25px;
  font-weight: 500;
  padding-bottom: 3px;
}

.consulting_img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
</style>