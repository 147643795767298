<template>
  <v-sheet class="indexSection4 mainMaxWidth" style="background-color: #fff;">
    <div class="i4_box">
      <div style="width: 100%; text-align: left;">
      <p class="i4_top_ment">안녕하십니까? <br>요양기관 성공 플랫폼, 함우리입니다. 방문해주셔서 감사합니다.</p>
      <br>
      <p class="i4_top_ment2">
        열심히 한다고, 성실하다고 성공하지 않습니다.
        베이비부머 세대가 장기요양보험 서비스 대상으로 진입하기 시작했습니다.
        요양시설이 6,200개(’23년 기준)를 초과하였습니다. 초고령화 사회로의 진입을 대변한다 할 수 있습니다.
        규모가 작은 시설들은 서비스 경쟁에서 밀려나고 있으며, 대규모 시설의 증가가 계속되고 있습니다.
        인지가 높아진 잠재적 고객들과 보호자들의 수준이 높아져 원하는 바를 서비스로 연결해야 합니다.
        정부정책과 공단이 원하는 방향에 전략적으로 연구하고 지혜롭게 대처해야 합니다.
        시대적, 지역적 흐름을 분석하여 브랜딩화 해야 하고, 조직적 체계적 운영이 절실합니다.
        지금부터  출구전략을 가지고, 단계별 정확한 목표를 설정하여 이루어 나가야 합니다. 
        ‘요양기관 성공 플랫폼’으로 원장님의 든든한 버팀목이자 디딤돌이 되어 드리겠습니다. <br><br>
        감사합니다.
        <span style="float: right;">주식회사 함우리</span>
        </p>
        
      </div>
    </div>
  </v-sheet>
</template>
<script setup>
import { onMounted} from 'vue';

onMounted(() => {
  window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
})
</script>
<style>
.i4_top_ment{
  font-size: 28px;
  letter-spacing: -0.03em;
  margin: 0 0 20px 0;
  color: #333;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1); /* 그림자 추가 */
  font-weight: 500;
}
.i4_top_ment2{
  font-size: 24px;
  margin: 0 0 20px 0;
  color: #333;
  letter-spacing: -0.03em;
  line-height: 1.8;

  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1); /* 그림자 추가 */
}
</style>