<template>
<v-row>
  <v-col v-if="toggle === true" xl="1" lg="1" md="2"  sm="2" xs="3" cols="3" style="min-width: 400px; margin:0 0 0 15px;  padding: 0 !important;">
    <SsBasic/>
  </v-col>
  <v-col v-if="toggle === true" style="max-width: 20px; padding: 0; display: flex; align-items: center; justify-content: center;">
    <v-btn @click="toggleBtn" style="min-height: 100px !important; width: 100%; background-color: #333; padding: 0;">
      <v-icon style="color: #fff; width:30px; height: 100% !important;">{{ mdiMenuLeft }}</v-icon>
    </v-btn>
  </v-col>

  <v-col v-if="toggle === false" style="max-width: 20px; padding: 0; display: flex; align-items: center; justify-content: center;">
    <v-btn @click="toggleBtn" style="min-height: 100px !important; width: 100%; background-color: #333; padding: 0;">
      <v-icon style="color: #fff; width:30px; height: 100% !important;">{{ mdiMenuRight }}</v-icon>
    </v-btn>
  </v-col>
  <v-col style= "min-width: 800px; padding-left: 0;">
    <SsCompRight/>
  </v-col>
</v-row>
</template>
<script setup lang="ts">
import SsBasic from '@/components/sisul_comp/SsBasic.vue'
import SsCompRight from '@/components/sisul_comp/SsCompRight.vue'
import { ref } from 'vue';
import {
  mdiMenuRight,
  mdiMenuLeft
} from '@mdi/js'
const toggle = ref(true);
const toggleBtn = () => {
  toggle.value = !toggle.value; 
}
</script>