<template>기관관리 개발 중</template>

<script setup lang="ts">
import router from '@/router';
import store from '@/store';
import { onMounted } from 'vue';


const navigateTo = (path: any, comp: any) => {
  router.push(path);
  store.commit('setMngTitle', comp);
};
const empSec = [
  { title: '사원정보', link: 'SsEmpList'},
];
onMounted(() => {
  navigateTo('/SsEmpPage', empSec[0])
})
</script>