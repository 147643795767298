<template>
  <v-stepper prev-text="이 전" next-text="다 음" class="stepper tac">
    <template v-slot:default="{ prev, next }">
      <v-stepper-header>
        <v-stepper-item complete title="기관조회" value="0" :rules="[() => rule_1(wageInfo)]"></v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item title="간편진단" value="1" :rules="[() => rule_1(wageInfo)]"></v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item title="상세진단신청" value="2"></v-stepper-item>

      </v-stepper-header>

      <v-stepper-window>
        <v-stepper-window-item value="0">
          <div class="stepperDiv">
            <v-table class="stepperTb">
              <tbody>
                <tr>
                  <td class="tal">지역</td>
                  <td class="tal">
                    <select style="cursor: pointer;" v-model="wageInfo.wg_sido" @change="getSigungu()"
                      class="mg-right10">
                      <option value="" class="tac">--- 선택 ---</option>
                      <option class="tal" v-for="sido in sidoInfo" :key="sido.sido_id" :value="sido.sido_id">
                        {{ sido.sido_name }}
                      </option>
                    </select>
                    <select style="cursor: pointer;" v-model="wageInfo.wg_sigungu" @change="getCompName()">
                      <option value="" class="tac">--- 선택 ---</option>
                      <option class="tal" v-for="sigungu in sigunguInfo" :key="sigungu.sigungu_id"
                        :value="sigungu.sigungu_id">{{ sigungu.sigungu_name }}
                      </option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td class="tal">구분</td>
                  <td class="tal">
                    <v-radio-group v-model="wageInfo.wg_biz_gubun" @change="getCompName()" class="label-black" inline
                      flat hide-details="auto">
                      <v-radio label="요양시설" value="요양시설"></v-radio>
                      <v-radio label="주간보호" value="주간보호"></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr>
                  <td class="tal">기관 명</td>
                  <td class="tal">
                    <v-combobox v-model="wageInfo.wg_name" :items="compNames" placeholder="기관 명을 입력해주세요." type="text"
                      variant="outlined" density="compact" hide-details="auto"
                      style="max-width: 280px !important; color: #000 !important;" class="activeClick" clearable>
                    </v-combobox>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </v-stepper-window-item>

        <v-stepper-window-item value="1">
          <v-skeleton-loader type="image, list-item-three-line" elevation="12" :loading="loadingChart">
            <v-row class="wageChartRow">
              <v-col cols="12" md="6" sm="10" xs="12" class="wageChartCol">
                <v-card class="steppersImgCard overflow-visible position-relative" elevation="2" variant="elevated">
                  <img class="man1" src="@/assets/pictures/man1.png" />
                  <div class="contentComp">

                    <div class="stepper2MentBDiv">
                      <b class="stepper2MentB" style="color: #593CBA;">기관 </b><span class="stepper2MentSpan">인건비
                        절감금액</span>
                    </div>
                    <div>
                      <span>{{ formatToKRW(comp_avg) }} ~ </span>
                      <b class="stepper2MentB" style="color: #593CBA;">{{ formatToKRW(comp_max) }}</b>
                    </div>
                    <div class="stepper2MentChipDiv">
                      <v-chip class="stepper2MentChip blue-darken-4" variant="tonal" size="large" elevation="0">
                        연간 인건비 절감 예상금액
                      </v-chip>
                    </div>
                  </div>
                </v-card>
              </v-col>
              
              <v-col cols="12" md="6" sm="10" xs="12" class="wageChartCol">
                <v-card class="steppersImgCard overflow-visible position-relative" elevation="2" variant="elevated">
                  <img class="woman1" src="@/assets/pictures/woman1.png" />
                  <div class="contentLabor">

                    <div class="stepper2MentBDiv">
                      <b class="stepper2MentB" style="color: #d66503;">근로자 </b><span class="stepper2MentSpan">실수령액
                        증가</span>
                    </div>
                    <div>
                      <span>{{ formatToKRW(labor_avg) }} ~ </span>
                      <b class="stepper2MentB" style="color: #d66503;">{{ formatToKRW(labor_max) }}</b>
                    </div>
                    <div class="stepper2MentChipDiv">
                      <v-chip class="stepper2MentChip blue-darken-4" variant="tonal" size="large" elevation="0">
                        1인당 연간 실수령액
                      </v-chip>
                    </div>
                  </div>
                </v-card>
              </v-col>

              

              <v-col cols="12" md="12" sm="10" xs="12" class="wageChartCol">
                <v-card class="steppersImgCard overflow-visible position-relative" elevation="2" variant="elevated">
                  <img class="money1" src="@/assets/pictures/money1.png" />
                  <div class="contentPayBackMoney">

                    <div class="stepper2MentBDiv"><span class="stepper2MentSpan">더 낸 사회보험 예상 </span>
                      <b class="stepper2MentB" style="color: #FFBB22;">환급금 </b><span class="stepper2MentSpan"></span>
                    </div>
                    <div>
                      <span>{{ formatToKRW(payback_avg) }} ~ </span>
                      <b class="stepper2MentB" style="color: #FFBB22;">{{ formatToKRW(payback_max) }}</b>
                    </div>
                    <div class="stepper2MentChipDiv">
                      <v-chip class="stepper2MentChip blue-darken-4" variant="tonal" size="large" elevation="0">
                        {{ getPreviousMonth() }}
                      </v-chip>
                    </div>

                  </div>
                </v-card>
              </v-col>

            </v-row>
            <v-row class="wageChartRow">
              <v-col cols="12" md="12" sm="10" xs="12" class="wageChartCol">
                <v-card class="wageChartText">
                  <v-card-title class="tac mb-2">
                    <b>NOTICE</b>
                  </v-card-title>
                <span>1. 절감기준은 최신 소득세법, 근로기준법을 근거로 작성되었습니다.</span><br />
                <span>2. 위의 내용은 장기요양보험공단의 기관정보를 토대로 작성되었습니다.</span><br />
                <span>3. 해당 금액은 귀 기관과 규모가 비슷한 기관들의 평균 절감금액이오니 착오없으시기 바랍니다.</span>
                <p class="mt-2">* 더욱 자세한 내용을 원하신다면 하단의 '<b>다음</b>' 버튼을 클릭 후 '<b>상세진단신청</b>'을 완료해주세요. </p>
              </v-card>
              </v-col>
              
            </v-row>
              

          </v-skeleton-loader>

        </v-stepper-window-item>


        <v-stepper-window-item value="2">

          <div class="wageClientInfoForm">

            <v-text-field :prepend-inner-icon="mdiBank" variant="outlined" label="기관 명" type="text"
              v-model="wageInfo.wg_name" readonly :rules="compNameRule" color="indigo" />

            <v-text-field :prepend-inner-icon="mdiLicense" variant="outlined" label="사업 구분" type="text"
              v-model="wageInfo.wg_biz_gubun" readonly :rules="compGubunRule" color="indigo" />

            <v-text-field :prepend-inner-icon="mdiAccountTie" variant="outlined" label="담당자 성명" type="text"
              v-model="clientInfo.wg_client_name" maxlength="10" :rules="nameRule" color="indigo" />

            <v-text-field :prepend-inner-icon="mdiCellphone" variant="outlined" label="휴대폰" type="text"
              v-model="clientInfo.wg_client_phone" maxlength="11" :rules="phoneRule" color="indigo" />

            <div class="wageAuthForm">

              <v-text-field v-if="clientInfo.wg_client_email_auth === false && seconds === 0 && !loading"
                :prepend-inner-icon="mdiEmailVariant" variant="outlined" label="이메일" hint="이메일 형식을 맞춰주세요." type="email"
                v-model="clientInfo.wg_client_email" maxlength="50" :rules="emailRule" color="indigo" />

              <v-text-field v-else :prepend-inner-icon="mdiEmailVariant" variant="outlined" label="이메일"
                hint="이메일 형식을 맞춰주세요." type="email" v-model="clientInfo.wg_client_email" maxlength="50"
                :rules="emailRule" color="indigo" readonly />

              <v-btn v-if="seconds === 0 && !loading" @click="sendWageAuthMail()">인증번호받기</v-btn>

              <v-btn v-else-if="seconds > 0 && seconds <= 270 && !loading" @click="sendWageAuthMail()">재발송</v-btn>

              <div v-if="loading" class="spinner tac"></div>

            </div>


            <v-tooltip text="Tooltip" v-if="clientInfo.wg_client_email_auth === false && seconds !== 0">
              <template v-slot:activator="{ props }">
                <v-text-field v-bind="props" :prepend-inner-icon="mdiLockOutline" v-model="authCodeInput" label="인증번호"
                  density="compact" variant="outlined" style="padding:0 !important;" :rules="authNumRule" maxlength="6"
                  color="indigo" @change="authRandomCode(authCodeInput)" />

              </template>
              <div v-if="clientInfo.wg_client_email_auth === false && seconds !== 0">
                <span>인증시간이 {{ minutes }}분 {{ remainingSeconds }}초 남았습니다. </span>
                <br v-if="seconds > 270" />
                <span v-if="seconds > 270">재발송은 {{ seconds - 270 }}초 후 가능합니다.</span>
              </div>

            </v-tooltip>
            <v-text-field v-else readonly :prepend-inner-icon="mdiLockOutline" v-model="authCodeInput" label="인증번호"
              density="compact" variant="outlined" style="padding:0 !important;" :rules="authNumRule" maxlength="6"
              color="indigo" @change="authRandomCode(authCodeInput)" />

            <v-select variant="outlined" :prepend-inner-icon="mdiAirballoon" label="서비스를 찾아오게된 경로" :items="wayList"
              v-model="clientInfo.fwa_come_way" color="indigo" class="comeWaySelect"></v-select>

            <v-text-field :prepend-inner-icon="mdiAccountQuestion" variant="outlined" label="서비스 담당자 성명" type="text"
              v-model="clientInfo.fwa_manager" maxlength="10" :rules="mngNameRule" color="indigo" />

            <div v-show="clientInfo.wg_client_email_auth === true"
              style="border: 2px solid #d2d2d2; border-radius: 10px; padding-top: 5px; margin-bottom: 10px;">
              <div class="wageAuthForm">
                <v-file-input v-model="fileInputValue" density="compact" label="사용중인 임금대장파일 업로드👆" variant="underlined"
                  id="fileUploadInput" style="padding:0 !important;" />
              </div>
              <WageDirectPush />
            </div>

            <div class="d-flex" style="align-items: center;">
              <playingMent @success="formPlayTrue()" @false="formPlayFalse()"/>
            </div>
            <div class="d-flex" style="align-items: center;">
              <policyMent @success="formPolicyTrue()" @false="formPolicyFalse()"/>
            </div>
            <v-btn :disabled="LastBtnLoading" @click="saveClientInfo" class="clientInfoBtn">상세진단 신청하기</v-btn>
          </div>
        </v-stepper-window-item>
        <v-stepper-window-item value="3">

        </v-stepper-window-item>
      </v-stepper-window>
      <v-stepper-actions v-if="(rule_1(wageInfo) === false && compInfoCheck === false)"></v-stepper-actions>
      <v-stepper-actions v-else-if="(rule_1(wageInfo) === true && compInfoCheck === true)" @click:prev="prev"
        @click:next="next"></v-stepper-actions>
    </template>
  </v-stepper>
  <div class="text-center">
    <v-snackbar v-model="snackbar" :snacktime="snacktime">
      {{ snacktext }}
    </v-snackbar>
  </div>

</template>

<script setup lang="ts">
import axios from 'axios';
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import {
  mdiEmailVariant,
  mdiBank,
  mdiAccountTie,
  mdiLicense,
  mdiCellphone,
  mdiAccountQuestion,
  mdiAirballoon,
  mdiLockOutline
} from '@mdi/js'
import store from '@/store';
import {
  compNameRule,
  compGubunRule,
  nameRule,
  emailRule,
  mngNameRule,
  phoneRule
} from '../../composables/rules';
import WageDirectPush from './WageDirectPush.vue';
import policyMent from './policyMent.vue';
import playingMent from './playingMent.vue';
import router from '@/router';
const wayList = [
  '지인 추천',
  '서비스 담당자 연락',
  '인터넷 검색',
  '함우리 블로그',
  '기타'
];
const authNumRule = [
  (v: string) => !!v || '인증번호를 입력해주세요.',
  (v: string) => v.length >= 6 || '인증번호는 6자 입니다.',
  (v: string) => /^\d+$/.test(v) || '숫자만 입력해야 합니다.',
  (v: string) => v === store.state.randomCode || '인증번호가 일치하지 않습니다.'
];
const snackbar = ref(false);
const snacktext = ref(`메일이 전송되었습니다. 인증번호는 5분간 유효합니다.`);
const snacktime = ref(5000);

const compInfoCheck = ref(false);
const LastBtnLoading = ref(false);

const sidoInfo = ref([] as any);
const sigunguInfo = ref([] as any);
const compNames = ref([] as any[]);
const compInfo = ref();


const wageInfo = reactive({
  wg_name: '',
  wg_sido: '',
  wg_sigungu: '',
  wg_biz_gubun: '',
  wg_nursing_care: 0
});
const clientInfo = reactive({
  wg_client_name: '',
  wg_client_phone: '',
  wg_client_email: '',
  wg_client_email_auth: false,
  wg_policy_agree: false,
  wg_playing_agree: false,
  fwa_code: '',
  fwa_upload_date: '',
  newFileName: '',
  fwa_manager: '',
  fwa_come_way: null,
})
const formPolicyTrue = () => {
  clientInfo.wg_policy_agree = true;
}
const formPolicyFalse = () => {
  clientInfo.wg_policy_agree = false;
}
const formPlayTrue = () => {
  clientInfo.wg_playing_agree = true;
}
const formPlayFalse = () => {
  clientInfo.wg_playing_agree = false;
}

function formatToKRW(number: number) {
  // 10,000 단위로 나누고 정수로 반올림
  const millionWon = Math.round(number / 10000);
  // '만원'을 붙여서 문자열로 반환
  return new Intl.NumberFormat().format(millionWon) + '만원';

}

const loadingChart = ref(false)
const loadingChartTrue = () => {
  loadingChart.value = true;
}
watch(loadingChart, (newValue) => {
  if (newValue === true) {
    setTimeout(() => {
      loadingChart.value = false
    }, 4000)
  }
})

setTimeout(() => {
  loadingChart.value = true
}, 4000)
//환급적용기간
const getPreviousMonth = () => {
  const today = new Date();
  today.setMonth(today.getMonth() - 1);  // 한 달 전으로 설정
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0') as any;  // 월을 2자리 문자열로 변환 (1월은 0부터 시작하므로 +1)
  return `${year}.01 ~ ${year}.${month} (${month - 1 + 1}개월 간)`;
};

//환급액 계산
const calcMonth = () => {
  const today = new Date();
  today.setMonth(today.getMonth() - 1);  // 한 달 전으로 설정
  const month = (today.getMonth() + 1);  // 월을 2자리 문자열로 변환 (1월은 0부터 시작하므로 +1)
  return `${month/12}`;
};
let calcMonthValue = ref(0);
let labor_max = ref(0);
let labor_avg = ref(0);
let comp_max = ref(0);
let comp_avg = ref(0);
let payback_avg =  ref(0);
let payback_max = ref(0);
const heightValue = ref(1);

const chartLabor = ref([] as any)
const chartComp = ref([] as any)
const calcMaxAvg = async () => {
  let laborPer = 0
  let compPer = 0

  if (wageInfo.wg_biz_gubun === '요양시설') {
    laborPer = 38.8;
    compPer = 58.7;
  } else {
    laborPer = 25.5;
    compPer = 48;
  }

  labor_max.value = 620000;
  labor_avg.value = Math.floor(620000 * laborPer / 100);

  comp_max.value = 696000 * wageInfo.wg_nursing_care;
  comp_avg.value = Math.floor(696000 * compPer / 100 * wageInfo.wg_nursing_care);
  calcMonthValue.value = parseFloat(calcMonth());
  payback_avg.value = (comp_avg.value + labor_avg.value) * calcMonthValue.value;
  payback_max.value = (comp_max.value + labor_max.value) * calcMonthValue.value;
  if (comp_max.value > 99999999 && comp_max.value <= 999999999) {
    heightValue.value = 10000000;
  } else if (comp_max.value > 9999999 && comp_max.value <= 99999999) {
    heightValue.value = 1000000;
  } else if (comp_max.value > 999999 && comp_max.value <= 9999999) {
    heightValue.value = 100000;
  } else if (comp_max.value > 99999 && comp_max.value <= 999999) {
    heightValue.value = 10000;
  } else if (comp_max.value > 9999 && comp_max.value <= 99999) {
    heightValue.value = 1000;
  } else if (comp_max.value > 999 && comp_max.value <= 9999) {
    heightValue.value = 100;
  } else if (comp_max.value > 99 && comp_max.value <= 999) {
    heightValue.value = 10;
  }

  chartLabor.value = [
    { label: '최대', value: labor_max.value, price: formatToKRW(labor_max.value) },
    { label: '평균', value: labor_avg.value, price: formatToKRW(labor_avg.value) },
  ]
  let x = (100 * comp_avg.value) / comp_max.value;
  if (wageInfo.wg_nursing_care !== 1) {
    heightValue.value = 1;
    chartComp.value = [
      { label: '최대', value: 100, price: formatToKRW(comp_max.value) },
      { label: '평균', value: x, price: formatToKRW(comp_avg.value) },
    ]
  } else {

    chartComp.value = [
      { label: '최대', value: comp_max.value, price: formatToKRW(comp_max.value) },
      { label: '평균', value: comp_avg.value, price: formatToKRW(comp_avg.value) },
    ]
  }

}


const rule_1 = (info: any) => {
  return Object.values(info).every(value => !!value);
}

const rgNameComputed = computed(() => {

  return wageInfo.wg_name;
});

watch(rgNameComputed, () => {
  if (wageInfo.wg_name) {
    getCompInfo();
  } else {
    compInfoCheck.value = false;
  }
});


const getSido = async () => {
  try {
    const resSido = await axios.get(`/api/getSido/`);
    const SidoData = resSido.data;
    if (SidoData) {
      sidoInfo.value = SidoData
      return SidoData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

const getSigungu = async () => {
  wageInfo.wg_sigungu = '';
  wageInfo.wg_name = '';
  if (wageInfo.wg_sido === '') {
    compNames.value = [];
    return;
  }
  try {
    const resSigungu = await axios.get(`/api/getSigungu/${wageInfo.wg_sido}`);
    const SigunguData = resSigungu.data;
    if (SigunguData) {
      sigunguInfo.value = SigunguData
      compNames.value = [];
      return SigunguData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

const getCompName = async () => {
  wageInfo.wg_name = '';

  if (!wageInfo.wg_biz_gubun || !wageInfo.wg_sido) {
    compNames.value = [];
    return;
  }
  if (!wageInfo.wg_sigungu) {
    if (parseInt(wageInfo.wg_sido) !== 36) {
      // 세종 시군구없음 방지
      return;
    } else {
      wageInfo.wg_sigungu = 'sejong'
    }
  }
  try {
    console.log(wageInfo.wg_sigungu, 'wageInfo.wg_sigungu')
    const response = await axios.get(`/api/getCompName/${wageInfo.wg_sido}/${wageInfo.wg_sigungu}/${wageInfo.wg_biz_gubun}`);
    const responseData = response.data;
    if (responseData) {
      compNames.value = responseData.map((item: { hc_name: any; }) => `${item.hc_name}`);
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

const getCompInfo = async () => {
  compInfoCheck.value = false;
  if (!wageInfo.wg_sido || !wageInfo.wg_sigungu || !wageInfo.wg_biz_gubun || !wageInfo.wg_name) {
    return;
  }
  try {
    const response = await axios.get(`/api/getCompInfo/${wageInfo.wg_sido}/${wageInfo.wg_sigungu}/${wageInfo.wg_biz_gubun}/${wageInfo.wg_name}`);
    const responseData = response.data;
    if (responseData) {
      compInfo.value = responseData;
      wageInfo.wg_nursing_care = responseData.hc_nursing_care || compInfo.value.hc_hyunwon / 2.5 || 1;
      compInfoCheck.value = true;
      await calcMaxAvg();
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loadingChartTrue();
  }
};

///////////insert client info///////////////////////////////////////////////////////////////////////////////

//임금대장 업로드 엑셀 양식 다운로드

const excelFiles = ref('');

const fetchExcelFilesList = async () => {
  try {
    const response = await axios.get('/api/readTemplateExcel');
    excelFiles.value = response.data;
  } catch (error) {
    console.error('client_엑셀 파일 목록을 가져오는 중 오류 발생:', error);
  }
};

/* 파일 업로드 관련 */
function formatDate(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
function formatDateForCode(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
}
const today = formatDate(new Date());
clientInfo.fwa_upload_date = today;
const fileDataList = ref([] as any[]);
const fileInputValue = ref();

const uploadWageExcel = async () => {
  if (!fileInputValue.value) {
    fileInputValue.value = '';
    return;
  }
  let files = fileInputValue.value;
  const formData = new FormData();
  const fileExtension = files.name.split('.').pop(); // 확장자 추출
  const fileNameWithoutExtension = files.name.split('.').slice(0, -1).join('.'); // 확장자 제외 나머지

  // if (fileExtension !== 'xlsx') {
  //   alert('엑셀 파일만 업로드해주세요.')
  // }
  const allowedExtensions = ['xlsx', 'pdf', 'hwp', 'docx', 'pptx', 'jpg', 'jpeg', 'png', 'gif', 'bmp'];
  if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
    alert('허용되지 않는 파일 형식입니다.');
  }
  clientInfo.newFileName = fileNameWithoutExtension + '-' + wageInfo.wg_name + '_' + today + '.' + fileExtension;
  // 중복 파일 이름 검사
  // db생성요망
  await readClientInfo();
  let isDuplicate = fileDataList.value.some(item => item.rf_file_name === clientInfo.newFileName);
  // 중복된 파일 이름이 있다면 숫자를 늘려서 새로운 파일 이름 생성

  if (isDuplicate) {
    let count = 1;
    let baseFileName = clientInfo.newFileName.split('.').slice(0, -1).join('.'); // 확장자를 제거한 파일 이름
    while (isDuplicate) {
      clientInfo.newFileName = `${baseFileName}_${count}.${fileExtension}`;
      isDuplicate = fileDataList.value.some(item => item.rf_file_name === clientInfo.newFileName);
      count++;
    }
  }

  let newFile = new File([files], clientInfo.newFileName); // 새로운 파일 생성
  formData.append('file', newFile);
  try {
    await axios.post('/api/uploadWageExcel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.error('파일 업로드 오류:', error);
  }

};

const readClientInfo = async () => {
  try {
    const response = await axios.get(`/api/readClientInfo/${wageInfo.wg_sido}/${wageInfo.wg_sigungu}/${wageInfo.wg_biz_gubun}/${wageInfo.wg_name}/${today}`);
    const ReadFileDataList = response.data
    fileDataList.value = ReadFileDataList;
    return ReadFileDataList;
  }
  catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  }
};



const saveClientInfo = async () => {

  if (clientInfo.wg_playing_agree === false) {
    alert('이용약관에 동의해주세요.')
    return;
  }
  if (clientInfo.wg_policy_agree === false) {
    alert('개인정보처리방침에 동의해주세요.')
    return;
  }

  const checkAndLog = (rule: any, value: any, ruleName: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === 'string') {
      alert(`${ruleName}`);
      return true;
    } else {
      return false;
    }
  };
  const isName = nameRule.some(rule => checkAndLog(rule, clientInfo.wg_client_name, '올바른 성함을 작성해주세요.'));
  if (isName) {
    return;
  }
  const isPhone = phoneRule.some(rule => checkAndLog(rule, clientInfo.wg_client_phone, '올바른 휴대폰번호를 작성해주세요.'));
  if (isPhone) {
    return;
  }
  const isEmail = emailRule.some(rule => checkAndLog(rule, clientInfo.wg_client_email, '올바른 이메일을 작성해주세요.'));
  if (isEmail) {
    return;
  }
  if (!fileInputValue.value) {
    fileInputValue.value = 0;
    // alert('임금대장 파일을 업로드해주세요.');
    // return;
    // 파일업로드 없어도 가입가능
  }
  if (store.state.randomCode !== authCodeInput.value) {
    alert('이메일 인증번호가 틀립니다.');
    return;
  } else {
    clearInterval(intervalId)
  }
  try {
    LastBtnLoading.value = true;
    await uploadWageExcel();
    // 랜덤수에서 기관고유번호로 변경.
    if (!compInfo.value.hc_number) {
      clientInfo.fwa_code = authCodeInput.value + wageInfo.wg_sido + wageInfo.wg_sigungu + formatDateForCode(new Date());
    } else {
      clientInfo.fwa_code = compInfo.value.hc_number;
    }
    if (!store.state.wageDirectPushData) {
      store.commit('setWageDirectPushData', [])
    }
    const response = await axios.post(`/api/saveClientInfo/`, {
      clientInfo: clientInfo,
      wageInfo: wageInfo,
      wageData: store.state.wageDirectPushData
    });
    const result = response.data
    if (result.success) {
      router.push('/WageComplete');
    } else {
      alert("saveClientInfo실패");
    }
  }
  catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  } finally {
    LastBtnLoading.value = false;
  }
};



const randomCode = ref(generateRandomCode());
function generateRandomCode() {
  return (Math.floor(100000 + Math.random() * 900000)).toString();
}
watch(randomCode, (newValue) => {
  store.commit('setRandomCode', newValue);
});

const seconds = ref(0)
let intervalId = 0;

const startTimer = () => {
  try {

    if (seconds.value !== 0 && intervalId !== 0) {
      clearInterval(intervalId)
    }
    seconds.value = 300
    snackbar.value = true;
    intervalId = setInterval(() => {
      if (seconds.value > 0) {
        seconds.value--
      } else {
        clearInterval(intervalId)
        alert('인증번호 입력제한시간이 초과하였습니다.')
      }
    }, 1000)
  } catch {
    console.log('타이머 에러')
  }
}

const minutes = computed(() => Math.floor(seconds.value / 60))
const remainingSeconds = computed(() => seconds.value % 60)

const authCodeInput = ref('');
const authRandomCode = (code: any) => {
  if (parseInt(code) > 99999) {
    if (store.state.randomCode === authCodeInput.value) {
      clearInterval(intervalId)
      clientInfo.wg_client_email_auth = true;
      seconds.value = 0;
      alert('인증되었습니다.');
    }
  } else {
    return;
  }
}

const loading = ref(false);


const sendWageAuthMail = async () => {
  if (seconds.value >= 270) {
    alert(`인증번호 재발송은 ${seconds.value - 270}초 후 가능합니다.`);
    return;
  }
  const checkAndLog = (rule: any, value: any, ruleName: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === 'string') {
      alert(`${ruleName}`);
      return true;
    } else {
      return false;
    }
  };
  const isEmailValid = emailRule.some(rule => checkAndLog(rule, clientInfo.wg_client_email, '올바른 이메일을 작성해주세요.'));
  if (isEmailValid) return;

  loading.value = true;
  randomCode.value = generateRandomCode();
  try {
    const response = await axios.post(`/api/sendWageAuthMail/${clientInfo.wg_client_email}/${randomCode.value}`);
    const result = response.data;
    if (result.success) {
      startTimer();
      return result; // 가져온 데이터를 반환
    } else {
      alert('메일 전송에 실패하였습니다. 관리자에게 문의바랍니다.')

      console.error('에러가 발생했습니다.', result);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loading.value = false;
  }
};
//////////////////////////////////////////////////////////////////////////////////////////////
onUnmounted(() => {
  clearInterval(intervalId);
  store.commit('setRandomCode', 'success');
});
onMounted(async () => {
  await getSido();
  await fetchExcelFilesList();
  store.commit('setRandomCode', '');
})

</script>

<style scoped>
.chart-container {
  text-align: center;
  width: 190px;
  height: 280px;
  display: flex;
  padding: 8px 8px 4px 6px;
  margin: 10px auto 0 auto;
}

.bar-container {
  margin: auto auto 0px auto;
  display: flex;
  flex-direction: column-reverse;
  /* 변경된 부분 */
  align-items: center;
  position: relative;
}

.bar-container:last-child .bar {
  background: linear-gradient(180deg, #f72047, #ffd200);
}

.bar-label {
  font-size: 12px;
  font-weight: 700;
}

.bar {
  border-top-left-radius: 10px;
  /* 추가된 부분 */
  border-top-right-radius: 10px;
  /* 추가된 부분 */
  width: 40px;
  background: linear-gradient(180deg, #9b9b9b, #e1e1e1);
  margin: 3px 5px;
}

.bar:hover {
  background-color: rgb(164, 164, 164);
  animation: fade-out 1.5s ease-out;
}

@keyframes fade-out {
  0% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.spinner {
  width: 30px !important;
  margin: 0 0 18px 8px;
  display: flex;
  height: 30px !important;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.position-relative {
  position: relative;
}

.overflow-visible {
  overflow: visible;
}

.illustrator-img {
  width: 110px;
}
</style>