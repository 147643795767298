<template>
  <template v-if="changeUpdate">
    <div v-if="loading" class="spinner tac"></div>

    <v-card v-if="!loading" class="blogWriteCard" elevation="0">
      <v-select
        v-model="blogInfo.cate"
        variant="outlined"
        label="카테고리"
        :items="cateValue"
        color="indigo"
        autocomplete="off"
        hide-details="auto"
        flat
        density="compact"
        style="width: auto; min-width: 70px; max-width: 150px"
      ></v-select>
      <v-text-field
        class="inoutSearchInput"
        style="width: 100%; background-color: #fff; margin: 15px 0 15px 0"
        v-model="blogInfo.title"
        color="#0f7545"
        label="제목"
        clearable
        flat
        hide-details="auto"
        variant="outlined"
        density="compact"
      >
      </v-text-field>
      <v-text-field
        class="inoutSearchInput"
        style="width: 100%; background-color: #fff; margin: 0 0 15px 0"
        v-model="blogInfo.sub"
        color="#0f7545"
        label="부제목"
        clearable
        flat
        hide-details="auto"
        variant="outlined"
        density="compact"
      >
      </v-text-field>
      <ckeditor
        :editor="editor"
        v-model="blogInfo.content"
        :config="editorConfig"
        @ready="onEditorReady"
      >
      </ckeditor>

      <v-file-input
        v-model="file"
        density="compact"
        label="첨부파일 업로드"
        hide-details="auto"
        max-width="400"
        variant="underlined"
        style="margin: 15px 0; padding: 0 10px"
      />
      <div v-if="!loading">
      <div style="display: flex; align-items: center; justify-content: left;">
        <v-file-input
          accept="image/*"
          @change="handleFileUpload"
          label="썸네일 업로드"
          variant="underlined"
          density="compact"
          hide-details="auto"
          clearable
          max-width="400"
          style="margin: 15px 0; padding: 0 10px"
          :prepend-icon="mdiImageAlbum"
        />
      </div>
        <v-card
          class="ml-11 gridStyleBlog"
          max-width="400"
          height="280"
          elevation="0"
          v-if="imageMiribogi || imageUrl"
        >
          <div class="blogImgBox" style="background-color: #d1d1d1">
            <v-img
              height="200"
              :src="imageMiribogi || imageUrl"
              alt="썸네일미리보기"
              cover
            ></v-img>
          </div>
        </v-card>
      </div>
      <v-btn
        @click="goToUpdateCancle()"
        class="btnColorDark"
        style="float: right; margin-top: 10px;"
      >
        취소
      </v-btn>
      <v-btn
        @click="updateBlogContent('real')"
        class="btnColorGreen"
        style="float: right; margin-top: 10px; margin-right: 3px"
      >
      <span>저장</span>

      </v-btn>
      <v-btn
      @click="updateBlogContent('no-real')"
      class="btnColorWhite"
      style="float: right; margin-top: 10px; margin-right: 3px"
    >
      임시저장
    </v-btn>
    </v-card>
  </template>
  <template v-else>
    <div>
      <div v-if="loading" class="spinner tac"></div>

      <v-card v-if="!loading" elevation="0" class="blogViewCard">
          <v-card-item>
            <v-card-title class="tac">
              <p class="blogTitle" style="font-size: 32px; font-weight: 600">
                {{ blogInfo.title }}
              </p>
              <span class="blogCateDateInfo">
                {{ blogInfo.cate }}
              </span>
              <div class="gubunLine"></div>
              <span class="blogCateDateInfo">
                {{ blogInfo.wdate }}
              </span>
            </v-card-title>
          </v-card-item>
          <v-card-text class="blogCardText">
            <div v-html="content"></div>
          </v-card-text>
          <div class="blogFileDownDiv">
            <span v-if="blogInfo.fileName"> 첨부파일: </span>
            <span @click="downloadExcelFile()" style="cursor: pointer">
              <v-icon :style="{ color: iconColor(blogInfo.fileName) }">
                {{ getFileIcon(blogInfo.fileName) }}
              </v-icon>
              <span class="blogFileDownSpan">
                {{ fileNameSplit }}
              </span>
            </span>
          </div>
      </v-card>
    </div>

    <div v-if="role >= 8 && !loading" class="tac mb-6">
      <v-btn @click="goToUpdate()" class="mr-4"> 수정 </v-btn>
      <v-btn @click="deleteBlogContent()" class="mr-4"> 삭제 </v-btn>
      <span class="mr-4">조회: {{ blogInfo.count + 1 }}</span>
      <span>(관리자에게만 노출)</span>
    </div>
    <div class="blogViewMoveBtn tac" v-if="!loading">
      <!-- <v-btn
        v-if="blogInfo.idx === blogInfo.minIdx"
        class="blogNonMoveBtn"
        style="float: left"
        >이전글</v-btn
      >
      <v-btn
        v-else
        @click="goToDown(blogInfo.idx)"
        class="blogMoveBtn"
        style="float: left"
        >이전글</v-btn
      > -->
      <v-btn @click="blogPage()" class="bigBtn2">목록으로</v-btn>
      <!-- <v-btn
        v-if="blogInfo.idx === blogInfo.maxIdx"
        @click="goToUp(blogInfo.idx)"
        class="blogNonMoveBtn"
        style="float: right"
        >다음글</v-btn
      >
      <v-btn v-else @click="goToUp(blogInfo.idx)" class="blogMoveBtn" style="float: right"
        >다음글</v-btn
      > -->
    </div>
  </template>
</template>

<script setup lang="ts">
import CKEditor from "@/plugins/ckeditor/build/ckeditor.js";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import { onMounted, ref } from "vue";
import {
  mdiFileExcel,
  mdiFileDocument,
  mdiFilePdfBox,
  mdiFileImage,
  mdiFile,
  mdiArchive,
  mdiImageAlbum
} from "@mdi/js";
const editor = CKEditor;
const role = store.getters.role || null;
const id = store.getters.id;

const loading = ref(false);
const changeUpdate = ref(false);
const blogInfo = ref([] as any);
const fileNameSplit = ref('');
const fileName = ref('');
const file = ref(null) as any;
const duplicateFileName = ref([] as any[]);

const blogCode = ref("");
const content = ref("");
const imageMiribogi = ref(null) as any;
const imageFile = ref(null) as any;
const ext = ref(null) as any;
const imageType = ref(null) as any;
const imageUrl = ref(null) as any;
const imgName = ref(null) as any;

const cateValue = ["운영관리", "노무관리", "재무회계", "위탁급식", "기타"];

const blogPage = async () => {
  router.push("/BlogPage");
};
const goToUpdate = async () => {
  changeUpdate.value = true;
};
const goToUpdateCancle = async () => {
  changeUpdate.value = false;
};

// const goToDown = async (code: any) => {
//   if (code === "0001") {
//     return;
//   }
//   let number = parseInt(code, 10);
//   number -= 1;
//   // 감소된 숫자를 문자열로 변환하고, 원래의 형식으로 패딩
//   const downCode = number.toString().padStart(code.length, "0");
//   store.commit("setBlogCode", downCode);
//   blogCode.value = store.state.blogCode;
//   await readBlogSelect();
// };

// const goToUp = async (code: any) => {
//   if (code === blogInfo.value.maxIdx) {
//     return;
//   }
//   let number = parseInt(code, 10);
//   number += 1;
//   // 감소된 숫자를 문자열로 변환하고, 원래의 형식으로 패딩
//   const downCode = number.toString().padStart(code.length, "0");
//   store.commit("setBlogCode", downCode);
//   blogCode.value = store.state.blogCode;
//   await readBlogSelect();
// };

const removePrefix = (fileName: string) => {
  // 정규식 패턴: 'hwrCount' 뒤에 어떤 문자열이 오더라도 언더스코어를 포함하여 이를 삭제
  const regexPattern = /^.*hC25Jes_/;

  // 정규식을 사용하여 접두사 제거
  if (regexPattern.test(fileName)) {
    return fileName.replace(regexPattern, "");
  } else {
    return fileName;
  }
};

const downloadExcelFile = () => {
  if (!blogInfo.value.fileName) {
    return;
  }
  window.location.href = `/api/downloadBlogFile/${blogInfo.value.fileName}`;
};
const iconColor = (fileName: any) => {
  if (!fileName) {
    return "red";
  }
  const extension = fileName.split(".").pop(); // 확장자 추출
  switch (extension) {
    case "xls":
    case "xlsx":
      return "darkgreen";
    case "pdf":
      return "red";
    case "hwp":
    case "hwpx":
    case "hwt":
      return "orange";
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
    case "tiff":
      return "blue";
    case "zip": // zip 파일 추가
      return "yellow";
    default:
      return "black";
  }
};
const getFileIcon = (fileName: any) => {
  if (!fileName) {
    return "";
  }
  const extension = fileName.split(".").pop(); // 확장자 추출
  switch (extension) {
    case "xls":
    case "xlsx":
      return mdiFileExcel;
    case "pdf":
      return mdiFilePdfBox;
    case "hwp":
    case "hwpx":
    case "hwt":
      return mdiFileDocument;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
    case "tiff":
      return mdiFileImage;
    case "zip":
      return mdiArchive;
    default:
      return mdiFile;
  }
};

const readBlogSelect = async () => {
  try {
    loading.value = true;
    const response = await axios.get(`/api/readBlogSelect/${blogCode.value}/${role}`);
    const responseData = response.data;
    if (responseData) {
      blogInfo.value = responseData;
      if(blogInfo.value.content){
        content.value = blogInfo.value.content;
      }
      if(blogInfo.value.fileName){
        fileNameSplit.value = removePrefix(blogInfo.value.fileName);
        fileName.value = blogInfo.value.fileName;
      }

      return responseData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    loading.value = false;
  }
};

const editorConfig = {
  language: "ko",
};

// CKEditor의 ready 이벤트를 처리할 함수
const onEditorReady = (editorInstance: any) => {
  editorInstance.ui
    .getEditableElement()
    .parentElement.insertBefore(
      editorInstance.ui.view.toolbar.element,
      editorInstance.ui.getEditableElement()
    );
};

const readBlogDupFileName = async (fileName: string) => {
  try {
    const response = await axios.get(`/api/readBlogDupFileName/${fileName}`);
    const result = response.data;
    duplicateFileName.value = result;
    return result;
  } catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  }
};

const saveBlogFile = async () => {
  if (!file.value || file.value === '' || file.value === null) {
    file.value = null;
    return;
  }
  let files = file.value;
  const formData = new FormData();
  const fileExtension = files.name.split(".").pop(); // 확장자 추출
  const fileNameWithoutExtension = files.name.split(".").slice(0, -1).join("."); // 확장자 제외 나머지

  // if (fileExtension !== 'xlsx') {
  //   alert('엑셀 파일만 업로드해주세요.')
  // }
  const allowedExtensions = [
    "xlsx",
    "pdf",
    "hwp",
    "docx",
    "pptx",
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "zip",
  ];
  if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
    alert("허용되지 않는 파일 형식입니다.");
  }
  fileName.value = fileNameWithoutExtension + "." + fileExtension;
console.log(fileName.value,'fileName.value')
  await readBlogDupFileName(fileName.value);
  let isDuplicate = duplicateFileName.value.some(
    (item) => item.fileName === fileName.value
  );
  // 중복된 파일 이름이 있다면 숫자를 늘려서 새로운 파일 이름 생성

  if (isDuplicate) {
    let count = 1;
    let baseFileName = fileName.value.split(".").slice(0, -1).join("."); // 확장자를 제거한 파일 이름
    while (isDuplicate) {
      fileName.value = `${count}hC25Jes_${baseFileName}.${fileExtension}`;
      isDuplicate = duplicateFileName.value.some(
        (item) => item.fileName === fileName.value
      );
      count++;
    }
  }

  let newFile = new File([files], fileName.value); // 새로운 파일 생성
  formData.append("file", newFile);
  try {
    blogInfo.value.fileName = fileName.value;
    
    await axios.post(`/api/saveBlogFile/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.error("파일 업로드 오류:", error);
  }
};
// 저장 버튼 클릭 시 호출되는 메서드
const updateBlogContent = async (suc : any) => {
  const isConfirmed = window.confirm(`해당 게시글을 수정하시겠습니까?`);
  if (isConfirmed) {
    try {
      loading.value = true;
      await saveBlogFile();
      await sendThumbnail();
      
      if(suc === 'real'){
        blogInfo.value.status = 2;//저장
      }else{
        blogInfo.value.status = 1;//임시저장
      }

      const response = await axios.post(`/api/updateBlogContent/${id}`, {
      blogInfo: blogInfo.value,
      });
      const result = response.data;
      if (result.success) {
        await readBlogSelect();
        await goToUpdateCancle();
      } else {
        alert("수정에 실패하였습니다. 관리자에게 문의해주세요.");
        console.log("데이터 저장 실패");
      }
    } catch (error) {
      console.error(error);
      throw error; // 에러 다시 던지기
    } finally {
      loading.value = false;
    }
  } else {
    return;
  }
};
// 저장 버튼 클릭 시 호출되는 메서드
const deleteBlogContent = async () => {
  const isConfirmed = window.confirm(`해당 블로그 글을 삭제하시겠습니까?`);
  if (isConfirmed) {
    try {
      loading.value = true;
      const response = await axios.post(`/api/deleteBlogContent/${store.state.blogCode}`);
      const result = response.data;
      if (result.success) {
        store.commit("setBlogCode", "");
        await blogPage();
      } else {
        alert("삭제에 실패하였습니다. 관리자에게 문의해주세요.");
        console.log("데이터 저장 실패");
      }
    } catch (error) {
      console.error(error);
      throw error; // 에러 다시 던지기
    } finally {
      loading.value = false;
    }
  } else {
    return;
  }
};

const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    const file = target.files[0];
    ext.value = file.name.split(".").pop();
    const reader = new FileReader();
    reader.onload = () => {
      imageMiribogi.value = reader.result;
    };
    reader.readAsDataURL(file);
    imageFile.value = file;
  }
};
const fetchImage = async () => {
  imageUrl.value = await getThumbnail();
};
const getThumbnail = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`/api/getThumbnail/${blogInfo.value.idx}`, {
      responseType: "blob",
    });
    if (response.data) {
      //이미지타입 추출
      imageType.value = response.data.type.split("/")[1];
      if (imageType.value === "jpeg") {
        imageType.value = "jpg";
      }
      const imageBlob = response.data;
      const imageObjectURL = URL.createObjectURL(imageBlob);
      return imageObjectURL; // 가져온 이미지 URL을 반환
    }
  } catch (error) {
    const imageObjectURL = "nothing";
    return imageObjectURL;
  } finally {
    loading.value = false;
  }
};

const sendThumbnail = async () => {
  if (!imageFile.value) {
    return;
  }
  const allowedTypes = ["image/jpeg", "image/png"];
  if (!allowedTypes.includes(imageFile.value.type)) {
    alert("JPG 또는 PNG 파일만 업로드 가능합니다.");
    loading.value = false;
    return;
  }

  try {
    if (imageUrl.value !== "nothing") {
      await deleteThumbnail();
    }

    const formData = new FormData();
    formData.append("image", imageFile.value);
    const whatKindOfSend = blogInfo.value.idx;
    const response = await axios.post(
      `/api/sendThumbnail/${imgName.value}/${ext.value}/${whatKindOfSend}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response && response.data.success) {
      blogInfo.value.thumbnail = `${blogInfo.value.idx}.${ext.value}`
    } else {
      console.error("이미지 업로드 실패", response.data);
    }
  } catch (error) {
    console.error("이미지 업로드 중 에러 발생", error);
  }
};
const deleteThumbnail = async () => {
  try {
    const response = await axios.post(`/api/deleteThumbnail/${blogInfo.value.idx}`);
    if (!response) {
      alert("기존 파일 삭제 에러 발생.");
      return;
    }
  } catch (error) {
    console.error("이미지 업로드 중 에러 발생", error);
  }
};
onMounted(async () => {
  blogCode.value = store.state.blogCode;
  await fetchImage();
  await readBlogSelect();
});
</script>
<style scoped>
.spinner {
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-left: 30px solid #8b2197;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
  margin: 60px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
