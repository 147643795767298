<template>
  <v-card class="normalMngCard" style="width: 100%;">
    <v-data-table no-data-text="데이터가 존재하지 않습니다." fixed-header density="compact" scrollable :headers="headersArr" :items="filteredItems"
      :search="search" item-value="name" class="fontNotoSans400" style="
        height: calc(100vh - 217px); 

        overflow-y: hidden; 
        position: relative;
      " :items-per-page="50" :items-per-page-options="[
        { value: 50, title: '50' },
        { value: 100, title: '100' },
        { value: 200, title: '200' }
        // { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
      ]" v-if="inquiryArr.length !== 0">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="fontNotoSans700">🧑‍💼 상담문의 신청목록</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-text-field class="inoutSearchInput" style="
          background-color: #fff;
          max-width:200px; 
          width:auto; 
          min-width:70px;
          margin: 0 20px 0 0;
        " v-model="search" color="#0f7545" label="기관검색" single-line clearable :append-inner-icon="mdiMagnify" flat
            hide-details="auto" variant="outlined" density="compact">
          </v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item, index }">
        <tr>
          <td class="tac">{{ index + 1 }}</td>

          <td>
            <v-chip variant="text" label class="ma-2" color="black">
              <v-chip class="tar" color="orange " label v-if="item.hi_comp_gubun === '주간보호센터'">
                주간
              </v-chip>
              <v-chip class="tar" color="blue" label v-else-if="item.hi_comp_gubun === '요양시설'">
                요양
              </v-chip>
              <v-chip class="tar" color="blue" label v-else>
              </v-chip>
              <span style="margin-left: 5px;">{{ item.hi_comp_name || '' }}</span>
            </v-chip>
          </td>

          <td class="tac">
            {{ item.hi_service_wage }} {{ item.hi_service_fna }}
          </td>

          <td class="tac">
            {{ item.hi_phone }}<br>
            {{ item.hi_email }}
          </td>

          <td class="tac" style="max-width: 400px;">
            {{ item.hi_memo }}
          </td>

          <td class="tac">
            <template v-if="!item.editing">
              {{ item.hi_mng_id }}
            </template>
            <template v-else>
              <select v-model="item.hi_mng_id" class="mngSelect"
              style="cursor: pointer; outline: auto; padding: 3px 20px 3px 20px;">
                <option v-if="!item.hi_mng_id" value="" class="tal">--- 선택 ---</option>
                <option class="tal" v-for="mng in mngNameList" :key="mng" :value="mng">
                  {{ mng }}
                </option>
              </select>
            </template>
          </td>

          <td class="tac">
            {{ item.matchingLog }}
          </td>

          <td class="tac">
            {{ item.wdate }}
          </td>

          <td class="tac">
              <v-chip color="black" v-if="item.hi_status === 0">
                {{ item.status_name }}
              </v-chip>
              <v-chip color="red-accent-4" v-else-if="item.hi_status === 1">
                {{ item.status_name }}
              </v-chip>
              <v-chip color="blue-accent-4" v-else-if="item.hi_status === 2">
                {{ item.status_name }}
              </v-chip>
              <v-chip color="red-darken-4" v-else-if="item.hi_status === 3">
                {{ item.status_name }}
              </v-chip>
          </td>

          <td class="tac">
            <template v-if="!item.editing">
              <v-btn @click="toggleEditMode(item)" class="btnColorWhite" style="height: 28px;">수정</v-btn>
            </template>
            <template v-else>
              <v-btn @click="grantInquiry(item)" class="btnColorBlue" style="height: 28px;">확정</v-btn>
              <v-btn @click="cancelEdit(item)" class="btnColorPink" style="height: 28px; margin-left: 5px;">취소</v-btn>
            </template>
          </td>

        </tr>
      </template>

    </v-data-table>
  </v-card>
</template>
<script setup lang="ts">
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import {
  mdiMagnify,
} from '@mdi/js'
const inquiryArr = ref([] as any[]);
const search = ref('');
const hiMngId = ref('');
const mngNameList = ref([] as any[]);
const headersArr = ref([
  { title: 'No.', key: 'index', align: 'center', sortable: false },
  { title: '기관 명', key: 'hi_comp_name', align: 'center', sortable: true },
  { title: '희망서비스', key: 'hi_service_wage', align: 'center', sortable: true },
  { title: '연락처', key: 'hi_email', align: 'center', sortable: true },
  { title: '문의사항', key: 'hi_memo', align: 'center', sortable: true },
  { title: '담당자', key: 'hi_mng_id', align: 'center', sortable: true },
  { title: '배정일', key: 'hi_mng_matching_log', align: 'center', sortable: true },
  { title: '신청일', key: 'hi_wdate', align: 'center', sortable: true },
  { title: '상태', key: 'hi_status', align: 'center', sortable: true },
  { title: '수정', key: '', align: 'center', sortable: false }

] as any[]);
const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return inquiryArr.value;
  }
  return inquiryArr.value.filter(item =>
    (item.hi_comp_name && item.hi_comp_name.toLowerCase().includes(search.value.toLowerCase()))
  );
});
const toggleEditMode = (item: any) => {
  hiMngId.value = item.hi_mng_id;
  item.editing = !item.editing;
};
// 수정 취소하는 메서드
const cancelEdit = (item: any) => {
  item.hi_mng_id = hiMngId.value;
  item.editing = false; // 수정 모드 종료
};
const grantInquiry = async (item:any) => {

  if(item.hi_status !== 0 && item.hi_mng_id === ''){
    alert('담당자를 선택해주세요.');
    return;
  } 

  const isConfirmed = window.confirm(`정말 수정하시겠습니까?`);
  if (isConfirmed) {
    try{
      const response = await axios.post(`/api/grantInquiry`, {form: item})
      const responseData = response.data;
      if (responseData.success) {
          await getInquiry();
        } else {
          console.log('저장에 실패하였습니다.')
      }
    }catch{
      console.error('에러가 발생했습니다.');
    }
  }
}
const getMngName = async () => {
  try {
    const response = await axios.get(`/api/getMngName`);
    const responseData = response.data;
    if (responseData) {

      const fwaManagers = responseData.map((item: { hm_name: any; }) => item.hm_name);
      mngNameList.value = fwaManagers;

      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
const getInquiry = async () => {
  try {
    const result = await axios.get(`/api/getInquiry/`);
    const response = result.data;
    if (response) {
      inquiryArr.value = response
      return response;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
onMounted(async () => {
  await getInquiry();
  await getMngName();
})
</script>