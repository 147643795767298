<template>
  <div class="fade-in-up">
    <h2 class="registMainTitle">신청할 서비스를 선택하세요</h2>

    <div class="wageClientInfoForm">
      <p
        class="tac registSubTitle"
        style="font-size: 14px; opacity: 0.8; margin: 3px 0 45px 0"
      >
        수급자를 기준으로 수임료가 책정됩니다.
      </p>
      <div class="wageAuthForm">
        <v-text-field
          :prepend-inner-icon="mdiAccount"
          variant="outlined"
          label="수급자 정원"
          type="number"
          v-model="form.sisul_jungwon"
          color="dark"
          autocomplete="off"
          style="max-width: 48%; margin: auto"
          @focus="clearFormat(form.sisul_jungwon)"
        />
        <v-text-field
          :prepend-inner-icon="mdiAccount"
          variant="outlined"
          label="수급자 현원"
          type="number"
          v-model="form.sisul_hyunwon"
          color="dark"
          autocomplete="off"
          style="max-width: 48%; margin: auto"
          @focus="clearFormat(form.sisul_hyunwon)"
        />
      </div>
      <div style="display: flex; align-items: center">
        <v-checkbox
          color="primary"
          width="34"
          value="임금"
          v-model="form.serviceWage"
          hide-details
          style="display: inline-block"
        ></v-checkbox>
        <span style="font-size: 15px; color: #001381; font-weight: 700">
          임금관리서비스
        </span>
        <span
          v-if="form.serviceWage"
          style="
            font-size: 15px;
            color: #333;
            font-weight: 700;
            margin-left: auto;
            margin-right: 10px;
          "
        >
          {{ wagePrice }}
        </span>
      </div>
      <div v-if="form.serviceWage" class="hideTr_regist">
        <div style="display: flex; align-items: center">
          <v-radio-group inline v-model="defaultContractPeriod">
            <v-radio label="1년 계약" value="one"></v-radio>
            <v-radio label="단기계약" value="two"></v-radio>
          </v-radio-group>
        </div>
        <v-text-field
          :prepend-inner-icon="mdiCalendarRange"
          variant="outlined"
          label="임금관리서비스 개시 연월"
          type="month"
          v-model="form.sisul_wage_in"
          color="dark"
          autocomplete="off"
        />

          <v-text-field
            :prepend-inner-icon="mdiAccountTie"
            variant="outlined"
            label="임금관리 담당자 명"
            type="text"
            v-model="form.sisul_wage_name"
            maxlength="10"
            :rules="nameRule"
            color="dark"
            autocomplete="off"
          />
        <v-text-field
          :prepend-inner-icon="mdiCellphone"
          variant="outlined"
          label="임금관리 담당자 연락처"
          type="text"
          v-model="form.sisul_wage_phone"
          maxlength="11"
          :rules="phoneNumberRule"
          color="dark"
          autocomplete="off"
        />
      </div>

      <div style="display: flex; align-items: center">
        <v-checkbox
          color="green"
          width="34"
          value="회계"
          v-model="form.serviceFna"
          hide-details
          style="display: inline-block"
        ></v-checkbox>
        <span style="font-size: 15px; color: #124900; font-weight: 700"
          >재무회계서비스</span
        >
        <span
          v-if="form.serviceFna"
          style="
            font-size: 15px;
            color: #333;
            font-weight: 700;
            margin-left: auto;
            margin-right: 10px;
          "
        >
        {{ fnaPrice }}
        </span>
      </div>
      <div v-if="form.serviceFna" class="hideTr_regist">
        <v-text-field
          :prepend-inner-icon="mdiCalendarRange"
          variant="outlined"
          label="재무회계서비스 개시 연월"
          type="month"
          v-model="form.sisul_fna_in"
          color="dark"
          autocomplete="off"
        />
          <v-text-field
            :prepend-inner-icon="mdiAccountTie"
            variant="outlined"
            label="재무회계 담당자 명"
            type="text"
            v-model="form.sisul_fna_name"
            maxlength="10"
            :rules="nameRule"
            color="dark"
            autocomplete="off"
          />
        <v-text-field
          :prepend-inner-icon="mdiCellphone"
          variant="outlined"
          label="재무회계 담당자 연락처"
          type="text"
          v-model="form.sisul_fna_phone"
          maxlength="11"
          :rules="phoneNumberRule"
          color="dark"
          autocomplete="off"
        />
      </div>

      <v-btn
        :class="{
          clientInfoBtn: status1 && status2,
          'not-agree-disabled': !(status1 && status2),
        }"
        @click="getForm()"
        >확인</v-btn
      >
    </div>
  <p class="registPrevBtn" @click="prev()">이전단계로 돌아가기</p>

  </div>
</template>
<script setup lang="ts">
import { mdiAccount, mdiCalendarRange, mdiAccountTie, mdiCellphone } from "@mdi/js";
import { reactive, ref, watch } from "vue";
import { phoneNumberRule, nameRule } from "../../composables/rules";
import store from "@/store";
const status1 = ref(false);
const status2 = ref(false);
const fnaPrice = ref(null) as any;
const wagePrice = ref('근로자 1인당 월 12,000 원 (VAT 별도)') as any;
const defaultContractPeriod = ref('one') as any;
const form = reactive({
  serviceWage: false,
  serviceFna: false,
  sisul_wage_in: store.state.RegistForm.sisul_wage_in,
  sisul_fna_in: store.state.RegistForm.sisul_fna_in,
  sisul_jungwon: "",
  sisul_hyunwon: "",
  sisul_wage_name: "",
  sisul_wage_phone: "",
  sisul_fna_name: "",
  sisul_fna_phone: "",
});
// eslint-disable-next-line no-undef
const emit = defineEmits(["success", "prev"]);
const prev = () => {
  emit('prev');
}
watch(form, async () => {
  const checkAndLog = (rule: any, value: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === "string") {
      return true;
    } else {
      return false;
    }
  };
  const nameValid1 = nameRule.some((rule) => checkAndLog(rule, form.sisul_wage_name));
  const nameValid2 = nameRule.some((rule) => checkAndLog(rule, form.sisul_fna_name));
  const phoneValid1 = phoneNumberRule.some((rule) =>
    checkAndLog(rule, form.sisul_wage_phone)
  );
  const phoneValid2 = phoneNumberRule.some((rule) =>
    checkAndLog(rule, form.sisul_fna_phone)
  );
  if (
    form.serviceWage &&
    parseInt(form.sisul_jungwon) > 0 &&
    parseInt(form.sisul_hyunwon) > 0
  ) {
    if (form.sisul_wage_in && !nameValid1 && !phoneValid1) {
      status1.value = true;
    } else {
      status1.value = false;
    }
  } else {
    if (parseInt(form.sisul_jungwon) > 0 && parseInt(form.sisul_hyunwon) > 0) {
      form.sisul_wage_in = "";
      form.sisul_wage_name = "";
      form.sisul_wage_phone = "";
      status1.value = true;
    } else {
      status1.value = false;
    }
  }

  if (
    form.serviceFna &&
    parseInt(form.sisul_jungwon) > 0 &&
    parseInt(form.sisul_hyunwon) > 0
  ) {
    if (form.sisul_fna_in && !nameValid2 && !phoneValid2) {
      status2.value = true;
    } else {
      status2.value = false;
    }
  } else {
    if (parseInt(form.sisul_jungwon) > 0 && parseInt(form.sisul_hyunwon) > 0) {
      form.sisul_fna_in = "";
      form.sisul_fna_name = "";
      form.sisul_fna_phone = "";
      status2.value = true;
    } else {
      status2.value = false;
    }
  }

  if (!form.serviceWage && !form.serviceFna) {
    status1.value = false;
    status2.value = false;
  }
});
const getForm = async () => {
  if (!status1.value || !status2.value) {
    return;
  } else {
    let registForm = store.state.RegistForm;
    registForm.sisul_wage_in = form.sisul_wage_in;
    registForm.sisul_wage_name = form.sisul_wage_name;
    registForm.sisul_wage_phone = form.sisul_wage_phone;
    registForm.sisul_fna_in = form.sisul_fna_in;
    registForm.sisul_fna_name = form.sisul_fna_name;
    registForm.sisul_fna_phone = form.sisul_fna_phone;
    registForm.sisul_jungwon = form.sisul_jungwon;
    registForm.sisul_hyunwon = form.sisul_hyunwon;
    store.commit("setRegistForm", registForm);
    emit("success");
  }
};
function clearFormat(item: any) {
  // 값이 0이면 빈 값으로 설정하여 placeholder를 보이도록 합니다.
  if (item === "0" || item === 0) {
    item = "";
  }
}

function ac(number: any) {
  // 입력이 숫자인지 확인 후 숫자로 강제 변환
  const num = Number(number);
  if (isNaN(num)) return number; // 숫자가 아닌 경우 그대로 반환
  if (num === 0) return " "; // 0이 입력된 경우 "-" 반환
  // 숫자인 경우 컴마를 추가한 문자열 반환
  return num.toLocaleString();
}
function calculateValue(m: number, p4: number) {
  return m < 13 ? 200000 : 200000 + (m - 12) * p4;
}
function calculateStaff(recipients: any) {
    const staff = {
        시설장: 1, // 항상 1명
        사무국장: recipients >= 50 ? 1 : 0,
        사회복지사: recipients < 101 ? 1 : Math.ceil(recipients / 100),
        간호조무사: recipients <= 30 ? 1 : Math.ceil(recipients / 25),
        물리치료사: recipients <= 30 ? 0 : Math.ceil(recipients / 100),
        요양보호사: Math.ceil(recipients / 2.3),
        사무원: recipients >= 50 ? 1 : 0,
        영양사: recipients >= 50 ? 1 : 0,
        조리원: recipients <= 30 ? 1 : Math.floor(recipients / 25),
        위생원: recipients <= 30 ? 0 : Math.ceil(recipients / 100),
        관리인: recipients >= 50 ? 1 : 0
    };

    // 모든 값의 합 계산
    const totalStaff = Object.values(staff).reduce((sum, count) => sum + count, 0);

    return { staff, totalStaff };
}

watch(
  () => form.sisul_hyunwon,
  (newValue) => {
    if (newValue) {
      // 사용 예시
      const input = parseInt(newValue); // M5에 해당하는 값
      const P4 = 4000; // P$4에 해당하는 값

      const resultStaff = calculateStaff(input);
      
      const result = calculateValue(resultStaff.totalStaff, P4);
      fnaPrice.value = `월 ${ac(result)} 원 (VAT 별도)`;
    } else {
      fnaPrice.value = "";
    }
  }
);
watch(
  () => defaultContractPeriod.value,
  (newValue) => {
    if (newValue === 'one') {
      wagePrice.value = `근로자 1인당 월 12,000 원 (VAT 별도)`;
    } else {
      wagePrice.value = `근로자 1인당 월 15,000 원 (VAT 별도)`;
    }
  }
);

// yyyy-mm 형식으로 현재 날짜 반환 함수
function getCurrentYearMonth() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
}
watch(
  () => [form.sisul_fna_in, form.sisul_wage_in], 
  ([newFnaValue, newWageValue]) => {
    if (newFnaValue === true) {
      form.sisul_fna_in = getCurrentYearMonth();
    }
    if (newWageValue === true) {
      form.sisul_wage_in = getCurrentYearMonth();
    }
  }
);
</script>
<style scoped>
.hideTr_regist {
  margin-top: 10px;
}
</style>
