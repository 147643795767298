<template>
  <v-row>
    <v-col cors="6">
      <v-sheet>
        <MngClientAdmin v-if="role >= 8"/>
        <MngClient v-if="role === 7"/>
      </v-sheet>
    </v-col>
  </v-row>

</template>
<script setup lang="ts">
import MngClientAdmin from '@/components/mng/MngClientAdmin.vue'
import MngClient from '@/components/mng/MngClient.vue'
import store from '@/store';
const role = store.getters.role;

</script>