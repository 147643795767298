<template>
  <v-sheet>
    <v-card class="tac" style="width: 600px; margin: 50px auto; padding: 20px;">
      <v-card-text>
        <h2>
        임금대장 상세진단 신청이 완료되었습니다.</h2>
      </v-card-text>
      <v-card-text>
        <span style="font-size: 16px;">등록하신 이메일로 상세진단의 결과를 발송해 드립니다. (2~3일)</span>
      </v-card-text>
      <v-btn @click="goToIndex" class="bigBtn">
        메인화면으로 가기
      </v-btn>
    </v-card>
  </v-sheet>
</template>

<script setup>
import router from '@/router';
const goToIndex = () => {
  router.push('/indexPage');
}
</script>