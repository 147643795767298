<template>
  <v-sheet class="mainMaxWidth meal-menu main-menu-sheet">
    <div class="image-container">
        <img src="@/assets/pictures/main/메뉴_급식.png" class="main-menu-img" cover />
        <div class="main-menu-overlay">
          <p class="main-menu-text">급식관리</p>
          <span class="main-menu-sub-text">Meal management</span>
          <span></span>
        </div>
      </div>
    <div class="main-menu-box">
      
      <div class="main-menu-content">
        <p class="main-menu-top-ment">정성과 믿음으로 만들어가는 위탁급식</p>
        <p class="main-menu-top-sub-ment">
          식자재/위생/인력/시설/안전관리 등 전문가에 의한 철저하고 종합적인 시스템으로 운영하고 있습니다.
        <br/> 성별, 연령대, 근무형태 및 기호도에 맞춰 전문 영양사 배치 후 메뉴를 구성합니다.
        <br/> 입소 어르신들의 행복하고 즐거운 식사시간을 제공하기 위해 건강하고 맛있는 식단을 제공합니다.</p>
        <div class="meal-menu-img-content">
          <v-row style="height:auto;">
            <v-col cols="3" class="meal-col-img">
              <img src="@/assets/pictures/main/메뉴_급식_영양사.jpg" class="meal_menu_img" cover />
            </v-col>
            <v-col cols="3" class="meal-col-ment">
              <p>• 전문 영양사, 조리전문인력 지원
              <br/>• 체계적인 급식운영시스템 구축
              <br/>• 다양하고 균형잡힌 식단 제공
              <br/>• 정성이 담긴 요양기관 맞춤 메뉴 선정
              </p>
            </v-col>
            <v-col cols="3" class="meal-col-img">
              <img src="@/assets/pictures/main/메뉴_급식_안전관리.png" class="meal_menu_img" cover />
            </v-col>
            <v-col cols="3" class="meal-col-ment">
              <p>• 위생팀의 철저한 위생 및 안전관리
              <br/>• 공신력 있는 위생안전기준 준수
              <br/>• 조리인력 지원 시 위생교육 실시
              <br/>• 안심하고 믿을 수 있는 음식 제공
              <br/>• 식품위생안전점검 컨설팅 지원
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="meal-col-img">
              <img src="@/assets/pictures/main/메뉴_급식_식자재.jpg" class="meal_menu_img" cover />
            </v-col>
            <v-col cols="3" class="meal-col-ment">
              <p>• 식자재전문 구매업체와의 협업
              <br/>• 당일경매, 새벽배송
              <br/>• 배송된 식자재에 대한 철저한 검수
              </p>
            </v-col>
            <v-col cols="3" class="meal-col-img">
              <img src="@/assets/pictures/main/메뉴_급식_경비절감.jpg" class="meal_menu_img" cover />
            </v-col>
            <v-col cols="3" class="meal-col-ment">
              <p>• 계획적인 금전 관리로 운영 경비 절감
              </p>
            </v-col>
          </v-row>

        </div>
        <p class="main-menu-top-ment">체계적인 위탁급식 업무절차</p>
        <p class="main-menu-top-sub-ment">함우리는 고객사의 니즈를 분석하고, 성별이나 연령, 기호도와 근무환경을 고려하여 메뉴를 구성하고 급식의 만족도를 높이기 위해 최선을 다합니다.</p>
        <div class="meal-menu-count-content">
          <v-table class="meal-menu-count-content-tb">
            <tbody>
              <tr>
                <td style="background-color: #FFF2E0;" class="meal-menu-count-content-title"><v-chip color="dark" style="padding:10px 11px 12px 10px;">1</v-chip>식단</td>
                <td rowspan="2" style="font-size: 28px;">➜</td>
                <td style="background-color: #FFE5CC;" class="meal-menu-count-content-title"><v-chip color="dark" style="padding:10px 11px 12px 10px;">2</v-chip>발주</td>
                <td rowspan="2" style="font-size: 28px;">➜</td>
                <td style="background-color: #FFD1A3;" class="meal-menu-count-content-title"><v-chip color="dark" style="padding:10px 11px 12px 10px;">3</v-chip>구매</td>
                <td rowspan="2" style="font-size: 28px;">➜</td>
                <td style="background-color: #FFBD7A;" class="meal-menu-count-content-title"><v-chip color="dark" style="padding:10px 11px 12px 10px;">4</v-chip>배송</td>
              </tr>
              <tr class="meal-menu-count-content-ment">
                <td>영양사가 입소 어르신의 상태에 맞춰 식단을 작성하고, 급식운영을 계획합니다.</td>
                <td>작성된 식단과 식수인원에 맞추어 발주합니다.</td>
                <td>발주서를 확인하고 당일 경매를 통해 신선한 식자재를 구매합니다.</td>
                <td>신선한 음식을 제공하도록 당일 경매한 식자재를 새벽배송합니다.</td>
              </tr>
              <tr>
                <td colspan="7"></td>
              </tr>
              <tr>
                <td style="background-color: #FFA852;" class="meal-menu-count-content-title"><v-chip color="dark" style="padding:10px 11px 12px 10px;">5</v-chip>검수</td>
                <td rowspan="2" style="font-size: 28px;">➜</td>
                <td style="background-color: #FF9433;" class="meal-menu-count-content-title"><v-chip color="dark" style="padding:10px 11px 11px 10px;">6</v-chip>조리</td>
                <td rowspan="2" style="font-size: 28px;">➜</td>
                <td style="background-color: #FF8000;" class="meal-menu-count-content-title"><v-chip color="dark" style="padding:10px 10px 11px 10px;">7</v-chip>배식</td>
                <td rowspan="2" style="font-size: 28px;">➜</td>
                <td style="background-color: #FF8000;" class="meal-menu-count-content-title"><v-chip color="dark" style="padding:10px 10px 11px 10px;">8</v-chip>세척</td>
              </tr>
              <tr class="meal-menu-count-content-ment">
                <td>안전한 급식이 되도록 배송된 식자재를 철저히 검수합니다.</td>
                <td>입소 어르신의 입맛을 돋우고 원활한 소화를 위해 건강하고 맛있는 식사를 만듭니다.</td>
                <td>조리하여 완성된 음식을 입소 어르신에게 배식합니다.</td>
                <td>수거된 조리도구 및 식판 등을 위생안전기준에 맞추어 세척살균 후 보관합니다.</td>
              </tr>
            </tbody>
          </v-table>

        </div>
      </div>
    </div>
  </v-sheet>
</template>
<style>
.meal-menu-img-content{
  margin: 60px 0 100px 0;
  border-radius: 10px 10px 15px 15px;
  background-color: #f5f5f5;
  padding-right: 20px;
}
.meal-menu-count-content{
  margin: 40px 0;
}

.meal-menu-count-content-tb .v-chip{
  font-size: 22px; 
  position: absolute; 
  left:15px;
}
.meal-menu-count-content-title{
  font-size: 22px;
  font-weight: 600;
  padding: 20px 16px !important;
  border-radius: 15px 15px 0 0;
}
.meal-menu-count-content-ment td{
  border: none !important;
  padding: 10px 30px 20px 30px !important;
  font-size: 18px;
  width: 400px;
  border-radius: 0 0 15px 15px;
  letter-spacing: -0.05rem;
  background-color: #f5f5f5;
}
.meal-menu-count-content-tb td{
  text-align: center;
  position: relative;
  border: none !important;
}
.meal-menu-count-content-tb tr{
  margin: 80px 0;
}

.meal-menu .meal_menu_img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.meal-col-img{
  padding: 40px;
}
.meal-menu .meal-col-ment{
  padding-top: 60px;
  font-size: 18px;
  
}
.meal-col-ment p{
  letter-spacing: -0.05rem;
  line-height:1.8; 
}
</style>
<script setup>
import { onMounted} from 'vue';

onMounted(() => {
  window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
})
</script>