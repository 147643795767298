<template>
  <v-sheet class="blogMainSheet mainMaxWidth">
    <div v-if="loading" class="spinner tac"></div>
    <div v-else>
      <div class="blogSearchDiv tac" >
        <v-select
        v-if="role >= 8"
          variant="outlined"
          label="카테고리"
          :items="cateValue"
          color="indigo"
          autocomplete="off"
          hide-details="auto"
          flat
          density="compact"
          style="width: auto; min-width: 70px; max-width: 150px"
        ></v-select>

        <v-text-field
        v-if="role >= 8"
          class="inoutSearchInput"
          style="background-color: #fff; max-width: 300px; width: auto; min-width: 70px"
          color="#0f7545"
          label="제목검색"
          clearable
          :append-inner-icon="mdiMagnify"
          flat
          hide-details="auto"
          variant="outlined"
          density="compact"
        />

        <!-- <v-btn v-if="role >= 8" @click="styleChange = !styleChange">폼변경</v-btn> -->
        <v-btn v-if="role >= 8" @click="$router.push('/EditBlog')">글쓰기</v-btn>
      </div>
      <v-row v-if="!styleChange">
        <v-col cols="4" v-for="(item, i) in blogList" :key="i">
          <v-card
            @click="viewPage(item.idx)"
            class="mx-auto gridStyleBlog"
            max-width="400"
            height="335"
            elevation="0"
          >
            <div class="blogImgBox">
              <v-img height="200" :src="item.thumbnail" cover></v-img>
            </div>
            <v-card-subtitle class="pt-0" style="font-size: 12px">
              {{ item.cate }}
              <div class="gubunLine"></div>
              {{ item.wdate }}</v-card-subtitle
            >
            <v-card-text>
              <div>
                <span v-if="item.status === 0" style="letter-spacing: -0.03em; color: red"
                  >삭제됨
                </span>
                <span v-if="item.status === 1" style="letter-spacing: -0.03em; color: red"
                  >임시저장
                </span>
                <span
                  style="font-size: 18px; font-weight: 500; letter-spacing: -0.03em"
                  >{{ item.title }}</span
                >
              </div>
              <div style="font-size: 12px; opacity: 0.8; letter-spacing: -0.03em">
                {{ item.sub }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div v-else>
        <v-card
          v-for="(item, i) in blogList"
          :key="i"
          class="mx-auto stickStyleBlog"
          elevation="0"
        >
          <p>{{ item.cate }}</p>
          <div class="gubunLine"></div>
          <p>{{ item.wdate }}</p>
          <div style="display: flex">
            <div style="width: 100%">
              <p>{{ item.title }}</p>
              <p>{{ item.sub }}</p>
            </div>
            <div style="width: 250px; float: right">
              <v-img
                style="border-radius: 10px"
                max-width="250"
                height="140"
                :src="item.thumbnail"
                cover
              >
              </v-img>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </v-sheet>
</template>
<script setup lang="ts">
import router from "@/router";
import store from "@/store";
import { mdiMagnify } from "@mdi/js";
import axios from "axios";
import { onMounted, ref } from "vue";
const role = store.getters.role;
const styleChange = ref(false);
const cateValue = ["운영관리", "노무관리", "재무회계", "위탁급식", "기타"];
const blogList = ref([] as any[]);
const loading = ref(false);

const viewPage = async (code: string) => {
  if (code) {
    store.commit("setBlogCode", code);
    router.push("/BlogContentView");
  } else {
    return;
  }
};
const getBlogList = async () => {
  try {
    loading.value = true; // 로딩 상태 시작
    const response = await axios.get(`/api/getBlogList`); // 블로그 목록 요청
    const responseData = response.data; // 응답 데이터

    if (responseData) {
      // 블로그 목록 저장
      blogList.value = responseData.map((blog: { thumbnail: any; }) => ({
        ...blog,
        thumbnail: `${blog.thumbnail}?t=${new Date().getTime()}`, // 쿼리 파라미터 추가
      }));

      blogList.value = blogList.value.filter((item) => {
        return ((item.status === 2 && role < 8) || role >= 8);
      });

      return responseData; // 응답 데이터 반환
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error); // 에러 로그
  } finally {
    loading.value = false; // 로딩 상태 종료
  }
};


onMounted(async () => {
  await getBlogList();
});
</script>
<style>
.blogSearchDiv {
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blogMainSheet {
  padding: 80px 0 80px 0;
  width: 100%;
  height: 100%;
  margin: auto;
}
.blogMainSheet .v-col {
  padding: 10px;
}

.blogMainSheet .gridStyleBlog {
  cursor: pointer;
  border-radius: 10px;
}
.blogMainSheet .gridStyleBlog:active {
  opacity: 0.8;
  transition: transform 0s ease;
}
.blogMainSheet .gridStyleBlog:hover {
  background-color: rgb(216, 216, 216);
}

.blogMainSheet .stickStyleBlog {
  margin: 10px 0s;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 18px;
}
.blogMainSheet .stickStyleBlog:active {
  opacity: 0.8;
  transition: transfor m 0s ease;
}
.blogMainSheet .stickStyleBlog:hover {
  background-color: rgb(113, 138, 49);
}

.blogImgBox {
  padding: 12px;
}
.blogImgBox .v-img {
  border-radius: 10px 10px 0 0;
}
.spinner {
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-left: 30px solid #8b2197;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
  margin: 60px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
