<!--엑셀업로드 후 반환데이터를 vuex에 저장하기 위한 컴포넌트-->
<template>
  <div style="font-weight: 400;">
    <v-btn @click="dialog = true" class="btnColorExcel" >
      <span class="btnIconText" style="color:#fff;">엑셀업로드</span>
    </v-btn>
  </div>

   
    <v-dialog v-model="dialog" height="800px" style="width: 500px;" class="fontNotoSans400" persistent>
    <v-card style="padding: 20px !important;"> <!--elevation : 그림자-->
        <h2 style="text-align: center">임금대장 엑셀업로드</h2>
        <span class="tac" style="opacity: 0.9; font-size: 14px;">양식에 맞지 않는 엑셀파일은 거부될 수 있습니다.</span>
        <div class="wageClientInfoForm">

          <v-file-input 
            v-model="fileInputValue" 
            density="compact" 
            label="양식에 맞는 엑셀 파일을 업로드해주세요👆" 
            variant="outlined"
            style="padding:0 !important;" 
            accept=".xlsx, .xls"
          />
          
          <v-icon @click="downloadExcelFile('임금대장상세진단양식_함우리.xlsx')" style="cursor: pointer; color: darkgreen;">
            {{mdiFileExcel }}
          </v-icon>
          <span @click="downloadExcelFile('임금대장상세진단양식_함우리.xlsx')" style="cursor: pointer;">
            양식 다운로드
          </span>
          <v-btn class="excelUploadBtn" @click="excelDirectGet">업로드</v-btn>
          <v-btn @click="dialog = false" class="cancelRegistBtn">닫기</v-btn>

        </div>
    </v-card>

  </v-dialog>
  <div class="text-center">
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import axios from 'axios';
import {
  mdiFileExcel,
} from '@mdi/js'
import store from '@/store';
const loading = ref(false);
const dialog = ref(false);

const fileInputValue = ref();

const downloadExcelFile = (fileName: any) => {
  window.location.href = `/api/downloadTemplateExcel/${fileName}`;
};

const excelDirectGet = async () => {
  loading.value = true;

  let files = fileInputValue.value;
  if (!files) {
    console.error('No file selected');
    return;
  }

  const formData = new FormData();
  const fileExtension = files.name.split('.').pop().toLowerCase();
  const allowedExtensions = ['xlsx','xls'];

  if (!allowedExtensions.includes(fileExtension)) {
    alert('허용되지 않는 파일 형식입니다.');
    return;
  }

  formData.append('file', files);

  try {
    const response = await axios.post(`/api/excelDirectGet`, formData);

    const result = response.data;
    if (result.success === true) {
      alert('엑셀 업로드가 완료되었습니다.');
      store.commit('setWageDirectPushBox', result.data)
      dialog.value = false;
    } else {
      alert('엑셀 업로드에 실패하였습니다. 엑셀을 확인해주세요.');
    }
  } catch (error) {
    alert('엑셀 업로드에 에러가 발생했습니다. 관리자에게 문의해주세요.');
    console.error(error);
  } finally{
    loading.value = false;
  }
};


</script>
