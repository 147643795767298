<template>
  <v-sheet>
    <v-row>

      <v-col v-if="toggle === true" xl="1" lg="1" md="2"  sm="2" xs="3" cols="3" style="min-width: 400px; margin:0 0 0 15px;  padding: 0 !important;">
        <LeftClient />
      </v-col>
      <v-col v-if="toggle === true" style="max-width: 20px; padding: 0; display: flex; align-items: center; justify-content: center;">
        <v-btn @click="toggleBtn" style="min-height: 100px !important; width: 100%; background-color: #333; padding: 0;">
          <v-icon style="color: #fff; width:30px; height: 100% !important;">{{ mdiMenuLeft }}</v-icon>
        </v-btn>
      </v-col>

      <v-col v-if="toggle === false" style="max-width: 20px; padding: 0; display: flex; align-items: center; justify-content: center;">
        <v-btn @click="toggleBtn" style="min-height: 100px !important; width: 100%; background-color: #333; padding: 0;">
          <v-icon style="color: #fff; width:30px; height: 100% !important;">{{ mdiMenuRight }}</v-icon>
        </v-btn>
      </v-col>
      <v-col style= "min-width: 800px; padding-left: 0;">
        <RightClientAdmin v-if="role >= 8"/>
        <RightClient v-if="role === 7"/>
      </v-col>

    </v-row>
  </v-sheet>


</template>

<script setup lang="ts">
import LeftClient from '@/components/mng/one-client/LeftClient.vue';
import RightClientAdmin from '@/components/mng/one-client/RightClientAdmin.vue';
import RightClient from '@/components/mng/one-client/RightClient.vue';

import { ref } from 'vue';
import {
  mdiMenuRight,
  mdiMenuLeft
} from '@mdi/js'
import store from '@/store';
const role = store.getters.role;
const toggle = ref(true);
const toggleBtn = () => {
  toggle.value = !toggle.value; 
}
</script>
