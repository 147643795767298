<template>
  <template v-if="loading">
    <div class="spinner tac"></div>
  </template>
  <template v-else>
    <v-data-table fixed-header scrollable :items-per-page="-1" :items-per-page-options="[
    { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
  ]" density="compact" :headers="headerCateList" :items="filteredItems" item-value="name"
      class="wageMenuTable mngWageTable "
      style="height: calc(100vh - 110px); overflow-y: hidden; position: relative;">

      <template v-slot:top>

        <v-toolbar flat class="headerToolBar">
          <v-toolbar-title class="fontNotoSans700">🧮임금대장 상세진단</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn @click="openDialog" class="btnColorBlue">합산금액</v-btn>
          <v-dialog style="width: 600px;" v-model="dialog" class="fontNotoSans700">
            <v-card>
              <v-card-text>
              <v-table class="oneClientDialogTb" style="font-size: 13px; width: 100%;">
                <thead>
                  <tr>
                    <th></th>
                    <th style="text-align: center;" colspan="2">기관</th>
                    <th style="text-align: center;" class="tac" colspan="2">근로자</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                  <th></th>
                  <th>공제합(사)</th>
                  <th style="color: darkgreen;">연금제외</th>
                  <th>공제합(노)</th>
                  <th style="color: darkgreen;">연금제외</th>
                </tr>
                <tr>
                  <td>개선 전</td>
                  <td class="tar">{{ comma(BsumCTotal) }}원</td>
                  <td class="tar">{{ comma(BsumCTotal - BsumCYeon) }}원</td>
                  <td class="tar">{{ comma(BsumLTotal) }}원</td>
                  <td class="tar">{{ comma(BsumLTotal - BsumLYeon) }}원</td>
                </tr>
                <tr>
                  <td>개선 후</td>
                  <td class="tar">{{ comma(AsumCTotal) }}원</td>
                  <td class="tar">{{ comma(AsumCTotal - AsumCYeon) }}원</td>
                  <td class="tar">{{ comma(AsumLTotal) }}원</td>
                  <td class="tar">{{ comma(AsumLTotal - AsumLYeon) }}원</td>
                </tr>
                <tr>
                  <td>차 액</td>
                  <td class="tar" style="color: rgb(168, 90, 90);">{{ comma(BsumCTotal - AsumCTotal) }}원</td>
                  <td class="tar" style="color: rgb(168, 90, 90);">{{ comma((BsumCTotal - BsumCYeon) - (AsumCTotal - AsumCYeon)) }}원</td>
                  <td class="tar" style="color: rgb(168, 90, 90);">{{ comma(BsumLTotal - AsumLTotal) }}원</td>
                  <td class="tar" style="color: rgb(168, 90, 90);">{{ comma((BsumLTotal - BsumLYeon) - (AsumLTotal - AsumLYeon)) }}원</td>
                </tr>

                <tr style="  font-weight: 700;">
                  <td>연간차액</td>
                  <td class="tar" style="color: rgb(168, 90, 90);">{{ comma(12*(BsumCTotal - AsumCTotal)) }}원</td>
                  <td class="tar" style="color: rgb(168, 90, 90);">{{ comma(12*((BsumCTotal - BsumCYeon) - (AsumCTotal - AsumCYeon))) }}원</td>
                  <td class="tar" style="color: rgb(168, 90, 90);">{{ comma(12*(BsumLTotal - AsumLTotal)) }}원</td>
                  <td class="tar" style="color: rgb(168, 90, 90);">{{ comma(12*((BsumLTotal - BsumLYeon) - (AsumLTotal - AsumLYeon))) }}원</td>
                </tr>
              </tbody>
              </v-table>
            </v-card-text>
            <v-btn @click="dialog=false">닫기</v-btn>
            </v-card>
          </v-dialog>
          
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-text-field class="searchWorker" style="
            background-color: #fff;
            max-width:200px; 
            width:auto; 
            min-width:70px;
            margin: 0 0 0 0;
          " v-model="search" color="#0f7545" label="직종 검색" single-line clearable :append-inner-icon="mdiMagnify" flat
            hide-details="auto" variant="outlined" density="compact">
          </v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <MngExcelUpload @success="readWageForm('basic')"/>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn class="btnColorDark" @click="mngWageSave">
            <span class="btnIconText">저장</span>
          </v-btn>
          <v-btn class="btnColorBlue" @click="rowCreate()">
            <span class="btnIconText">행 추가</span>
            <v-icon style="color: #fff; margin-left: 3px;">{{ mdiPlus }}</v-icon>
          </v-btn>
        </v-toolbar>
      </template>


      <template v-slot:item="{ item, index }">

        <tr :class="getRowClass(index)">
          <td class="sticky-column">
            {{ index + 1 }}
          </td>
          <td>
            <v-text-field type="text" :rules="workerRule" variant="underlined" v-model="item.worker" />
          </td>
          <td class="lineTd" style="min-width: 90px !important;">
            <v-text-field type="text" maxlength="6" style="width: 90px !important;" variant="underlined"
              v-model="item.birth" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.base" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.overtime" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.night" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.holiday" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.dayOff" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.longTerm" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.bonus" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.etc" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.lunch" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.car" />
          </td>
          <td class="lineTd">
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.childcare" />
          </td>
          <td>
            <v-btn type="button" @click="rowDelete(index)" class="tac">
              <v-icon style="color: darkgreen;">{{ mdiDelete }}</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </template>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import store from '@/store';
import {
  mdiPlus,
  mdiDelete,
  mdiMagnify
} from '@mdi/js'
import {
  numRule,
  workerRule
} from '../../../composables/rules';
import axios from 'axios';
import MngExcelUpload from '../../excel/MngExcelUpload.vue'
const loading = ref(false);


const getRowClass = (index: number) => {
  // 인덱스가 짝수이면 'oneTr' 클래스를, 홀수이면 'twoTr' 클래스를 반환
  return index % 2 === 0 ? 'twoTr2' : 'threeTr';
}

const BsumLTotal = ref(0);
const BsumCTotal = ref(0);
const AsumLTotal = ref(0);
const AsumCTotal = ref(0);
const BsumLYeon = ref(0);
const AsumLYeon = ref(0);
const BsumCYeon = ref(0);
const AsumCYeon = ref(0);
const dialog = ref(false);
const openDialog = () => {
  dialog.value = true;
}
const comma = (num: any) => {
  if (num === 0) return '';
  // 1의 자리 절삭 (num을 10으로 나누고, 다시 10을 곱함)
  num = Math.floor(num / 10) * 10;
  // 숫자를 문자열로 변환 후, 천 단위 컴마 추가
  return num.toLocaleString();
};
const cleanValue = (value: any) => {
  if (value === '') {
    return 0;
  }
  if (typeof value === 'string' && /^[\d,]+$/.test(value)) {
    // 컴마 제거 후 숫자로 변환
    return parseInt(value.replace(/,/g, ''), 10);
  }
  return value;
};
const client = store.state.mngOneClient;


const createForm = () => {
  const form = reactive({
    idx: 0,
    worker: '',     // 근로자명
    birth: '',
    man: '',
    base: 2060740,  // 기본급
    overtime: 0,    // 연장근로
    night: 0,       // 야간근로
    holiday: 0,     // 휴일근로
    dayOff: 0,      // 연차
    longTerm: 0,    // 장기
    bonus: 0,       // 상여
    etc: 0,         // 기타
    lunch: 0,       // 중식
    car: 0,         // 차량
    childcare: 0,   // 양육
    total: 0,       // 총 임금
    taxable: 0,     // 과세 임금
    usual: 0,       // 통상 임금
    minimum: 0,     // 최저 임금

    l_yeon: 0,
    l_gun: 0,
    l_jang: 0,
    l_go: 0,
    l_so: 0,
    l_ji: 0,
    l_total: 0,

    c_yeon: 0,
    c_gun: 0,
    c_jang: 0,
    c_go: 0,
    c_sil: 0,
    c_san: 0,
    c_out: 0,
    c_total: 0,

    status: 1
  });
  return form;
};

const formList = ref([] as any[]);
formList.value.push(createForm());

const rowCreate = () => {
  formList.value.push(createForm());
};

const headerCateList = ref([
  {
    title: '', align: 'center', children: [
      { title: '순번', key: 'index', align: 'center' },
      { title: '직종', key: 'worker', align: 'center', sortable: false },
      { title: '생년월일', key: 'birth', align: 'center', sortable: false }
    ]
  },
  {
    title: '임금구성', align: 'center', children: [
      { title: '기본급', key: 'base', align: 'center', sortable: false },
      { title: '연장수당', key: 'overtime', align: 'center', sortable: false },
      { title: '야간수당', key: 'night', align: 'center', sortable: false },
      { title: '휴일수당', key: 'holiday', align: 'center', sortable: false },
      { title: '연차수당', key: 'dayOff', align: 'center', sortable: false },
      { title: '장기근속', key: 'longTerm', align: 'center', sortable: false },
      { title: '상여금', key: 'bonus', align: 'center', sortable: false },
      { title: '기타수당', key: 'etc', align: 'center', sortable: false },
      { title: '중식대', key: 'lunch', align: 'center', sortable: false },
      { title: '차량비', key: 'car', align: 'center', sortable: false },
      { title: '양육비', key: 'childcare', align: 'center', sortable: false },
    ]
  },
  {
    title: '', align: 'center', children: [
      { title: '삭제', align: 'center' },
    ]
  },
] as any[]);


const rowDelete = (index: any) => {
  formList.value.splice(index, 1);
};

const search = ref('');

const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return formList.value;
  }
  return formList.value.filter(item =>
    (item.worker && item.worker.toLowerCase().includes(search.value.toLowerCase()))
  );
});

const mngWageSave = async () => {
  if (formList.value.length === 0) {
    return;
  }
  const checkAndLog = (rule: any, value: any, ruleName: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === 'string') {
      alert(`${ruleName}`);
      return true;
    } else {
      return false;
    }
  };

  // const validateFields = (item: any) => {
  //   const keysToValidate = Object.keys(item).filter(key => key !== 'worker' && key !== 'birth' && key !== 'man');
  //   return keysToValidate.some(key =>
  //     numRule.some(rule => checkAndLog(rule, item[key], '숫자만 입력해주세요.'))
  //   );
  // };
const validateFields = (item: any) => {
    // checkAndLog 함수가 정의되어 있는지 확인
    if (typeof checkAndLog !== 'function') {
      throw new Error('checkAndLog 함수가 정의되어 있지 않습니다.');
    }

    // numRule 배열이 정의되어 있는지 확인
    if (!Array.isArray(numRule)) {
      throw new Error('numRule 배열이 정의되어 있지 않습니다.');
    }

    // 유효성 검사를 위한 키들을 필터링
    const keysToValidate = Object.keys(item).filter(key => key !== 'worker' && key !== 'birth' && key !== 'man');

    for (const key of keysToValidate) {
      // 값이 null인 경우 0으로 치환하여 소득세 등 계산된 값이 null일 때를 방지함.
      const valueToCheck = item[key] === null ? 0 : item[key];

      for (const rule of numRule) {
        if (checkAndLog(rule, valueToCheck, '숫자만 입력해주세요.')) {
          alert(`값: ${valueToCheck}`);
          return true; // 문제가 발견되면 true 반환
        }
      }
    }
    return false; // 문제가 없으면 false 반환
  };

  const validateWorker = (item: any) => {
    const keysToValidate = Object.keys(item).filter(key => key === 'worker');
    return keysToValidate.some(key =>
      workerRule.some(rule => checkAndLog(rule, item[key], '직종을 확인해주세요.'))
    );
  };
  
  const isNum = formList.value.some(item => validateFields(item));
  const isWorker = formList.value.some(item => validateWorker(item));
  // const isWorkerDuplicated = checkDuplicateWorker(formList.value);

  if (isNum || isWorker) {
    return;
  } else {
    loading.value = true;
    formList.value = formList.value.map(item => {
      const formattedItem = {} as any;
      for (const key in item) {
        if (key === 'birth') {
          //birth yymmdd가 0으로 시작하는 경우, 함수로직에 의해 0 사라짐 방지.
          formattedItem[key] = item[key];
        } else {
          formattedItem[key] = cleanValue(item[key]);
        }
      }
      return formattedItem;
    });

    try {
      const response = await axios.post(`/api/mngWageSave/${client.fwa_code}`, {
        formList: formList.value,
        rememberStatus1Form: rememberStatus1Form.value
      });
      const result = response.data
      if (result.success) {
        // formList.value = result[0];
        // store.commit('setStatus2Form', result[1]);
        await getFinishWage();
      } else {
        console.log('에러발생')
      }
    }
    catch (error) {
      console.error(error);
      throw error; // 에러 다시 던지기
    } finally {
      loading.value = false;
    }
  }
};
const getFinishWage = async () => {
  try {
    loading.value = true;
    const response = await axios.get(`/api/getFinishWage/${client.fwa_code}`);
    // const responseData = response.data.result;
    const responseData1 = response.data.status1Result;
    const responseData3 = response.data.status3Result;
    store.state.mngOneClient.fwa_status = 3;
    store.commit('setMngOneClient', store.state.mngOneClient);
    store.commit('setStatus2Form', []);
    formList.value = response.data.status1Result;

    const filteredResponseData3: any[] = [];
    for (let i = 0; i < responseData3.length; i++) {
      const item = responseData3[i];
      filteredResponseData3.push(item);
    }
    let AsumLTotals = 0;
    let AsumCTotals = 0;
    let AsumLYeons = 0;
    let AsumCYeons = 0;
    for (const value of filteredResponseData3) {
      AsumLTotals += value.l_total || 0;
      AsumCTotals += value.c_total || 0;
      AsumLYeons += value.l_yeon || 0;
      AsumCYeons += value.c_yeon || 0;
    }
    AsumLTotal.value = AsumLTotals;
    AsumCTotal.value = AsumCTotals;
    AsumLYeon.value = AsumLYeons;
    AsumCYeon.value = AsumCYeons;


    const filteredResponseData1: any[] = [];
    for (let i = 0; i < responseData1.length; i++) {
      const item = responseData1[i];
      filteredResponseData1.push(item);
    }
    let sumLTotal = 0;
    let sumCTotal = 0;
    let BsumLYeons = 0;
    let BsumCYeons = 0;
    for (const value of filteredResponseData1) {
      sumLTotal += value.l_total || 0; // null 또는 undefined일 경우를 대비하여 기본값 0으로 처리
      sumCTotal += value.c_total || 0; // null 또는 undefined일 경우를 대비하여 기본값 0으로 처리
      BsumLYeons += value.l_yeon || 0; // null 또는 undefined일 경우를 대비하여 기본값 0으로 처리
      BsumCYeons += value.c_yeon || 0; // null 또는 undefined일 경우를 대비하여 기본값 0으로 처리
    }
    BsumLTotal.value = sumLTotal;
    BsumCTotal.value = sumCTotal;
    BsumLYeon.value = BsumLYeons;
    BsumCYeon.value = BsumCYeons;



  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loading.value = false;
  }
};
const rememberStatus1Form = ref([] as any[]);

const readWageForm = async (basicORcalc: any) => {
  try {
    loading.value = true;
    const response = await axios.get(`/api/readWageForm/${client.fwa_code}/${basicORcalc}`);
    const responseData = response.data;
    if (responseData) {
      if (basicORcalc === 'basic') {
        rememberStatus1Form.value = responseData
      }
      formList.value = responseData;
      
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  readWageForm('basic')
  store.commit('setStatus2Form', []);
  if (store.state.mngOneClient.fwa_status < 3) {
    await readWageForm('basic');
  } else {
    await getFinishWage();
  }
})
</script>
<style scoped>
.spinner {
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-left: 30px solid #8b2197;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
  margin: 60px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>