<template>
  <v-sheet class="indexSection1 mainMaxWidth" >
    <div class="i1_point"></div>
    <div class="i1_box">
      <p class="i1_top_ment">
        <b> 성공적인 관리운영</b> & <b>EXIT</b>
      </p>
      <div class="i1_content">
        <v-carousel 
          height="527"
          show-arrows="hover"
          cycle
          hide-delimiter-background
          style="border-radius: 50px;
          box-shadow: 1px 1px 4px rgb(67, 66, 66);"
        >
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src="item.src"
            cover
          >
          </v-carousel-item>
        </v-carousel>
      </div>
      
    </div>
  </v-sheet>
</template>
<script setup lang="ts">
import { onMounted} from 'vue';

onMounted(() => {
  window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
})
const items = [
  {src: require('@/assets/pictures/main/트루먼.png') },
  {src: require('@/assets/pictures/main/등대.png') },
];
</script>
<style>
.i1_point{
  width: 77.7%;
    height: 777px;
    background: rgba(65, 113, 208, 0.1);
    border-radius: 180px 0px 0px 180px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}
.i1_box{
  padding: 110px 0 0 0;
}
.i1_top_ment{
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3); /* 그림자 추가 */
  font-size: 28px;
  letter-spacing: -0.03em;
}
.i1_content {
  margin: 20px 0 0 0;
}
</style>