<template>
  <v-btn @click="dialog = true" class="btnColorWhite">직접생성</v-btn>

   <v-dialog v-model="dialog" height="800px" style="width: 500px;" class="fontNotoSans400" persistent>
    <v-card style="padding: 20px !important;"> <!--elevation : 그림자-->
    <v-card-title>상세진단 직접요청</v-card-title>
    <v-card-sub-title style="margin: 0 0 15px 17px;">※ 어떠한 기관명이라도 저장 가능합니다.</v-card-sub-title>

      <div class="stepperDiv">
            <v-table class="stepperTb">
              <tbody>
                <tr>
                  <td class="tal">지역</td>
                  <td class="tal">
                    <select style="cursor: pointer;" v-model="wageInfo.wg_sido" @change="getSigungu()"
                      class="mg-right10">
                      <option value="" class="tac">--- 선택 ---</option>
                      <option class="tal" v-for="sido in sidoInfo" :key="sido.sido_id" :value="sido.sido_id">
                        {{ sido.sido_name }}
                      </option>
                    </select>
                    <select style="cursor: pointer;" v-model="wageInfo.wg_sigungu" @change="getCompName()">
                      <option value="" class="tac">--- 선택 ---</option>
                      <option class="tal" v-for="sigungu in sigunguInfo" :key="sigungu.sigungu_id"
                        :value="sigungu.sigungu_id">{{ sigungu.sigungu_name }}
                      </option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td class="tal">구분</td>
                  <td class="tal">
                    <v-radio-group v-model="wageInfo.wg_biz_gubun" @change="getCompName()" class="label-black" inline
                      flat hide-details="auto">
                      <v-radio label="요양시설" value="요양시설"></v-radio>
                      <v-radio label="주간보호" value="주간보호"></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr>
                  <td class="tal">기관 명</td>
                  <td class="tal">
                    <v-combobox v-model="wageInfo.wg_name" :items="compNames" placeholder="기관 명을 입력해주세요." type="text"
                      variant="outlined" density="compact" hide-details="auto"
                      style="max-width: 280px !important; color: #000 !important;" class="activeClick" clearable>
                    </v-combobox>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
      <v-btn @click="saveClientInfo();" class="cancelRegistBtn smallDialogBtn">저장</v-btn>
      <v-btn @click="dialog = false" class="cancelRegistBtn smallDialogBtn">취소</v-btn>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import store from '@/store';
import axios from 'axios';
import { computed, onMounted, reactive , ref, watch} from 'vue';
const dialog = ref(false);
const sidoInfo = ref([] as any);
const sigunguInfo = ref([] as any);
const compNames = ref([] as any[]);
const compInfo = ref();
// eslint-disable-next-line no-undef
const emit = defineEmits(['success'])
const wageInfo = reactive({
  wg_name: '',
  wg_sido: '',
  wg_sigungu: '',
  wg_biz_gubun: '',
});
function formatDate(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
const clientInfo = reactive({
  wg_client_name: store.state.name,
  wg_client_phone: '01099999999',
  wg_client_email: 'direct_custom@ham.woori',
  wg_client_email_auth: true,
  wg_policy_agree: true,
  wg_playing_agree: true,
  fwa_code: '',
  fwa_upload_date: formatDate(new Date()),
  newFileName: '',
  fwa_manager: store.state.name,
  fwa_come_way: '직접 생성',
})
function formatDateForCode(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
}
const saveClientInfo = async () => {
try {
  if(!wageInfo.wg_sido || !wageInfo.wg_sigungu || !wageInfo.wg_biz_gubun || !wageInfo.wg_name){
    alert('빈 칸이 존재합니다.');
    return;
  }
  if (!compInfo.value?.hc_number) {
      clientInfo.fwa_code = '9999' + wageInfo.wg_sido + wageInfo.wg_sigungu + formatDateForCode(new Date());
    } else {
      clientInfo.fwa_code = compInfo.value.hc_number;
    }
  const response = await axios.post(`/api/saveClientInfo/`, {
    clientInfo: clientInfo,
    wageInfo: wageInfo,
    wageData: []
  });
  const result = response.data
  if (result.success) {
    emit('success');
    alert("정상 생성되었습니다.");
    dialog.value = false;
  } else {
    alert("잘못된 접근입니다.");
  }
}
catch (error) {
  console.error(error);
  throw error; // 에러 다시 던지기
}
};

const rgNameComputed = computed(() => {
return wageInfo.wg_name;
});

watch(rgNameComputed, () => {
if (wageInfo.wg_name) {
  getCompInfo();
}
});

const getSido = async () => {
try {
  const resSido = await axios.get(`/api/getSido/`);
  const SidoData = resSido.data;
  if (SidoData) {
    sidoInfo.value = SidoData
    return SidoData;
  } else {
    console.error('에러가 발생했습니다.');
  }
} catch (error) {
  console.error('errorMsg:', error);
}
};

const getSigungu = async () => {
wageInfo.wg_sigungu = '';
wageInfo.wg_name = '';
if (wageInfo.wg_sido === '') {
  compNames.value = [];
  return;
}
try {
  const resSigungu = await axios.get(`/api/getSigungu/${wageInfo.wg_sido}`);
  const SigunguData = resSigungu.data;
  if (SigunguData) {
    sigunguInfo.value = SigunguData
    compNames.value = [];
    return SigunguData;
  } else {
    console.error('에러가 발생했습니다.');
  }
} catch (error) {
  console.error('errorMsg:', error);
}
};

const getCompName = async () => {
wageInfo.wg_name = '';

if (!wageInfo.wg_biz_gubun || !wageInfo.wg_sido) {
  compNames.value = [];
  return;
}
if (!wageInfo.wg_sigungu) {
  if (parseInt(wageInfo.wg_sido) !== 36) {
    // 세종 시군구없음 방지
    return;
  } else {
    wageInfo.wg_sigungu = 'sejong'
  }
}
try {
  const response = await axios.get(`/api/getCompName/${wageInfo.wg_sido}/${wageInfo.wg_sigungu}/${wageInfo.wg_biz_gubun}`);
  const responseData = response.data;
  if (responseData) {
    compNames.value = responseData.map((item: { hc_name: any; }) => `${item.hc_name}`);
    return responseData;
  } else {
    console.error('에러가 발생했습니다.');
  }
} catch (error) {
  console.error('errorMsg:', error);
}
};

const getCompInfo = async () => {
if (!wageInfo.wg_sido || !wageInfo.wg_sigungu || !wageInfo.wg_biz_gubun || !wageInfo.wg_name) {
  return;
}
try {
  const response = await axios.get(`/api/getCompInfo/${wageInfo.wg_sido}/${wageInfo.wg_sigungu}/${wageInfo.wg_biz_gubun}/${wageInfo.wg_name}`);
  const responseData = response.data;
  if (responseData) {
    compInfo.value = responseData;
    return responseData;
  } else {
    console.error('에러가 발생했습니다.');
  }
} catch (error) {
  console.error('errorMsg:', error);
}
};

onMounted(async () => {
  await getSido();
})

</script>