<template>
  <div style="font-weight: 400;">
    <v-btn @click="openDialog" style="width:100%" class="btnColorUpdate">
      <span style="color:#fff;">기관정보수정</span>
    </v-btn>
  </div>


  <v-dialog v-model="dialog" height="800px" style="width: 500px;" class="fontNotoSans400" persistent>
    <v-card style="padding: 20px !important;"> <!--elevation : 그림자-->
      <h2 style="text-align: center">기관정보수정</h2>
      <v-form class="wageClientInfoForm">
        
        <v-text-field :prepend-inner-icon="mdiNoteTextOutline" variant="outlined" label="사업자등록번호" type="text"
          v-model="form.sisul_biz_num" maxlength="11" :rules="bizNumberRule" color="indigo" autocomplete="off" />
          <DaumAddr @updateAddress="updateAddress" :addr="form.sisul_addr"/>
       
        <!-- <v-text-field :prepend-inner-icon="mdiMapLegend" variant="outlined" label="기관 주소" type="text"
          v-model="form.sisul_addr" maxlength="150" :rules="addrRule" color="indigo" autocomplete="off" /> -->
          <v-text-field :prepend-inner-icon="mdiPhoneClassic" variant="outlined" label="기관 전화번호" type="text"
          v-model="form.sisul_tell" maxlength="11" :rules="localNumberRule" color="indigo" autocomplete="off" />
        
          <v-select
              variant="outlined" 
              :prepend-inner-icon="mdiBankOutline"
              label="은행명"
              :items="bankValue"
              v-model="form.sisul_bank"
              color="indigo"
              autocomplete="off"
              class="comeWaySelect"
            ></v-select>

        <v-text-field :prepend-inner-icon="mdiWallet" variant="outlined" label="계좌번호(숫자만 입력해주세요.)" type="text"
          v-model="form.sisul_bank_num" maxlength="20" :rules="bankNumRule" color="indigo" autocomplete="off" />
        
        <v-text-field :prepend-inner-icon="mdiShieldAccount" variant="outlined" label="예금주" type="text"
          v-model="form.sisul_bank_owner" maxlength="10" :rules="bankOwnerRule" color="indigo" autocomplete="off" />

        <v-text-field :prepend-inner-icon="mdiAccountTie" variant="outlined" label="대표자 성명" type="text"
          v-model="form.sisul_boss_name" maxlength="10" :rules="bossNameRule" color="indigo" autocomplete="off" />

        <v-text-field :prepend-inner-icon="mdiCellphone" variant="outlined" label="대표자 휴대폰 번호" type="text"
          v-model="form.sisul_boss_phone" maxlength="11" :rules="phoneNumberRule" color="indigo" autocomplete="off" />

        <div class="wageAuthForm">

          <v-text-field 
            v-if="form.sisul_boss_email_auth === false && seconds === 0 && !loading && emailChange"
            :prepend-inner-icon="mdiEmailVariant" 
            variant="outlined" 
            label="대표자 이메일" 
            hint="이메일 형식을 맞춰주세요." 
            type="email"
            v-model="form.sisul_boss_email" 
            maxlength="50" 
            :rules="emailRule" 
            color="indigo" 
            autocomplete="off" 
          />

          <v-text-field 
            v-else :prepend-inner-icon="mdiEmailVariant" 
            variant="outlined" 
            label="대표자 이메일"
            type="email" 
            v-model="form.sisul_boss_email"
            maxlength="50" 
            :rules="emailRule"
            color="indigo" 
            autocomplete="off" 
            readonly 
          />
          <v-btn v-if="!emailChange"
            @click="changeEmail()">변경</v-btn>
          <v-btn v-if="seconds === 0 && !loading && !form.sisul_boss_email_auth && emailChange"
            @click="sendWageAuthMail()">인증번호</v-btn>
          <v-btn v-else-if="seconds > 0 && seconds <= 270 && !loading && !form.sisul_boss_email_auth && emailChange"
            @click="sendWageAuthMail()">재발송</v-btn>
          <v-btn v-if="emailChange" @click="changeEmail()">취소</v-btn>

          <div v-if="loading" class="spinner tac"></div>
        </div>
        <v-tooltip text="Tooltip" v-if="form.sisul_boss_email_auth === false && seconds !== 0 && emailChange">
          <template v-slot:activator="{ props }">

            <v-text-field v-bind="props" :prepend-inner-icon="mdiEmailAlert" v-model="authCodeInput" label="인증번호"
              density="compact" variant="outlined" style="padding:0 !important;" :rules="authNumRule" maxlength="6"
              color="indigo" @change="authRandomCode(authCodeInput)" />
              
          </template>
          <div v-if="form.sisul_boss_email_auth === false && seconds !== 0">
            <span>인증시간이 {{ minutes }}분 {{ remainingSeconds }}초 남았습니다. </span>
            <br v-if="seconds > 270" />
            <span v-if="seconds > 270">재발송은 {{ seconds - 270 }}초 후 가능합니다.</span>
          </div>
        </v-tooltip>

        <v-btn class="clientInfoBtn" @click="updateSsInfo">수정완료</v-btn>
        <v-btn @click="closeDialog" class="cancelRegistBtn">취소</v-btn>
      </v-form>
    </v-card>
    <div class="text-center">
      <v-snackbar v-model="snackbar" :snacktime="snacktime">
        {{ snacktext }}
      </v-snackbar>
    </div>

  </v-dialog>
  <div class="text-center">

  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import axios from 'axios';
import store from '@/store';
import DaumAddr from '@/components/api/DaumAddr.vue'

import {
  mdiEmailVariant,
  mdiAccountTie,
  mdiCellphone,
  mdiEmailAlert,
  mdiPhoneClassic,
  mdiBankOutline,
  mdiShieldAccount,
  mdiNoteTextOutline,
  mdiWallet

} from '@mdi/js'
import {
  emailRule,
  phoneNumberRule,
  authNumRule,
  bossNameRule,
  localNumberRule,
  addrRule,
  bizNumberRule,
  bankNumRule,
  bankOwnerRule
} from '../../composables/rules';
// eslint-disable-next-line no-undef
const emit = defineEmits(['success'])
const dialog = ref(false);
const emailChange = ref(false);
const snackbar = ref(false);
const snacktext = ref(`메일이 전송되었습니다. 인증번호는 5분간 유효합니다.`);
const snacktime = ref(5000);
const randomCode = ref(generateRandomCode());

const loading = ref(false);
const authCodeInput = ref('');
const bankValue= [
'기업은행',
'국민은행',
'외환은행',
'수협은행',
'농협은행',
'지역농협',
'우리은행',
'SC제일은행',
'서울은행',
'한국씨티은행',
'대구은행',
'부산은행',
'광주은행',
'제주은행',
'전북은행',
'경남은행',
'새마을금고연합회',
'신협중앙회',
'하나은행',
'신한은행',
'케이뱅크',
'카카오뱅크',
'토스뱅크'
]
const form = reactive({
  sisul_id: '',
  sisul_addr: '',
  sisul_tell: '',
  sisul_boss_name: '',
  sisul_boss_phone: '',
  sisul_boss_email: '',
  sisul_boss_email_auth: false,
  sisul_biz_num: '',
  sisul_bank: '',
  sisul_bank_num: '',
  sisul_bank_owner: '',
});

const updateAddress = (address: any) => {
  form.sisul_addr = `${address.address}`;
};
const openDialog = () => {
  dialog.value = true;
  store.commit('setRandomCode', '');
  authCodeInput.value = '';
  seconds.value = 0;
  form.sisul_boss_email_auth = false;
  clearInterval(intervalId);
  emailChange.value = false
  const Ss = store.state.SsInfoBox;
  form.sisul_id = Ss.sisul_id;
  form.sisul_addr = Ss.sisul_addr;
  form.sisul_tell = Ss.sisul_tell?.replace(/\D/g, '');
  form.sisul_boss_name = Ss.sisul_boss_name;
  form.sisul_boss_phone = Ss.sisul_boss_phone?.replace(/\D/g, '');
  form.sisul_boss_email = Ss.sisul_boss_email;
  form.sisul_biz_num = Ss.sisul_biz_num?.replace(/\D/g, '');
  form.sisul_bank = Ss.sisul_bank;
  form.sisul_bank_num = Ss.sisul_bank_num;
  form.sisul_bank_owner = Ss.sisul_bank_owner;
}

const closeDialog = () => {
  dialog.value = false;
}

const changeEmail = () => {
  emailChange.value = !emailChange.value
  if(emailChange.value === false){
    form.sisul_boss_email = store.state.SsInfoBox.sisul_boss_email;
    form.sisul_boss_email_auth = false;
    store.commit('setRandomCode', '');
    authCodeInput.value = '';
    seconds.value = 0;
    clearInterval(intervalId);

  }
}
function generateRandomCode() {
  return (Math.floor(100000 + Math.random() * 900000)).toString();
}
watch(randomCode, (newValue) => {
  store.commit('setRandomCode', newValue);
});
const seconds = ref(0)
let intervalId = 0;
const startTimer = () => {
  try {
    if (seconds.value !== 0 && intervalId !== 0) {
      clearInterval(intervalId)
    }
    seconds.value = 300
    snackbar.value = true;
    intervalId = setInterval(() => {
      if (seconds.value > 0) {
        seconds.value--
      } else {
        clearInterval(intervalId)
        alert('인증번호 입력제한시간이 초과하였습니다.')
      }
    }, 1000)
  } catch {
    console.log('타이머 에러')
  }
}
const minutes = computed(() => Math.floor(seconds.value / 60))
const remainingSeconds = computed(() => seconds.value % 60)
const authRandomCode = (code: any) => {
  if (parseInt(code) > 99999) {
    if (store.state.randomCode === authCodeInput.value) {
      clearInterval(intervalId)
      form.sisul_boss_email_auth = true;
      seconds.value = 0;
      alert('인증되었습니다.');
    } else {
      alert('인증번호가 일치하지 않습니다.')
    }
  } else {
    return;
  }
}
const sendWageAuthMail = async () => {
  if (seconds.value >= 270) {
    alert(`인증번호 재발송은 ${seconds.value - 270}초 후 가능합니다.`);
    return;
  }
  const checkAndLog = (rule: any, value: any, ruleName: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === 'string') {
      alert(`${ruleName}`);
      return true;
    } else {
      return false;
    }
  };
  const isEmailValid = emailRule.some(rule => checkAndLog(rule, form.sisul_boss_email, '올바른 이메일을 작성해주세요.'));
  if (isEmailValid) return;
  loading.value = true;
  randomCode.value = generateRandomCode();
  try {
    const response = await axios.post(`/api/sendWageAuthMail/${form.sisul_boss_email}/${randomCode.value}`);
    const result = response.data;
    if (result.success) {
      startTimer();
      return result; // 가져온 데이터를 반환
    } else {
      alert('메일 전송에 실패하였습니다. 관리자에게 문의바랍니다.')
      console.error('에러가 발생했습니다.', result);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loading.value = false;
  }
};

const updateSsInfo = async () => {
  try {

    const checkAndLog = (rule: any, value: any, ruleName: any) => {
      const ruleResult = rule(value);
      if (typeof ruleResult === 'string') {
        alert(`${ruleName} 확인해주세요.`);
        return true;
      } else {
        return false;
      }
    };

    const isAddrValid = addrRule.some(rule => checkAndLog(rule, form.sisul_addr, '기관 주소를'));
    if (isAddrValid) return;

    const isBNValid = bossNameRule.some(rule => checkAndLog(rule, form.sisul_boss_name, '대표자 성명을'));
    if (isBNValid) return;

    const isBPValid = phoneNumberRule.some(rule => checkAndLog(rule, form.sisul_boss_phone, '대표자 휴대폰번호를'));
    if (isBPValid) return;

    if(emailChange.value === true){
      const isBEValid = emailRule.some(rule => checkAndLog(rule, form.sisul_boss_email, '대표자 이메일을'));
      if (isBEValid) return;
      if (!store.state.randomCode && (form.sisul_boss_email !== store.state.SsInfoBox.sisul_boss_email)){
        alert('이메일 인증이 필요합니다.')
        return;
      }
      if (store.state.randomCode !== authCodeInput.value) {
        alert('이메일 인증번호가 틀립니다.');
        return;
      }
    }

    const response = await axios.post('/api/updateSsInfo', { form: form });
    const result = response.data;
    if (result.success === true) {
      emit('success');
      alert('기관정보 수정이 완료되었습니다.');
      dialog.value = false;
    } else {
      alert('기관정보 수정에 실패하였습니다. 관리자에게 문의해주세요.');
    }
  } catch (error) {
    alert('기관정보 수정을 처리할 수 없습니다.');
    console.error(error);
  }
};


</script>
<style scoped>
.spinner {
  width: 30px !important;
  margin: 0 0 18px 8px;
  display: flex;
  height: 30px !important;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>