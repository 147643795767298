<template>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn
          class="wageInfoBtn"
          text="임금대장 직접입력"
          v-bind="activatorProps"
        ></v-btn>
      </template>

      <v-card>
        <v-toolbar style="background-color: #333;">
          <v-spacer></v-spacer>
          <v-btn
            color="#fff"
            text="입력완료 ✔"
            @click="mngWageSave()"
            style="font-size: 14px;"
          ></v-btn>
            <v-btn
            color="#fff"
            :icon="mdiClose"
            @click="dialog = false"
          ></v-btn>
        </v-toolbar>

        <v-data-table no-data-text="데이터가 존재하지 않습니다."  fixed-header scrollable :items-per-page="-1" :items-per-page-options="[
    { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
  ]" density="compact" :headers="headerCateList" :items="formList" item-value="name"
      class="wageMenuTable mngWageTable "
      style="height: calc(100vh - 60px); overflow-y: hidden; position: relative;">

      <template v-slot:top>

        <v-toolbar flat class="headerToolBar">
          <v-toolbar-title class="fontNotoSans700" style="align-items: center;">
            🧮임금대장 간편입력
            <v-btn style="min-height: 30px !important; width: 30px !important; font-size: 18px; padding-bottom: 16px; border-radius: 20px; background-color: #fff;">
              ❔
          <v-tooltip
            activator="parent"
            location="end"
          >
          ⛳ 정확한 인건비 절감 진단을 위해 임금대장을 입력해주세요.<br><br>
          📌 작성요령<br>
          - [행 추가+]버튼과 [엑셀업로드]버튼을 사용하여 임금구성을 작성할 수 있습니다. <br>
          - [엑셀업로드] 후 직종칸이 비어있다면, 직종칸을 클릭하여 직종을 선택해주세요.<br>
          - 생년월일은 숫자로만 작성해주세요. (예시: 810509)<br><br>
            작성이 완료되었다면, 우측 상단 [입력완료 ✔] 버튼을 눌러주세요.
        </v-tooltip>
        </v-btn>
          </v-toolbar-title>
          
          <v-divider class="mx-4" inset vertical></v-divider>
          <MngExcelDirect/>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn class="btnColorBlue" @click="rowCreate()">
            <span class="btnIconText">행 추가</span>
            <v-icon style="color: #fff; margin-left: 3px;">{{ mdiPlus }}</v-icon>
          </v-btn>
        </v-toolbar>
      </template>


      <template v-slot:item="{ item, index }">

        <tr :class="getRowClass(index)">
          <td class="sticky-column">
            {{ index + 1 }}
          </td>
          <td>
            <!-- <v-text-field type="text" :rules="workerRule" variant="underlined" v-model="item.worker" /> -->
            <select v-model="item.worker" style="padding: 3px;">
                <option v-for="option in workerList" :key="option.value" :value="option.value">{{ option.label }}
                </option>
              </select>
          </td>
          <td class="lineTd" style="min-width: 90px !important;">
            <v-text-field type="text" maxlength="6" style="width: 90px !important;" variant="underlined"
              v-model="item.birth" placeholder="예) 240601"/>
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.base" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.overtime" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.night" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.holiday" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.dayOff" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.longTerm" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.bonus" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.etc" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.lunch" />
          </td>
          <td>
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.car" />
          </td>
          <td class="lineTd">
            <v-text-field type="text" :rules="numRule" variant="underlined" v-model="item.childcare" />
          </td>
          <td>
            <v-btn type="button" @click="rowDelete(index)" class="tac">
              <v-icon style="color: darkgreen;">{{ mdiDelete }}</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
      </v-card>
    </v-dialog>
</template>

  

<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import store from '@/store';
import {
  mdiPlus,
  mdiDelete,
  mdiClose
} from '@mdi/js'
import {
  numRule,
  workerRule
} from '@/composables/rules';
import MngExcelDirect from '@/components/excel/MngExcelDirect.vue'
const loading = ref(false);
const dialog = ref(false);

const workerList = [
  { label: '시설장', value: '시설장' },
  { label: '사회복지사', value: '사회복지사' },
  { label: '간호사', value: '간호사' },
  { label: '간호조무사', value: '간호조무사' },
  { label: '물리치료사', value: '물리치료사' },
  { label: '사무장', value: '사무장' },
  { label: '사무원', value: '사무원' },
  { label: '영양사', value: '영양사' },
  { label: '조리사', value: '조리사' },
  { label: '조리원', value: '조리원' },
  { label: '위생원', value: '위생원' },
  { label: '요양보호사', value: '요양보호사' },
  { label: '운전사', value: '운전사' },
  { label: '기타', value: '기타' },
];

const getRowClass = (index: number) => {
  // 인덱스가 짝수이면 'oneTr' 클래스를, 홀수이면 'twoTr' 클래스를 반환
  return index % 2 === 0 ? 'twoTr2' : 'threeTr';
}

const cleanValue = (value: any) => {
  if (value === '') {
    return 0;
  }
  if (typeof value === 'string' && /^[\d,]+$/.test(value)) {
    // 컴마 제거 후 숫자로 변환
    return parseInt(value.replace(/,/g, ''), 10);
  }
  return value;
};

const createForm = () => {
  const form = reactive({
    idx: 0,
    worker: '요양보호사',     // 근로자명
    birth: '',
    man: '',
    base: 2060740,  // 기본급
    overtime: 0,    // 연장근로
    night: 0,       // 야간근로
    holiday: 0,     // 휴일근로
    dayOff: 0,      // 연차
    longTerm: 0,    // 장기
    bonus: 0,       // 상여
    etc: 0,         // 기타
    lunch: 0,       // 중식
    car: 0,         // 차량
    childcare: 0,   // 양육
    total: 0,       // 총 임금
    taxable: 0,     // 과세 임금
    usual: 0,       // 통상 임금
    minimum: 0,     // 최저 임금

    l_yeon: 0,
    l_gun: 0,
    l_jang: 0,
    l_go: 0,
    l_so: 0,
    l_ji: 0,
    l_total: 0,

    c_yeon: 0,
    c_gun: 0,
    c_jang: 0,
    c_go: 0,
    c_sil: 0,
    c_san: 0,
    c_out: 0,
    c_total: 0,

    status: 1
  });
  return form;
};

const formList = ref([] as any[]);
formList.value.push(createForm());

const rowCreate = () => {
  formList.value.push(createForm());
};

const headerCateList = ref([
  {
    title: '', align: 'center', children: [
      { title: '순번', key: 'index', align: 'center' },
      { title: '직종', key: 'worker', align: 'center', sortable: false },
      { title: '생년월일', key: 'birth', align: 'center', sortable: false }
    ]
  },
  {
    title: '임금구성', align: 'center', children: [
      { title: '기본급', key: 'base', align: 'center', sortable: false },
      { title: '연장수당', key: 'overtime', align: 'center', sortable: false },
      { title: '야간수당', key: 'night', align: 'center', sortable: false },
      { title: '휴일수당', key: 'holiday', align: 'center', sortable: false },
      { title: '연차수당', key: 'dayOff', align: 'center', sortable: false },
      { title: '장기근속', key: 'longTerm', align: 'center', sortable: false },
      { title: '상여금', key: 'bonus', align: 'center', sortable: false },
      { title: '기타수당', key: 'etc', align: 'center', sortable: false },
      { title: '중식대', key: 'lunch', align: 'center', sortable: false },
      { title: '차량비', key: 'car', align: 'center', sortable: false },
      { title: '양육비', key: 'childcare', align: 'center', sortable: false },
    ]
  },
  {
    title: '', align: 'center', children: [
      { title: '삭제', align: 'center' },
    ]
  },
] as any[]);


const rowDelete = (index: any) => {
  formList.value.splice(index, 1);
};

const setDirectDate = computed(() => {
  const DirectDateComputed = store.state.wageDirectPushBox
  return DirectDateComputed;
});

watch(setDirectDate, async (newValue: any) => {
  if (newValue && newValue.length > 0) {
    formList.value = newValue;
    store.commit('setWageDirectPushBox', [])
  }
});
// 날짜 형식 변환 함수
function convertToYYMMDD(dateStr: any) {
  // dateStr을 문자열로 강제 변환
  const str = dateStr.toString();

  // yyyy-mm-dd 형식인지 확인
  const yyyyMMddMatch = str.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (yyyyMMddMatch) {
    const [, yyyy, mm, dd] = yyyyMMddMatch;
    return `${yyyy.slice(2)}${mm}${dd}`;
  }

  // yy-mm-dd 형식인지 확인
  const yyMMddMatch = str.match(/^(\d{2})-(\d{2})-(\d{2})$/);
  if (yyMMddMatch) {
    const [, yy, mm, dd] = yyMMddMatch;
    return `${yy}${mm}${dd}`;
  }

  // 형식이 맞지 않으면 빈 문자열 반환
  return '';
}

const mngWageSave = async () => {
  const validWorkerValues = workerList.map(option => option.value);
  const birthRegex = /^\d{6}$/; // yymmdd 형식의 숫자 문자열을 검사하는 정규식

  formList.value.forEach((form) => {
    if (!validWorkerValues.includes(form.worker)) {
      form.worker = ''; // 유효하지 않은 값은 빈 값으로 처리
    }
    let convertedBirth = convertToYYMMDD(form.birth);
    if (convertedBirth && birthRegex.test(form.birth)) {
      form.birth = convertedBirth;
    }else{
      form.birth = ''
    }
  });

  if (formList.value.length === 0) {
    return;
  }
  const checkAndLog = (rule: any, value: any, ruleName: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === 'string') {
      alert(`${ruleName}`);
      return true;
    } else {
      return false;
    }
  };

const validateFields = (item: any) => {
    // checkAndLog 함수가 정의되어 있는지 확인
    if (typeof checkAndLog !== 'function') {
      throw new Error('checkAndLog 함수가 정의되어 있지 않습니다.');
    }

    // numRule 배열이 정의되어 있는지 확인
    if (!Array.isArray(numRule)) {
      throw new Error('numRule 배열이 정의되어 있지 않습니다.');
    }

    // 유효성 검사를 위한 키들을 필터링
    const keysToValidate = Object.keys(item).filter(key => key !== 'worker' && key !== 'birth' && key !== 'man');

    for (const key of keysToValidate) {
      // 값이 null인 경우 0으로 치환하여 소득세 등 계산된 값이 null일 때를 방지함.
      const valueToCheck = item[key] === null ? 0 : item[key];

      for (const rule of numRule) {
        if (checkAndLog(rule, valueToCheck, '숫자만 입력해주세요.')) {
          alert(`값: ${valueToCheck}`);
          return true; // 문제가 발견되면 true 반환
        }
      }
    }
    return false; // 문제가 없으면 false 반환
  };

  const validateWorker = (item: any) => {
    const keysToValidate = Object.keys(item).filter(key => key === 'worker');
    return keysToValidate.some(key =>
      workerRule.some(rule => checkAndLog(rule, item[key], '직종을 확인해주세요.'))
    );
  };
  
  const isNum = formList.value.some(item => validateFields(item));
  const isWorker = formList.value.some(item => validateWorker(item));
  // const isWorkerDuplicated = checkDuplicateWorker(formList.value);

  if (isNum || isWorker) {
    return;
  } else {
    loading.value = true;
    formList.value = formList.value.map(item => {
      const formattedItem = {} as any;
      for (const key in item) {
        if (key === 'birth') {
          //birth yymmdd가 0으로 시작하는 경우, 함수로직에 의해 0 사라짐 방지.
          formattedItem[key] = item[key];
        } else {
          formattedItem[key] = cleanValue(item[key]);
        }
      }
      return formattedItem;
    });

    try {
      sessionStorage.removeItem('setWageDirectPushBox');
      store.commit('setWageDirectPushData', formList.value)

      alert('정상적으로 입력되었습니다. 감사합니다.')
    }
    catch (error) {
      console.error(error);
      throw error; // 에러 다시 던지기
    } finally {
      loading.value = false;
      dialog.value = false;
    }
  }
};


</script>