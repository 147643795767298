<template>
  <v-chip @click="dialogOpen()" class="cursorPointer" style="margin: 0 1px;" color="red">
    <h4>인감업로드</h4>
  </v-chip >
  <v-dialog v-model="dialog" style="width: 400px;" class="fontNotoSans400">
  <v-card v-if="!loading">
    <div style="background-color: #333; color: #fff; padding: 10px 20px;">
      <h3>인감 변경 시 기존 인감은 삭제됩니다.</h3>
    </div>
    <v-card-text>
      <div>
        <input type="file" accept="image/*" @change="handleFileUpload"
          style="width: 100%; margin-bottom: 10px; border-bottom: 2px solid #333;">
      </div>
      <div>
        <img :src="imageMiribogi || imageUrl" alt=" 인감 확인 후 업로드버튼을 눌러주세요."
          style="max-width: 100%; max-height: 100%; margin-bottom: 5px;">
      </div>
    </v-card-text>
    <v-btn style="border-radius: 0 !important;" @click="sendSisulSignImg" class="btnColorBlue">업로드</v-btn>
    <v-btn style="border-radius: 0 !important;" @click="dialog = false" class="btnColorDark">닫기</v-btn>
  </v-card>
</v-dialog>
</template>
<script setup lang="ts">
import store from '@/store';
import axios from 'axios';
import { ref } from 'vue';


const dialog = ref(false);
const loading = ref(false);
const imageMiribogi = ref();
const imageFile = ref();
const ext = ref();
const imageType = ref();
const imageUrl = ref();
const imgName = ref();
const dialogOpen = async() => {
  dialog.value = true;
  imgName.value = store.state.MngIntoSs.sisul_code + 'signImg';
  await fetchImage();
}
const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    const file = target.files[0];
    ext.value = file.name.split('.').pop();
    const reader = new FileReader();
    reader.onload = () => {
      imageMiribogi.value = reader.result;
    };
    reader.readAsDataURL(file);
    imageFile.value = file;
  }
};
const fetchImage = async () => {
  imageUrl.value = await getSisulSignImg();
};
const getSisulSignImg = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`/api/getSisulSignImg/${imgName.value}`, { responseType: 'blob' });
    if(response.data){
      //인감타입 추출
      imageType.value = response.data.type.split('/')[1];
      if(imageType.value === 'jpeg'){
        imageType.value = 'jpg';
      }
      const imageBlob = response.data;
      const imageObjectURL = URL.createObjectURL(imageBlob);
      return imageObjectURL; // 가져온 인감 URL을 반환
    }
  } catch (error) {
    const imageObjectURL = 'nothing'
    return imageObjectURL;
  } finally {
    loading.value = false;
  }
};

const sendSisulSignImg = async () => {

if (!imageFile.value) {
  alert('인감 이미지를 선택하세요.');
  return;
}
const allowedTypes = ['image/jpeg', 'image/png'];
if (!allowedTypes.includes(imageFile.value.type)) {
  alert('JPG 또는 PNG 파일만 업로드 가능합니다.');
  loading.value = false;
  return;
}
loading.value = true;

try {
  if (imageUrl.value !== 'nothing') {
    await deleteSilsulSignImg();
  }
  const formData = new FormData();
  formData.append('image', imageFile.value);
  const response = await axios.post(`/api/sendSisulSignImg/${imgName.value}/${ext.value}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  if (response && response.data.success) {
    await fetchImage();
    dialog.value = false;
  } else {
    console.error('인감 업로드 실패', response.data);
  }
} catch (error) {
  console.error('인감 업로드 중 에러 발생', error);
} finally {
  loading.value = false;
}
};
const deleteSilsulSignImg = async () => {
try {
  const response = await axios.post(`/api/deleteSilsulSignImg/${imgName.value}`);
  if (!response) {
    alert('기존 파일 삭제 에러 발생.')
    return;
  }
} catch (error) {
  console.error('인감 업로드 중 에러 발생', error);
}
};
</script>