<template>
  <v-card class="dashBoardCard">
    <v-card-title>
      <span>공지사항</span>
    </v-card-title>
      <v-data-table no-data-text="데이터가 존재하지 않습니다." fixed-header density="compact" scrollable :headers="headersArr" :items="boardList"
      item-value="name" class="fontNotoSans400 SsEmpWorkPayTypeTb dashboardComponentTable"
      :items-per-page="-1" :items-per-page-options="[
      ]" >
      <template v-slot:item="{ item }">
        <tr>
          <td class="tal" @click="viewPage(item.hb_code)"  style="cursor: pointer;">
            {{ item.hb_title }}
          </td>
          <td class="tac">
              {{ item.hm_name }}
          </td>
          <td class="tac">
            {{ item.hb_wdate }}
          </td>
          <td class="tac">
            {{ item.hb_count }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script setup lang="ts">
// import axios from 'axios';
import {  onMounted, ref } from 'vue';
import axios from 'axios';
import router from '@/router';
import store from '@/store';

const boardList = ref(['test'] as any[])
const headersArr = ref([
  { title: '제목', key: 'hb_title', align: 'center', sortable: true },
  { title: '작성자', key: 'name', align: 'center', sortable: true },
  { title: '작성일', key: 'hb_wdate', align: 'center', sortable: true },
  { title: '조회수', key: 'hb_count', align: 'center', sortable: true },

] as any[]);


const viewPage = async (code: string) => {
  if(code){
    store.commit('setBoardCode', code);
    router.push('/MngBoardViewPage');
  }else{
    return;
  }
}
const readBoardAll = async () => {
  try {
    const response = await axios.get(`/api/readBoardAll`);
    const responseData = response.data;
    if (responseData) {
      boardList.value = responseData;
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

onMounted(async () => {
  await readBoardAll();
})
</script>