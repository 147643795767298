<template>
  <v-card class="ckeditorCard" style="padding: 25px 60px; width: 80%; margin: auto; ">
    <v-btn @click="boardPage()" class="btnColorDark" style="float: right;">
      취소
    </v-btn>
    <v-btn @click="saveBoardContent()" class="btnColorGreen" style="float: right; margin-right: 3px;">
      저장
    </v-btn>
    <v-text-field class="inoutSearchInput"
     style="
     width: 50%;
      background-color: #fff;
      margin: 0 0 0 0;" 
      v-model="form.title" color="#0f7545" label="제목을 입력해주세요." single-line clearable flat
      hide-details="auto" variant="outlined" density="compact">
    </v-text-field>

    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" @ready="onEditorReady"></ckeditor>
    
    <v-file-input v-model="file" density="compact" label="첨부파일 업로드" variant="underlined" style="margin-top: 15px;"/>
              
    <v-btn @click="boardPage()" class="btnColorDark" style="float: right; margin-top: 10px;">
      취소
    </v-btn>
    <v-btn @click="saveBoardContent()" class="btnColorGreen" style="float: right; margin-top: 10px; margin-right: 3px;">
      저장
    </v-btn>

  </v-card>


</template>

<script setup lang="ts">
import axios from 'axios';
import CKEditor from '@/plugins/ckeditor/build/ckeditor.js';
import { reactive, ref } from 'vue';
import store from '@/store';
import router from '@/router';
const editor = CKEditor;
const form = reactive({
  title: '',
  cate: '',
  id: store.getters.id,
  status: 1,
  fileName: ''
});
const file = ref();
const duplicateFileName = ref([] as any[]);

let editorData ='';
const editorConfig = {
  language: 'ko',
};




const saveBoardFile = async () => {
  if(!file.value){
    file.value = '';
    return;
  }
  let files = file.value;
  const formData = new FormData();
  const fileExtension = files.name.split('.').pop(); // 확장자 추출
  const fileNameWithoutExtension = files.name.split('.').slice(0, -1).join('.'); // 확장자 제외 나머지

  // if (fileExtension !== 'xlsx') {
  //   alert('엑셀 파일만 업로드해주세요.')
  // }
  const allowedExtensions = ['xlsx', 'pdf', 'hwp', 'docx', 'pptx', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'zip'];
  if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
    alert('허용되지 않는 파일 형식입니다.');
  }
  form.fileName = fileNameWithoutExtension + '.' + fileExtension;

  await readBoardDupFileName(form.fileName);
  let isDuplicate = duplicateFileName.value.some(item => item.hb_file_name === form.fileName);
  // 중복된 파일 이름이 있다면 숫자를 늘려서 새로운 파일 이름 생성

  if (isDuplicate) {
    let count = 1;
    let baseFileName = form.fileName.split('.').slice(0, -1).join('.'); // 확장자를 제거한 파일 이름
    while (isDuplicate) {
      form.fileName = `${count}hC25Jes_${baseFileName}.${fileExtension}`;
      isDuplicate = duplicateFileName.value.some(item => item.hb_file_name === form.fileName);
      count++;
    }
  }

  let newFile = new File([files], form.fileName); // 새로운 파일 생성
  formData.append('file', newFile);
  try {
    await axios.post(`/api/saveBoardFile/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.error('파일 업로드 오류:', error);
  }

};

const boardPage = async () => {
  router.push('/MngBoardPage');
}

// 저장 버튼 클릭 시 호출되는 메서드
const saveBoardContent = async () => {
  try {
    await saveBoardFile();
    const response = await axios.post(`/api/saveBoardContent`, {
      content: editorData,
      form: form
    });
    const result = response.data
    if (result.success) {
      alert('게시글이 정상 등록되었습니다.')
      await boardPage();
    } else {
      alert('게시글 등록에 실패했습니다.')
      console.log('데이터 저장 실패');
    }
  }
  catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  }
};  

const readBoardDupFileName = async (fileName: string) => {
  try {
    const response = await axios.get(`/api/readBoardDupFileName/${fileName}`);
    const result = response.data
    duplicateFileName.value = result;
    return result;
  }
  catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  }
};

// CKEditor의 ready 이벤트를 처리할 함수
const onEditorReady = (editorInstance: any) => {
  editorInstance.ui.getEditableElement().parentElement.insertBefore(
    editorInstance.ui.view.toolbar.element,
    editorInstance.ui.getEditableElement()
  );
};
</script>
<style>
/* .ck-heading-dropdown{
  display: none !important;
} */
</style>@/plugins/ckeditor/build/ckeditor.js