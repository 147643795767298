<template>
    <div class="fade-in-up">

    <h2 class="registMainTitle">사용하실 비밀번호를 입력하세요</h2>
  <div class="wageClientInfoForm">
    <p class="tac registSubTitle" style="font-size: 14px; opacity: 0.8; margin: 3px 0 45px 0">
      관리자페이지에 접속할 수 있는 계정을 생성합니다.
    </p>
    <v-text-field 
      class="registId"
      :prepend-inner-icon="mdiLicense" 
      variant="outlined" 
      label="아이디" 
      type="text"
      v-model="form.sisul_id" 
      maxlength="10" 
      autocomplete="off"
      readonly
    />
    <v-text-field 
      :prepend-inner-icon="mdiLockOutline" 
      variant="outlined" 
      label="비밀번호" 
      type="password"
      v-model="form.sisul_pw" 
      maxlength="30" 
      :rules="pwRule" 
      color="indigo"
      autocomplete="off"
    />
    <v-text-field 
      :prepend-inner-icon="mdiLock" 
      variant="outlined" 
      label="비밀번호확인" 
      type="password"
      v-model="form.sisul_pw_auth" 
      maxlength="30" 
      :rules="authPwRule" 
      color="indigo"
      autocomplete="off"
    />
    <SsRegistSignUpload @success="signUpStatusTrue"/>
    <v-btn
      :class="{ clientInfoBtn: status, 'not-agree-disabled': !status }"
      @click="SsRegist_New()"
      >확인</v-btn
    >
  </div>
  <p class="registPrevBtn" @click="prev()">이전단계로 돌아가기</p>

  </div>
</template>
<script setup lang="ts">
import SsRegistSignUpload from "./SsRegistSignUpload.vue";
import { mdiLicense,mdiLockOutline, mdiLock } from "@mdi/js";
import { reactive, ref, watch } from "vue";
import store from "@/store";
import {
  pwRule,
} from '../../composables/rules';
import axios from "axios";
const signUpStatusTrue = () => {
  form.signUpStatus = true;
}
const authPwRule = [
  (v: string) => !!v || '비밀번호가 일치하지 않습니다.',
  (v: string) => v === form.sisul_pw || '비밀번호가 일치하지 않습니다.'
];
const status = ref(false);
const form = reactive({
  signUpStatus: false,
  sisul_id: store.state.RegistForm.hc_number,
  sisul_name: store.state.RegistForm.hc_name,
  sisul_code: store.state.RegistForm.hc_number,
  sisul_boss_name: store.state.RegistForm.sisul_boss_name,
  sisul_boss_phone: store.state.RegistForm.sisul_boss_phone,
  sisul_boss_email: store.state.RegistForm.sisul_boss_email,
  sisul_role: 1, // 인증은되지만, 관리자페이지가 없음.
  sisul_status: 1,

  sisul_addr: store.state.RegistForm.hc_addr,
  sisul_biz_gubun: store.state.RegistForm.hc_biz_gubun,
  sisul_tell: store.state.RegistForm.hc_tell,
  sisul_since: store.state.RegistForm.hc_since,
  sisul_jungwon: store.state.RegistForm.sisul_jungwon,
  sisul_hyunwon: store.state.RegistForm.sisul_hyunwon,
  sisul_wage_in: store.state.RegistForm.sisul_wage_in,
  sisul_fna_in: store.state.RegistForm.sisul_fna_in,

  sisul_bank: store.state.RegistForm.sisul_bank,
  sisul_bank_num: store.state.RegistForm.sisul_bank_num,
  sisul_bank_owner: store.state.RegistForm.sisul_bank_owner,
  sisul_biz_num: store.state.RegistForm.sisul_biz_num,

  sisul_wage_name: store.state.RegistForm.sisul_wage_name,
  sisul_wage_phone: store.state.RegistForm.sisul_wage_phone,
  sisul_fna_name: store.state.RegistForm.sisul_fna_name,
  sisul_fna_phone: store.state.RegistForm.sisul_fna_phone,

  sisul_pw: '',
  sisul_pw_auth: '',
});
// eslint-disable-next-line no-undef
const emit = defineEmits(["success", "prev"]);
const prev = () => {
  emit('prev');
}
watch(form, async () => {
  const checkAndLog = (rule: any, value: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === "string") {
      return true;
    } else {
      return false;
    }
  }; 
  const isPwValid = pwRule?.some(rule => checkAndLog(rule, form.sisul_pw));
  if (isPwValid) return;

  const isAuthPwValid = authPwRule?.some(rule => checkAndLog(rule, form.sisul_pw_auth));
  if (isAuthPwValid) return;
    
  if( !isPwValid && !isAuthPwValid && form.signUpStatus){
    status.value = true;
  }else{
    status.value = false;
  }
});
const SsRegist_New = async () => {
  const checkAndLog = (rule: any, value: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === "string") {
      return true;
    } else {
      return false;
    }
  };
  const isPwValid = pwRule.some(rule => checkAndLog(rule, form.sisul_pw));
  if (isPwValid) return;

  const isAuthPwValid = authPwRule.some(rule => checkAndLog(rule, form.sisul_pw_auth));
  if (isAuthPwValid) return;
    
  if( isPwValid || isAuthPwValid || !form.signUpStatus){
    return;
  } else {
    let registForm = store.state.RegistForm;
    registForm.sisul_pw = form.sisul_pw;
    console.log(registForm,'registForm')
    store.commit("setRegistForm", registForm);
    emit("success");
  }
  try {
    const response = await axios.post('/api/SsRegist_New', { form: form });
    const result = response.data;
    if (result.success === true) {
      console.log('?')
    } else {
      alert('회원가입에 실패하였습니다.');
    }
  } catch (error) {
    alert('회원가입을 처리할 수 없습니다.');
  }

};
</script>
<style scoped>
  .registId .v-input__control{
    background-color: #454545 !important;
  }
</style>