<template>
    <div class="fade-in-up">

  <h2 class="registMainTitle">은행계좌를 입력해주세요</h2>
  <div class="wageClientInfoForm">
    <p class="tac registSubTitle" style="font-size: 14px; opacity: 0.8; margin: 3px 0 45px 0">
      서비스 계약 후 자동결제 정보로 사용됩니다.
    </p>

    <v-combobox
    variant="outlined"
      :prepend-inner-icon="mdiBankOutline"
      label="은행명"
      :items="bankValue"
      v-model="form.sisul_bank"
      color="indigo"
      autocomplete="off"
      class="comeWaySelect"
    >
    </v-combobox>
    <v-text-field
      :prepend-inner-icon="mdiWallet"
      variant="outlined"
      label="계좌번호(숫자만 입력해주세요.)"
      type="text"
      v-model="form.sisul_bank_num"
      maxlength="20"
      :rules="bankNumRule"
      color="indigo"
      autocomplete="off"
    />
    <v-select
    variant="outlined"
      :prepend-inner-icon="mdiBankOutline"
      label="예금주"
      :items="juValue"
      v-model="form.ju"
      color="indigo"
      autocomplete="off"
      class="comeWaySelect"
    >
    </v-select>
    <div class="wageAuthForm" v-if="form.ju === '대표자'">
      <v-text-field
        :prepend-inner-icon="mdiShieldAccount"
        variant="outlined"
        label="대표자 성명"
        type="text"
        v-model="form.sisul_bank_owner"
        maxlength="10"
        :rules="bankOwnerRule"
        color="indigo"
        style="max-width: 48%; margin: auto"
        autocomplete="off"
      />
      <v-text-field
        :prepend-inner-icon="mdiCakeVariant  "
        variant="outlined"
        label="대표자 생년월일"
        type="text"
        v-model="form.birth"
        maxlength="8"
        :rules="birthRule"
        color="indigo"
        autocomplete="off"
        style="max-width: 48%; margin: auto"
      />
    </div>
    <div v-else>

    </div>
    <v-btn
      :class="{ clientInfoBtn: status, 'not-agree-disabled': !status }"
      @click="getForm()"
      >확인</v-btn
    >
  </div>
  <p class="registPrevBtn" @click="prev()">이전단계로 돌아가기</p>

  </div>
</template>
<script setup lang="ts">
import { mdiBankOutline,mdiWallet, mdiShieldAccount,mdiCakeVariant   } from "@mdi/js";
import { reactive, ref, watch } from "vue";
import store from "@/store";
import {
  bankNumRule,
  bankOwnerRule,
  birthRule
} from '../../composables/rules';
const bankValue= [
'기업은행',
'국민은행',
'외환은행',
'수협은행',
'농협은행',
'지역농협',
'우리은행',
'SC제일은행',
'서울은행',
'한국씨티은행',
'대구은행',
'부산은행',
'광주은행',
'제주은행',
'전북은행',
'경남은행',
'새마을금고연합회',
'신협중앙회',
'하나은행',
'신한은행',
'케이뱅크',
'카카오뱅크',
'토스뱅크'
]
const juValue = [
store.state.RegistForm.hc_name,
'대표자',
]
const status = ref(false);

const form = reactive({
  sisul_bank: "",
  sisul_bank_num: "",
  sisul_bank_owner: store.state.RegistForm.sisul_boss_name,
  ju: '',
  birth: ''
});
// eslint-disable-next-line no-undef
const emit = defineEmits(["success", "prev"]);
const prev = () => {
  emit('prev');
}
watch(form, async () => {
  const checkAndLog = (rule: any, value: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === "string") {
      return true;
    } else {
      return false;
    }
  };
  const bankNumValid = bankNumRule.some((rule) =>
    checkAndLog(rule, form.sisul_bank_num)
  );
  const bankOwnerValid = bankOwnerRule.some((rule) =>
    checkAndLog(rule, form.sisul_bank_owner)
  );
  const birthValid = birthRule.some((rule) =>
    checkAndLog(rule, form.birth)
  );

  if(form.ju){
    if(form.ju === '대표자'){
      if(form.sisul_bank && !bankOwnerValid && !bankNumValid && !birthValid ){
        status.value = true;
      }else{
        status.value = false;
      }
    }else{
      if(form.sisul_bank && !bankNumValid ){
        status.value = true;
        form.birth = '';
      }else{
        status.value = false;
      } 
    }
  }else{
    status.value = false;
  }


});
const getForm = async () => {
  const checkAndLog = (rule: any, value: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === "string") {
      return true;
    } else {
      return false;
    }
  };
  const bankNumValid = bankNumRule.some((rule) =>
    checkAndLog(rule, form.sisul_bank_num)
  );
  const bankOwnerValid = bankOwnerRule.some((rule) =>
    checkAndLog(rule, form.sisul_bank_owner)
  );
  const birthValid = birthRule.some((rule) =>
    checkAndLog(rule, form.birth)
  );

  
  if(form.ju){
    if(form.ju === '대표자'){
      if(!form.sisul_bank || !form.sisul_bank_num || !form.sisul_bank_owner || bankNumValid || bankOwnerValid || birthValid ){
        return;
      }else{
        let registForm = store.state.RegistForm;
        registForm.sisul_bank = form.sisul_bank;
        registForm.sisul_bank_num = form.sisul_bank_num;
        registForm.sisul_bank_owner = `${form.sisul_bank_owner}/${form.birth}`;
        store.commit("setRegistForm", registForm);
        emit("success");
      }
    }else{
      if(!form.sisul_bank || !form.sisul_bank_num || bankNumValid ){
        return;
      }else{
        let registForm = store.state.RegistForm;
        registForm.sisul_bank = form.sisul_bank;
        registForm.sisul_bank_num = form.sisul_bank_num;
        registForm.sisul_bank_owner = form.ju;
        store.commit("setRegistForm", registForm);
        emit("success");
      }
    }
  }else{
    return;
  }
};
</script>