<template>
  <v-sheet class="indexSection2 mainMaxWidth" style="background: #fff">
    <!-- <div class="i2_point"></div> -->
<!-- <div class="circle circle-1"></div>
<div class="circle circle-2"></div>
<div class="circle circle-3"></div>
<div class="circle circle-4"></div>
<div class="circle circle-5"></div>
<div class="circle circle-6"></div> -->

  <div class="i2_box">
  <p class="i2_top_ment tal">장기요양기관 <b>"Life Cycle"</b></p>
  </div>
    <v-row style="display: flex; align-items: center; justify-content: center;">
      <v-col cols="5">
        <div class="i2_step1" style="background-color: rgba(4, 0, 105, 0.1);">
          <p class="i2_step_position">Step1 <br>설립기</p> 
          <div class="i2_step2" style="color: #fff;">
            <p>• 종사자 확보</p>
            <p>• 어르신 모집</p>
            <p>• 조직 체계 도입</p>
            <p>• 운영 체계 도입</p>
          </div>
        </div>
      </v-col>
      <v-col cols="2" style="display: flex; align-items: center; justify-content: center;">
        <img class="i2_arrowImg"  src="@/assets/pictures/main/arrow.png" />
      </v-col>
      <v-col cols="5">
        <div class="i2_step1" style="background-color: rgba(1, 26, 110, 0.4);">
          <p class="i2_step_position">Step2 <br>성장기</p>
          <div class="i2_step2" style="color: #fff;">
            <p>• 차별화 전략</p>
            <p>• 현원 60% 이상</p>
            <p>• 조직 체계 정착</p>
            <p>• 운영 체계 정착</p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
    <v-col cols="8">
    </v-col>
      <v-col>
        <img class="i2_arrowImg rotatedImage90" src="@/assets/pictures/main/arrow.png" />
      </v-col>
    </v-row>
    <v-row style="display: flex; align-items: center; justify-content: center;">
      <v-col cols="5">
        <div class="i2_step1" style="background-color: rgba(0, 3, 12, 0.9);">
          <p class="i2_step_position">Step4 <br>은퇴기</p>
          <div class="i2_step2" style="color: #fff;">
            <p>• 승계</p>
            <p>• 매각</p>
            <p>• 절세</p>
          </div>
        </div>
      </v-col>
      <v-col cols="2" style="display: flex; align-items: center; justify-content: center;">
        <img class="i2_arrowImg rotatedImage180"  src="@/assets/pictures/main/arrow.png" />
      </v-col>
      <v-col cols="5">
        <div class="i2_step1" style="background-color: rgba(0, 7, 31, 0.65);">
          <p class="i2_step_position">Step3 <br>안정기</p>
          <div class="i2_step2" style="color: #fff;">
            <p>• 현원 90% 이상 유지</p>
            <p>• 특장점 강화</p>
            <p>• 조직 문화 활성화</p>
            <p>• 운영체계 고도화</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script setup>
import { onMounted} from 'vue';

onMounted(() => {
  window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
})
</script>
<style>
.i2_point {
  width: 40%;
  height: 777px;
  background: radial-gradient(circle, rgba(65, 113, 208, 0.2), rgba(65, 113, 208, 0.05));
  border-radius: 50%; /* 완전한 원 모양을 위해 50%로 설정 */
  position: absolute;
  top: 1030px; /* top은 고정 */
  left: 30%; /* 수평 중앙 정렬 */
  transform: translateX(-50%); /* 수평 중앙 정렬을 위한 transform */
  z-index: 0;
  animation: pulse 16s infinite ease-in-out; /* 맥박 애니메이션 */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* 작은 원들 추가 */
.circle {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(65, 113, 208, 0.1);
  animation: float 8s infinite ease-in-out;
}

@keyframes float {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-20px) scale(1.1);
    transform: translateX(+20px) scale(1.1);

  }
  100% {
    transform: translateY(0) scale(1);
  }
}

.circle-1 {
  width: 100px;
  height: 100px;
  top: 1210px;
  left: 11%;
}

.circle-2 {
  width: 70px;
  height: 70px;
  top: 1180px;
  left: 15%;
}

.circle-3 {
  width: 160px;
  height: 160px;
  top: 1280px;
  left: 10%;
}
.circle-4 {
  width: 150px;
  height: 150px;
  top: 1180px;
  right: 10%;
}

.circle-5 {
  width: 80px;
  height: 80px;
  top: 1280px;
  right: 10%;
}

.circle-6 {
  width: 120px;
  height: 120px;
  top: 1580px;
  left: 17%;
}
.i2_box{
  margin: 160px 0 0 0;
}
.i2_step1{

  border-radius: 20px;
  position: relative;
  box-shadow: 1px 1px 4px rgb(25, 0, 99);
}
.i2_step2{
  height: 205px;
  background-color: rgba(21, 67, 218, 0.256);
  border-radius: 20px;
  padding: 28px 0px 0px 180px; 
  font-size: 24px;
  font-weight: 700;
}
.i2_top_ment{
  font-size: 28px;
  letter-spacing: -0.03em;
  margin: 0 0 20px 0;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3); /* 그림자 추가 */
}

.i2_step_position{
  position: absolute; 
  top: 18px; 
  left: 30px;
  font-size: 36px;
  font-weight: 900;
  color: aliceblue;
  opacity: 1;
  text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.7); /* 그림자 추가 */
}
.rotatedImage90 {
    transform: rotate(90deg);
}
.rotatedImage180 {
    transform: rotate(180deg);
}
.i2_arrowImg{
  width: 70px;
  opacity: 0.5;
}
</style>