<template>
  <v-btn @click="dialog = true" class="btnColorPurple mr-2">최근 한달 간 메일링크 클릭로그</v-btn>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition"
    class="mailFromCompDialog fontNotoSans700">
    <v-toolbar style="background-color: #333;">
      <v-spacer></v-spacer>
      <v-btn color="#fff" :icon="mdiClose" @click="dialog = false"></v-btn>
    </v-toolbar>
    <v-card class="fontNotoSans400">
      <v-data-table v-if="!loading" fixed-header density="compact" scrollable :headers="headersArr" :items="filteredItems" :search="search"
      item-value="name" class="SsEmpWorkPayTypeTb fontNotoSans400" style="
          height: calc(100vh - 64px); 
          overflow-y: hidden; 
          position: relative;
        " :items-per-page="-1" :items-per-page-options="[
          { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
        ]">
      <template v-slot:item="{ item, index }">
        <tr>
          <td class="tac">{{ index + 1 }}</td>
          <td class="tac">{{ item.compName }}</td>
          <td class="tac">{{ item.compNum }}</td>
          <td class="tac">{{ item.subject }}</td>
          <td class="tac">{{ item.readDate }}</td>
          <td class="tac">{{ item.sendDate }}</td>
          <td class="tac">{{ item.imgName }}</td>
          <td class="tac">{{ item.sender }}</td>
        </tr>
      </template>

    </v-data-table>
    <div v-if="loading" class="spinner tac"></div>

    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import {
  mdiClose
} from '@mdi/js'
import axios from 'axios';
const search = ref('');
const loading = ref(false);
const logList = ref([] as any[]);
const dialog = ref(false);
const headersArr = ref([
  { title: 'No.', key: 'index', align: 'center', sortable: false },
  { title: '기관명', key: 'compName', align: 'center', sortable: true },
  { title: '기관기호', key: 'compNum', align: 'center', sortable: true },
  { title: '메일제목', key: 'subject', align: 'center', sortable: true },
  { title: '링크접속일시', key: 'readDate', align: 'center', sortable: true },
  { title: '메일보낸일시', key: 'sendDate', align: 'center', sortable: true },
  { title: '링크이미지명', key: 'imgName', align: 'center', sortable: true },
  { title: '메일보낸ID', key: 'sender', align: 'center', sortable: true },
] as any[]);

const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return logList.value;
  }
  return logList.value.filter(item =>
    (item.emp_name && item.emp_name.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.emp_job && item.emp_job.toLowerCase().includes(search.value.toLowerCase()))
  );
});

const getMailLog = async () => {
  try {
    
    loading.value === true;
    const response = await axios.get(`/api/getMailLog`);

    const responseData = response.data;
    if (responseData) {
      logList.value = responseData;
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loading.value === false;
  }
};
onMounted( async () => {
  await getMailLog();
})
</script>
<style scoped>
.spinner {
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-left: 30px solid #8b2197;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
  margin: 60px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>