<template>
  <v-sheet class="mainMaxWidth financial-menu main-menu-sheet">
    <div class="image-container">
        <img src="@/assets/pictures/main/메뉴_회계.png" class="main-menu-img" cover />
        <div class="main-menu-overlay">
          <p class="main-menu-text">재무회계</p>
          <span class="main-menu-sub-text">Financial accounting</span>
          <span></span>
        </div>
      </div>
    <div class="main-menu-box">
      <div class="main-menu-content">
        <p class="main-menu-top-ment">지혜로운 재무회계는 풍요로운 미래를 여는 열쇠입니다.</p>
        <p class="main-menu-top-sub-ment">단순히 통장내역과 영수증으로 장부를 제작하는 것은 완벽한 회계가 아닙니다.<br/>
          상세한 자료 수집을 토대로 정확한 회계 처리뿐만 아니라, 더 나아가 재무 상태 분석을 통해 자금흐름과 운영의 문제점을 보완하는 것이 진정한 재무회계입니다.</p>
        
        <v-row class="financial-menu-row">
          <v-col>
            <v-table class="financial-menu-table-month">
              <thead>
                <tr>
                  <th colspan="2">월간업무</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="financial-menu-table-title">자료수집</td>
                  <td class="financial-menu-table-ment">
                    <p>
                      • 통장내역, 영수증 일체 <br/>
                      • 본인부담금, 비급여내역 <br/>
                      • 임금내역(직접,간접) <br/>
                      • 장기요양정보시스템 인건비지출비율 내역 <br/>
                      • 기타
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="financial-menu-table-title">계정조정</td>
                  <td class="financial-menu-table-ment">
                    <p>
                      • 통장내역, 영수증 입력 <br/>
                      • 입력데이터 비교분석 <br/>
                      • 계정조정(관항목, 자금원천, 상대계정)
                    </p>
                  </td>                
                </tr>
                <tr>
                  <td class="financial-menu-table-title">재무회계<br>분석</td>
                  <td class="financial-menu-table-ment">
                    <p>
                      • 인건비지출비율 확인 <br/>
                      • 재무상태(회계 부적격 사항 및 이익률) 검토
                    </p>
                  </td>                
                </tr>
                <tr>
                  <td class="financial-menu-table-title">정부보고</td>
                  <td class="financial-menu-table-ment">
                    <p>
                      • 사회복지시설정보시스템(w4c)
                      • 희망이음
                    </p>
                  </td>                
                </tr>
                <tr>
                  <td class="financial-menu-table-title">장부제작</td>
                  <td class="financial-menu-table-ment">
                    <p>
                      • 장부 영수증 부착(이미지는 결산서에 출력됨) <br/>
                      • 장부 제본 및 발송 <br/>
                      • 재무 보고서 작성 및 이메일 발송
                    </p>
                  </td>                
                </tr>
                <tr></tr>

              </tbody>
            </v-table>
          </v-col>
          <v-col>
            <v-table class="financial-menu-table-year">
              <thead>
                <tr>
                  <th colspan="2">연간업무</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="financial-menu-table-title">보고서</td>
                  <td class="financial-menu-table-ment">
                    <p>
                      • 적립금 및 차입금 보고서<br/>
                      • 본예산 수립 및 보고서
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="financial-menu-table-title">결산</td>
                  <td class="financial-menu-table-ment">
                    <p>
                      • 중간결산<br/>
                      • 연도결산
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="financial-menu-table-title">예산</td>
                  <td class="financial-menu-table-ment">
                    <p>
                      • 예산 수립 및 보고<br/>
                      • 추가경정 예산 수립 및 보고
                    </p>
                  </td>
                </tr>
                <tr></tr>
              </tbody>
            </v-table>
          </v-col>
        </v-row>
        
      </div>
      
    </div>
  </v-sheet>
</template>
<style>
.financial-menu-row{
  margin: 40px 0 ;
}
.financial-menu-row .v-table {
  border-radius: 10px;
}
.financial-menu-row .v-table thead tr th{
  font-size: 22px;
  font-weight: 600 !important;
  padding: 20px 0 20px 20px !important;
  background-color: #e6e6e6;
}
.financial-menu-row .v-table tbody tr td{
  font-size: 18px;
  letter-spacing: -0.05rem;
  font-weight: 500 !important;
}
.financial-menu-table-title{
  text-align: center;
  width: 100px !important;
  padding: 50px 10px !important;
  background-color: #f5f5f5;

}
.financial-menu-table-ment{
  padding: 20px 0px 20px 30px !important;
}
.financial-menu-table-ment p {
  line-height: 1.8;
}
</style>
<script setup>
import { onMounted} from 'vue';

onMounted(() => {
  window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
})
</script>