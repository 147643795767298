<template>
  <div class="fade-in-up tac fontNotoSans700">
    <v-sheet v-if="!showCompInfo" class="indexSheet">
      <div>
        <v-chip class="indexChips" color="green-accent-4 ">
          <div style="padding: 10px;">
            <b style="color: #4d4e58;">
              <b style="color: #005d1f; font-size: 17px;">더 낸 사회보험 환급</b> 안내해드립니다.</b>
          </div>
        </v-chip>
      </div>
      <span class="indexMainTitle1" style="color: #4D4E58;">장기요양기관을 위한</span>
      <br>
      <span class="indexMainH2" style="color: #005d1f;">사회보험금 환급 </span>
      <span class="indexMainH1" style="color: #4D4E58;">진단 서비스</span>

      <div class="indexMainHeightDiv" style="height: 20px;"></div>
      <p class="indexMainP">환급금 간편진단으로 더 낸 사회보험금을 확인해보세요.</p>
      <div class="indexMainStartBtnDiv">
        <v-btn style="position: relative; display: flex; align-items: center; justify-content: center;"
          id="indexMainStartBtn" @click="toggleCompInfo" class="mainBigBtn">
          더 낸 사회보험금 간편확인
          <img src="@/assets/pictures/free-view.png" class="freeViewImg" />
        </v-btn>
      </div>

    </v-sheet>
    <v-sheet v-if="showCompInfo" class="indexSheetBtnClicked">
      <div class="mb-6">
        <span class="indexMainTitle1" style="color: #4D4E58;">장기요양기관을 위한</span>
      <br>
      <span class="indexMainH2" style="color: #005d1f;">사회보험금 환급 </span>
      <span class="indexMainH1" style="color: #4D4E58;">진단 서비스</span>
      </div>
      <div class="mb-4">
      <v-btn @click="windowReload" class="btnColorWhite mr-2">메인으로가기</v-btn>
      <InquiryForm/>
    </div>
      <div class="tac">
        <WageCompInfo />
      </div>
    </v-sheet>
    <div class="mainImg1Div" v-if="!showCompInfo">
      <img class="mainImg1" src="@/assets/pictures/mainImg1.png" />
    </div>

    <v-sheet class="indexSheet2" v-if="!showCompInfo">

      <div class="indexSheet2Div">
        <p class="indexMainH1" style="color: #4D4E58; ">임금관리 서비스로 인건비 절감 평균</p>
        <p class="indexMainH1" style="color: #005d1f;"> 26,342,400 원</p>
        <br>
        <v-chip class="mb-6 indexChips" color="green-accent-4 ">
          <b style="color: #4d4e58; padding: 0 10px;">2024년도 요양보호사 20명 기준</b>
        </v-chip>
        <p class="indexMainP">이미 납부한 인건비 내역이 있으신가요?</p>
        <p class="indexMainP">최대 금액으로 환급 가능하도록 수정신고를 도와드립니다.</p>
        <br>
        <p class="indexMainP2" style="font-weight: 400;">더 낸 사회보험금 간편확인을 통해 상세진단 신청을 해주세요.</p>
      </div>
    </v-sheet>
  </div>
</template>


<script setup lang="ts">

import { ref } from 'vue';
import WageCompInfo from '@/components/wage/WageCompInfo.vue';
import InquiryForm from '@/components/wage/InquiryForm.vue';

const showCompInfo = ref(false);
const clicked = ref(false);
const windowReload = () => {
  window.location.reload();
}
const toggleCompInfo = () => {
  clicked.value = true;
  showCompInfo.value = !showCompInfo.value;
};

</script>

<style scoped>
.gradient-text {
  font-size: 20px;
  font-weight: bold;
  background: linear-gradient(to right, #1179a3, #006aff, #003c54);
  /* 세 가지 색상 그라데이션 */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.fade-in-up-slow {
  animation: fade-in-up-slow 2s ease-out;
}

.fade-in-up {
  animation: fade-in-up 1s ease-out;
}

.fade-out {
  animation: fade-out 1s ease-out;
}

@keyframes fade-in-up-slow {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>