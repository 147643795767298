<template>
  <v-card class="normalMngCard" style="width: 100%;">
    <v-data-table no-data-text="데이터가 존재하지 않습니다." fixed-header density="compact" scrollable :headers="headersArr" :items="filteredItems"
      :search="search" item-value="name" class="fontNotoSans400" style="
        height: calc(100vh - 217px); 

        overflow-y: hidden; 
        position: relative;
      " :items-per-page="50" :items-per-page-options="[
        { value: 50, title: '50' },
        { value: 100, title: '100' },
        { value: 200, title: '200' }
        // { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
      ]" v-if="memList.length !== 0">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="fontNotoSans700">🧑‍💼 관리자/매니저 목록</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <MngRegistForm />
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field class="inoutSearchInput" style="
          background-color: #fff;
          max-width:200px; 
          width:auto; 
          min-width:70px;
          margin: 0 20px 0 0;
        " v-model="search" color="#0f7545" label="이름검색" single-line clearable :append-inner-icon="mdiMagnify" flat
            hide-details="auto" variant="outlined" density="compact">
          </v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item, index }">
        <tr class="datatableTr">
          <td class="tac">{{ index + 1 }}</td>
          <td class="tac">
            {{ item.hm_name }}
          </td>
          <td class="tac">
            <template v-if="!item.editing">
              <v-chip color="red-accent-4 " v-if="item.hm_role === 0">
                {{ item.role_name }}
              </v-chip>
              <v-chip color="black" v-else-if="item.hm_role === 1">
                {{ item.role_name }}
              </v-chip>
              <v-chip color="blue-accent-4" v-else-if="item.hm_role === 7">
                {{ item.role_name }}
              </v-chip>
              <v-chip color="red-darken-4" v-else-if="item.hm_role === 8">
                {{ item.role_name }}
              </v-chip>
              <v-chip color="brown-accent-4" v-else-if="item.hm_role === 9">
                {{ item.role_name }}
              </v-chip>
              </template>
            <template v-else>
              <select v-model="item.hm_role" style="outline: auto; padding: 3px 20px 3px 20px;">
                <option v-for="option in memRoleOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
              </select>
            </template>
          </td>
          <td class="tac">
            {{ item.hm_phone }}
          </td>
          <td class="tac">
           {{ item.hm_email }}
          </td>
          <td class="tac">{{ item.fwa_code_count }} 건</td>
         
          <td class="tac">
            <template v-if="!item.editing">
              <v-chip color="black" v-if="item.hm_status === 0">
                {{ item.status_name }}
              </v-chip>
              <v-chip color="green-darken-2" v-else-if="item.hm_status === 1">
                {{ item.status_name }}
              </v-chip>
              <v-chip color="orange-accent-4" v-else-if="item.hm_status === 2">
                {{ item.status_name }}
              </v-chip>
              <v-chip color="yellow-darken-1" v-else-if="item.hm_status === 3">
                {{ item.status_name }}
              </v-chip>
            </template>
            <template v-else>
              <select v-model="item.hm_status" style="outline: auto; padding: 3px 20px 3px 20px;">
                <option v-for="status in memStatusOptions" :key="status.value" :value="status.value">{{ status.label }}</option>
              </select>
            </template>
          </td>
          <td class="tac">
            {{ item.hm_wdate }}
          </td>
          <td class="tac">
            <template v-if="!item.editing">
              <v-btn @click="toggleEditMode(item)" class="btnColorWhite" style="height: 28px;">수정</v-btn>
            </template>
            <template v-else>
              <v-btn @click="changeMem(item)" class="btnColorBlue" style="height: 28px;">확정</v-btn>
              <v-btn @click="cancelEdit(item)" class="btnColorPink" style="height: 28px; margin-left: 5px;">취소</v-btn>
            </template>
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-card>
</template>
<script setup lang="ts">
// import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import {
  mdiMagnify,
} from '@mdi/js'
import axios from 'axios';
import MngRegistForm from '@/components/mng/MngRegistForm.vue'

const memList = ref(['test'] as any[])
const search = ref('');
const headersArr = ref([
  { title: 'No.', key: 'index', align: 'center', sortable: false },
  { title: '이름', key: 'hm_name', align: 'center', sortable: true },
  { title: '권한', key: 'role_name', align: 'center', sortable: true },
  { title: '휴대폰', key: 'hm_phone', align: 'center', sortable: true },
  { title: '이메일', key: 'hm_email', align: 'center', sortable: true },
  { title: '영업진행누계', key: 'fwa_code_count', align: 'center', sortable: true },
  { title: '상태', key: 'hm_status', align: 'center', sortable: true },
  { title: '가입일', key: 'hm_wdate', align: 'center', sortable: true },
  { title: '수정', key: 'hm_wdate', align: 'center', sortable: false }

] as any[]);
const memRoleOptions = ref([
  { label: '미인증', value: 0 },
  { label: '일반회원', value: 1 },
  { label: '매니저', value: 7 },
  { label: '관리자', value: 8 },
]);
const memStatusOptions = ref([
  { label: '탈퇴', value: '0' },
  { label: '정상', value: '1' },
  { label: '휴직', value: '2' },
  { label: '경고', value: '3' },
]);
const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return memList.value;
  }
  return memList.value.filter(item =>
    (item.hm_name && item.hm_name.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.role_name && item.role_name.toLowerCase().includes(search.value.toLowerCase()))
  );
});



const toggleEditMode = (item: any) => {
  if(item.role_name === 'supervisor'){
    alert('수정이 불가한 권한입니다.')
    return;
  }
  item.editing = !item.editing;
};

// 수정 내용을 저장하는 메서드 (서버와의 통신 등 필요한 처리를 추가할 수 있음)
const changeMem = async (item: any) => {
  try {
    const response = await axios.post(`/api/changeMem/`, {
      item: item
    });
    const result = response.data
    if (result.success) {
      alert("수정되었습니다.");
      await readMem();
    } else {
      alert("수정에 실패하였습니다.");
    }
  }
  catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  } finally {
    item.editing = false; // 수정 모드 종료
  }
};

// 수정 취소하는 메서드
const cancelEdit = (item: any) => {
  item.editing = false; // 수정 모드 종료
};


const readMem = async () => {
  try {
    const response = await axios.get(`/api/readMem`);
    const responseData = response.data;
    if (responseData) {
      memList.value = responseData;

      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

onMounted(async () => {
  await readMem();
})
</script>
<style scoped>
.datatable {
  width: 100%;
  border-collapse: collapse;
}

.datatable th,
.datatable td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.form-control {
  width: 100%;
  padding: 4px;
}
</style>