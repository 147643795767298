<template>
  <div style="background: linear-gradient(180deg, #3159dec8 0%, #8197e0a8 70.45%, #ffffffa8 100%);">
    <v-sheet class="indexSection3 mainMaxWidth">
      <div class="i3_box">
          <p class="i3_top_ment tal"><b>Hamwoori's Main Content</b></p>
        <v-row>
          <v-col cols="6">
            <p class="i3_big_text">운영컨설팅</p>
            <p class="i3_sub_text" @click="OperationConsulting">VIEW<span class="i3_plus">+</span></p>
            <p class="i3_normal_text">Operation consulting</p>
            <div class="i3_backImgDiv">
              <img class="i3_img hover-zoom" @click="OperationConsulting" cover src="@/assets/pictures/main/운영.png" />
            </div>
          </v-col>
          <v-col cols="6">
            <p class="i3_big_text">노무관리</p>
            <p class="i3_sub_text" @click="LaborManagement">VIEW<span class="i3_plus">+</span></p>
            <p class="i3_normal_text">Labor management</p>
            <div class="i3_backImgDiv">
              <img class="i3_img hover-zoom" @click="LaborManagement" cover src="@/assets/pictures/main/노무.png" />
            </div>
          </v-col>
          <v-col cols="6">
            <p class="i3_big_text">재무회계</p>
            <p class="i3_sub_text" @click="FinancialAccounting">VIEW<span class="i3_plus">+</span></p>
            <p class="i3_normal_text">Financial accounting</p>
            <div class="i3_backImgDiv">
              <img class="i3_img hover-zoom" @click="FinancialAccounting" cover src="@/assets/pictures/main/회계.png" />
            </div>
          </v-col>
          <v-col cols="6">
            <p class="i3_big_text">급식관리</p>
            <p class="i3_sub_text" @click="MealManagement">VIEW<span class="i3_plus">+</span></p>
            <p class="i3_normal_text">Meal management</p>
            <div class="i3_backImgDiv">
              <img class="i3_img hover-zoom" @click="MealManagement" cover src="@/assets/pictures/main/급식.png" />
            </div>
          </v-col>
          <!-- <v-col cols="4">
            <p class="i3_big_text">자금운용</p>
            <p class="i3_sub_text" @click="FundManagement">VIEW<span class="i3_plus">+</span></p>
            <p class="i3_normal_text">Fund management</p>
            <div class="i3_backImgDiv">
              <img class="i3_img hover-zoom" @click="FundManagement" cover src="@/assets/pictures/main/자금.png" />
            </div>
          </v-col> -->
        </v-row>
      </div>
    </v-sheet>
  </div>
</template>
<script setup lang="ts">
import router from '@/router';
import { onMounted} from 'vue';

onMounted(() => {
  window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
})
const OperationConsulting = () => {
  router.push('/OperationConsulting')
}
const LaborManagement = () => {
  router.push('/LaborManagement')
}
const FinancialAccounting = () => {
  router.push('/FinancialAccounting')
}
const MealManagement = () => {
  router.push('/MealManagement')
}
// const FundManagement = () => {
//   router.push('/FundManagement')
// }
</script>
<style>
.i3_box {
  padding-top: 70px;
}
.i3_top_ment{
  font-size: 28px;
  letter-spacing: -0.03em;
  color: #fff;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3); /* 그림자 추가 */
}

.indexSection3 .v-row .v-col {
  padding: 25px;
}

.indexSection3 .v-row .v-col {
  position: relative !important;
}

.i3_backImgDiv {
  width: 100%;
  min-width: 500px;
  height: 500px;
  background: rgba(255, 255, 255, 0.15);
  padding: 40px 40px;
  border: 1px solid #e1e1e15e;
  border-radius: 5px;
  overflow: hidden;
}

.i3_img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out; /* 부드러운 확대 효과 */
  opacity: 0.9;
}
.i3_img:active{
  opacity: 0.8 !important;
}
.i3_img.hover-zoom:hover {
  transform: scale(1.03); /* 이미지 확대 */
  animation: bounce 0.3s; /* 또잉또잉 효과 */
  cursor: pointer;
  opacity: 1;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(1.03);
  }
  50% {
    transform: scale(1.07); /* 약간 더 확대 */
  }
}

.i3_big_text {
  font-size: 40px;
  color: #fff;
  letter-spacing: -0.04em;
  font-weight: 700;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
  text-shadow: 2px 3px 6px rgba(0, 0, 0, 0.5); /* 그림자 추가 */

}
.i3_sub_text{
  position: absolute; 
  top: 50px; 
  right: 30px;
  font-size: 16px;
  font-weight: 400;
  color: aliceblue;
  opacity: 0.8;
  cursor: pointer;
}

.i3_normal_text {
  font-size: 24px;
  margin: 3px 0 4px 0;
  color: #fff;
  letter-spacing: -0.02em;
  opacity: 0.7;
}

.i3_sub_text .i3_plus {
  display: inline-block;
  transition: transform 0.4s ease;
  margin-left: 5px;
  font-weight: bold;
}

.i3_sub_text:hover .i3_plus {
  transform: rotate(270deg); /* 마우스 오버 시 360도 회전 */
}
.i3_sub_text:active{
  opacity: 1;
}
</style>
