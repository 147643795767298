<template>
  <v-icon @click="openPdf" size="x-large" style="color: blue;">{{ mdiFileEye }}</v-icon>
  <v-icon @click="downloadPdf" size="x-large" style="color: red;">{{ mdiFilePdfBox }}</v-icon>
</template>

<script setup lang="ts">
import axios from 'axios';
import { mdiFilePdfBox, mdiFileEye } from '@mdi/js';
import { ref, defineProps } from 'vue';
const props = defineProps({
  item: {
    type: Object,
    required: true,
  }
});
const item = ref();
const getPdf = async () => {
  try {
    const response = await axios.post(`/api/getPdf/`, { pdfData: item.value }, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    console.error('PDF 파일 가져오기 중 오류 발생:', error);
    throw error;
  }
};

const openPdf = async () => {
  item.value = props.item
  if(item.value.fwa_status < 3){
    alert('상세진단 완료 후 보고서 출력이 가능합니다.');
    return;
  }
  try {
    const pdfData = await getPdf();
    const pdfUrl = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
    window.open(pdfUrl, '_blank');
  } catch (error) {
    console.error('PDF 파일을 새 창으로 열기 중 오류 발생:', error);
  }
};

const downloadPdf = async () => {
  item.value = props.item
  if(item.value.fwa_status < 3){
    alert('상세진단 완료 후 보고서 출력이 가능합니다.');
    return;
  }
  try {
    const pdfData = await getPdf();
    const response = await axios.get(`/api/getFwaLogForPdf/${item.value.fwa_code}`);
    if(!response.data){
      alert('상세진단 완료 후 보고서 출력이 가능합니다.');
      return;
    }
    const pdfUrl = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', `${item.value.comp_name}_상세진단결과보고서_${response.data.wsl_date}.pdf`);  // 파일 이름을 test.pdf로 지정합니다.
    document.body.appendChild(link);
    link.click();
    link.remove();  // 링크를 DOM에서 제거합니다.
    URL.revokeObjectURL(pdfUrl);  // URL 객체를 해제합니다.
  } catch (error) {
    console.error('PDF 다운로드 중 오류 발생:', error);
  }
};
</script>
