<template>
  <div class="fade-in-up">
      <h2 v-if="!sendMailStatus" class="registMainTitle">함우리 이메일 인증</h2>

  <div v-if="!sendMailStatus">

    <p class="registSubTitle tac" style="font-size: 14px; opacity: 0.8; margin: 3px 0 25px 0">
      함우리의 소식을 받을 대표 이메일을 입력해주세요.
    </p>

    <v-text-field
      style="padding: 0 10px 10px 10px"
      :prepend-inner-icon="mdiEmailVariant"
      variant="underlined"
      label="대표 이메일"
      type="email"
      v-model="form.sisul_boss_email"
      maxlength="50"
      :rules="emailRule"
      color="indigo"
      autocomplete="off"
    />
    <div class="d-flex" style="align-items: center">
      <playingMent @success="formPlayTrue()" @false="formPlayFalse()" />
    </div>
    <div class="d-flex" style="align-items: center">
      <policyMent @success="formPolicyTrue()" @false="formPolicyFalse()" />
    </div>

    <v-btn
      :class="{ clientInfoBtn: agreeGo, 'not-agree-disabled': !agreeGo }"
      @click="sendRegistAuthMail()"
      >인증요청</v-btn
    >
  </div>
  <div v-if="sendMailStatus" class="email-waiting-container">
    <h3 v-if="!loading3" class="tac pt-4" style="font-size: 20px">이메일을 열고 인증하기를 클릭하세요.</h3>
    <p v-if="!loading3" class="tac pt-4" style="font-size: 14px; opacity: 0.8; margin: 3px 0 0 0">
      {{ store.state.RegistForm.sisul_boss_email }}으로 인증 요청 메일을 보냈습니다.
    </p>
    <p v-if="!loading3" class="tac pt-1" style="font-size: 14px; opacity: 0.8; margin: 3px 0 0 0">
      이메일이 오지 않을 경우, 스팸함을 확인해주세요.
    </p>

    <!-- 로딩 애니메이션 -->
    <div v-if="loading3" class="loading-animation">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
    </div>

    <p v-if="mailLink && !loading3" class="tac pt-5" style="font-size: 16px; margin-bottom: 10px;">
      <a :href="mailLink" target="_blank" style="text-decoration: none">
        <div style="display: flex; justify-content: center; align-items: center;">
        <v-btn style="width: 150px; margin: auto;">
          <img v-if="domain === 'gmail.com'" class="registEmailLogo" src="@/assets/pictures/google_mail_logo.png" cover />
          <img v-if="domain === 'naver.com'" class="registEmailLogo" src="@/assets/pictures/naver_mail_logo.png" cover />
          <img v-if="domain === 'daum.net'" class="registEmailLogo" src="@/assets/pictures/daum_mail_logo.png" cover />
          <img v-if="domain === 'hanmail.net'" class="registEmailLogo" src="@/assets/pictures/daum_mail_logo.png" cover />
          <span class="ml-2" style="color:#333;">이메일 열기</span>
        </v-btn>
      </div>
      </a>
      
    </p>
    <p v-if="!loading3">남은시간 : {{ countdownTime }}초</p>
    <v-btn v-if="!loading3" class="emailAuthBtn" @click="backMailAuth">다른 이메일로 인증하기</v-btn>
  </div>
</div>
</template>

<script setup lang="ts">
import store from "@/store";
import { mdiEmailVariant } from "@mdi/js";
import { emailRule } from "../../composables/rules";
import playingMent from "@/components/wage/playingMent.vue";
import policyMent from "@/components/wage/policyMent.vue";
import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import axios from "axios";
// eslint-disable-next-line no-undef
const emit = defineEmits(["success"]);
const backMailAuth = () => {
  sendMailStatus.value = false;
  form.policy = false;
  form.playing = false;
  clearInterval(checkInterval.value);
  clearInterval(countdownInterval.value);
  emailVerified.value = false;
  countdownTime.value = 180;
  loading3.value = true;
  store.commit("setRegistForm", []);
};
const loading3 = ref(true);
const email = computed(() => store.state.RegistForm.sisul_boss_email);

// 이메일 도메인에 맞는 링크를 계산
const mailLink = computed(() => {
  if (!email.value) {
    return null;  // 이메일이 없으면 null을 반환
  }
  
  const domain = email.value.split("@")[1]?.toLowerCase();

  if (domain === "gmail.com") {
    return `https://mail.google.com`;
  } else if (domain === "naver.com") {
    return `https://mail.naver.com`;
  } else if (domain === "daum.net") {
    return `https://mail.daum.net`;
  } else {
    return null;  // 다른 도메인에 대해서는 null을 반환
  }
});

const domain = computed(() => {
  if (!email.value) {
    return null;  // 이메일이 없으면 null을 반환
  }
  
  return email.value.split("@")[1]?.toLowerCase();
});

const agreeGo = ref(false);
const sendMailStatus = ref(false);

const form = reactive({
  sisul_name: store.state.RegistForm.hc_name,
  sisul_boss_email: "",
  policy: false,
  playing: false,
});
const formPolicyTrue = () => {
  form.policy = true;
};
const formPolicyFalse = () => {
  form.policy = false;
};
const formPlayTrue = () => {
  form.playing = true;
};
const formPlayFalse = () => {
  form.playing = false;
};

watch(form, async () => {
  const checkAndLog = (rule: any, value: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === "string") {
      return true;
    } else {
      return false;
    }
  };
  const isEmailValid = emailRule.some((rule) => checkAndLog(rule, form.sisul_boss_email));
  if (form.sisul_boss_email && form.playing && form.policy && !isEmailValid) {
    agreeGo.value = true;
  } else {
    agreeGo.value = false;
  }
});

// 카운트다운 상태 및 인터벌 변수 정의
const countdownTime = ref(180); // 3분 = 180초
const countdownInterval = ref(null) as any;
// 카운트다운 시작 함수
const startCountdown = () => {
  countdownInterval.value = setInterval(() => {
    if (countdownTime.value > 0) {
      countdownTime.value--;
    }else {
      clearInterval(countdownInterval.value);
      alert('인증 유효 시간이 만료되었습니다.')
      window.location.reload();
    }
  }, 1000); // 1초마다 업데이트

};

const sendRegistAuthMail = async () => {
  const checkAndLog = (rule: any, value: any, ruleName: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === "string") {
      alert(`${ruleName}`);
      return true;
    } else {
      return false;
    }
  };
  const isEmailValid = emailRule.some((rule) =>
    checkAndLog(rule, form.sisul_boss_email, "올바른 이메일을 작성해주세요.")
  );

  if (!form.playing || !form.policy || isEmailValid) {
    return;
  }
  try {
    await deleteVerified();
    sendMailStatus.value = true;
    const response = await axios.post(`/api/sendRegistAuthMail/${form.sisul_boss_email}`);
    const result = response.data;
    if (result.success) {
      checkInterval.value = setInterval(checkVerificationStatus, 5000);
      store.commit("setRegistForm", form);
      loading3.value = false;
      startCountdown();
      return result; // 가져온 데이터를 반환
    } else {
      alert("메일 전송에 실패하였습니다. 관리자에게 문의바랍니다.");
      console.error("에러가 발생했습니다.", result);
    }
  } catch (error) {
    console.error("errorMsg:", error);
    sendMailStatus.value = false;
  }
};
const emailVerified = ref(false); // 인증 상태
const checkInterval = ref(null) as any; // Interval ID

const deleteVerified = async () => {
  try {
    await axios.get(`/api/deleteVerified?email=${encodeURIComponent(form.sisul_boss_email)}`);
  } catch (error) {
    console.error("상태 확인 중 오류 발생:", error);
  }
};
const checkVerificationStatus = async () => {
  try {

    const response = await axios.get(
      `/api/checkVerificationStatus?email=${encodeURIComponent(form.sisul_boss_email)}`
    );
    emailVerified.value = response.data.verified; // 서버에서 인증 여부 반환
    if (emailVerified.value) {
      alert("이메일 인증이 완료되었습니다!");
      clearInterval(checkInterval.value);
      clearInterval(countdownInterval.value);
      store.commit("setRegistForm", form);
      emit('success');
    }
  } catch (error) {
    console.error("상태 확인 중 오류 발생:", error);
  }
};


onUnmounted(() => {
  // 컴포넌트가 언마운트되면 Interval 해제
  emailVerified.value = false;
  clearInterval(countdownInterval.value);
  clearInterval(checkInterval.value);
});
onMounted(() => {
  emailVerified.value = false;
  clearInterval(checkInterval.value);
  clearInterval(countdownInterval.value);
})
</script>
<style scoped>
.email-waiting-container {
  text-align: center;
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.dot {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #3498db;
  animation: bounce 1.2s infinite ease-in-out;
}

.dot1 {
  animation-delay: 0s;
}

.dot2 {
  animation-delay: 0.2s;
}

.dot3 {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>
