<template>
  <v-card class="normalMngCard">
    <v-data-table fixed-header density="compact" scrollable :headers="headersArr" :items="filteredItems"
      :search="search" item-value="name" class="fontNotoSans400" style="
        height: calc(100vh - 217px);
        overflow-y: hidden; 
        position: relative;
      " :items-per-page="30" :items-per-page-options="[
        { value: 30, title: '30' },
        { value: 100, title: '100' },
        { value: 200, title: '200' },
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
      ]" >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="fontNotoSans700">📢 공지사항</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn v-if="role >= 8" @click="wrightPage()" class="btnColorPurple">
            글쓰기
          </v-btn>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field class="inoutSearchInput" style="
          background-color: #fff;
          max-width:200px; 
          width:auto; 
          min-width:70px;
          margin: 0 20px 0 0;
        " v-model="search" color="#0f7545" label="제목검색" single-line clearable :append-inner-icon="mdiMagnify" flat
            hide-details="auto" variant="outlined" density="compact">
          </v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item, index }">
        <tr class="boardTr">
          <td class="tac">{{ index + 1 }}</td>
          <td class="tal" @click="viewPage(item.hb_code)">
            <v-icon>
              {{ getFileIcon(item.hb_file_name) }}
            </v-icon>
            {{ item.hb_title }}
          </td>
          <td class="tac">
            <v-chip color="red-accent-4 " v-if="item.hm_role === 0">
              {{ item.hm_name }}
            </v-chip>
            <v-chip color="green-accent-4" v-else-if="item.hm_role === 1">
              {{ item.hm_name }}
            </v-chip>
            <v-chip color="blue-darken-4" v-else-if="item.hm_role === 7">
              {{ item.hm_name }}
            </v-chip>
            <v-chip color="purple-accent-4 " v-else-if="item.hm_role === 8">
              {{ item.hm_name }}
            </v-chip>
            <v-chip color="black" v-else-if="item.hm_role === 9">
              {{ item.hm_name }}
            </v-chip>
          </td>
          <td class="tac">
            {{ item.hb_wdate }}
          </td>
          <td class="tac">
            {{ item.hb_count }}
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-card>
</template>
<script setup lang="ts">
// import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import {
  mdiMagnify,
  mdiPaperclip
} from '@mdi/js'
import axios from 'axios';
import router from '@/router';
import store from '@/store';
const role = store.getters.role;

const boardList = ref(['test'] as any[])
const search = ref('');
const headersArr = ref([
  { title: 'No.', key: 'index', align: 'center', sortable: false },
  { title: '제목', key: 'hb_title', align: 'center', sortable: true },
  { title: '작성자', key: 'name', align: 'center', sortable: true },
  { title: '작성일', key: 'hb_wdate', align: 'center', sortable: true },
  { title: '조회수', key: 'hb_count', align: 'center', sortable: true },

] as any[]);
const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return boardList.value;
  }
  return boardList.value.filter(item =>
    (item.hb_title && item.hb_title.toLowerCase().includes(search.value.toLowerCase()))
  );
});

const getFileIcon = (fileName: any) => {
  if (!fileName) {
    return '';
  }else{
    return mdiPaperclip;
  }
};


const wrightPage = async () => {
  router.push('/MngBoardWright');
}
const viewPage = async (code: string) => {
  if(code){
    store.commit('setBoardCode', code);
    router.push('/MngBoardViewPage');
  }else{
    return;
  }
}
const readBoardAll = async () => {
  try {
    const response = await axios.get(`/api/readBoardAll`);
    const responseData = response.data;
    if (responseData) {
      boardList.value = responseData;
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

onMounted(async () => {
  await readBoardAll();
})
</script>
<style scoped>
.datatable {
  width: 100%;
  border-collapse: collapse;
}

.datatable th,
.datatable td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

</style>