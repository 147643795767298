<template>
  <v-sheet class="mainMaxWidth fund-menu main-menu-sheet">
    <div class="main-menu-box">
      <div class="image-container">
        <img src="@/assets/pictures/main/메뉴_자금.png" class="main-menu-img" cover />
        <div class="main-menu-overlay">
          <p class="main-menu-text">자금운용</p>
          <span class="main-menu-sub-text">Fund management</span>
          <span></span>
        </div>
      </div>
      <div class="main-menu-content"></div>
    </div>
  </v-sheet>
</template>

<script setup>
import { onMounted} from 'vue';

onMounted(() => {
  window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
})
</script>